import * as shopBookBankAction from "../../slices/master/shopBookBank.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";
import { endPoint } from "../../../constants/server/api";

export const createShopBookBank = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.SHOP_BOOK_BANK_URL}`,
        data: data,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data;
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const updateShopBookBank = (data, id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.SHOP_BOOK_BANK_URL}/${id}`,
        data: data,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const getShopBookBankByShopId = (shopId) => {
  return async (dispatch) => {
    dispatch(shopBookBankAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.SHOP_BOOK_BANK_URL}/shopId/${shopId}`,
        headers: setHeaders()
      };
      const result = await http(config);

      dispatch(shopBookBankAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};