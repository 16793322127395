
import DownloadExcel from "components/utils/DownloadExcel";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormatDate from "components/utils/FormatDate";

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';
import Chip from '@mui/material/Chip';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";

import { ModalConfirm } from "components/layouts/ModalConfirm";
import { ModalDeleteSuccess } from 'components/layouts/ModalDeleteSuccess';
import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getCouponGeneralPagination, deleteCouponGeneral, downloadExcelCouponGeneral } from "../../../redux/handlers/master/coupon";

export function CouponList() {
    const dispatch = useAppDispatch();
    const couponReducer = useAppSelector((state) => state.couponReducer);
    const [date, setDate] = useState([new Date(), new Date()]);
    const [open, setOpen] = React.useState(false);
    const [openDelModal, setOpenDelModal] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [couponList, setCouponList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const setCouponData = (data) => {
        if (data && data.length > 0) {
            const couponData = data.map((element) => ({
                id: element.id,
                couponCode: element?.code,
                couponName: element?.coupon_name,
                campaignName: element?.campaign.name,
                couponDateStart: FormatDate(element?.start_date),
                couponDateEnd: FormatDate(element?.end_date),
                couponDiscount: `${element?.discount_value} ${(element?.discount_type == 'percent') ? '%' : 'บาท'}`,
                shops: element?.couponShop.length,
                couponStatus: element?.conponStatus // check with current date if today between start and end date
            }));

            setCouponList(couponData);
        } else {
            setCouponList([]);
        }
    };
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const deleteCoupon = () => {
        dispatch(deleteCouponGeneral(deleteId))
            .then((response) => {
                if (response.message === "success") {
                    setDeleteId(null)
                    setOpenConfirm(false)
                    setOpenDelModal(true)
                    dispatch(getCouponGeneralPagination(date[0], date[1], 1, pageSize, filterText));
                }
            })
            .catch((error) => {
                console.error('Error delete coupon', error);
            })
    }
    const download = () => {
        dispatch(downloadExcelCouponGeneral(date[0], date[1], filterText))
    }
    const columns = [
        {
            field: 'couponCode',
            headerName: 'รหัสคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <a href={`coupon/manage/${row.id}/information`} target="_blank" className={` text-[#3FA5FF]`}>{row.couponCode}</a>
                </div>
            ),
        },
        {
            field: 'campaignName',
            headerName: 'ชื่อแคมเปญ',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.campaignName}</div>
                </div>
            ),
        },
        {
            field: 'couponName',
            headerName: 'ชื่อคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={`  `}>{row.couponName}</div>
                </div>
            ),
        },
        {
            field: 'couponDateStart',
            headerName: 'วันที่เริ่ม',
            headerClassName: 'bg-[#F5F5F7]',
            width: 140,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.couponDateStart}</div>
                    <div className=""></div>
                </div>
            ),
        },
        {
            field: 'couponDateEnd',
            headerName: 'วันที่สิ้นสุด',
            headerClassName: 'bg-[#F5F5F7]',
            width: 140,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.couponDateEnd}</div>
                    <div className=""></div>
                </div>
            ),
        },
        {
            field: 'couponDiscount',
            headerName: 'ส่วนลด',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            renderCell: ({ row }) => (
                <div>
                    <div className="mb-1">{row.couponDiscount}</div>
                </div>
            ),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'shops',
            headerName: 'จำนวนร้านค้า/สถานที่',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            renderCell: ({ row }) => (
                <div>
                    <div className="mb-1">{row.shops}</div>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'id2',
            headerName: 'สถานะ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    {
                        row.couponStatus == 0 ? (
                            <Chip label={"ปิดใช้งาน"} className="w-[88px]" style={{
                                color: '#FF0000',
                                background: '#ff00001f',
                            }} />
                        ) : ''
                    }
                    {
                        row.couponStatus == 1 ? (
                            <Chip label={"เปิดใช้งาน"} className="w-[88px]" style={{
                                color: 'rgba(114, 225, 40, 0.87)',
                                background: '#EEFBE5',
                            }} />
                        ) : ''
                    }
                    {
                        row.couponStatus == 2 ? (
                            <Chip label={"ยังไม่ถึงกำหนด"} className="w-[120px]" style={{
                                color: 'rgba(255, 255, 255, 0.87)',
                                background: '#75788D',
                            }} />
                        ) : ''
                    }
                </>
            ),
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <IconButton href={`/coupon/manage/${row.id}/information`} className="w-[30px] mr-3 h-[30px] p-1" style={{
                        background: '#E3E8F0',
                    }} >
                        <img src={ic_edit} alt="" />
                    </IconButton>
                    <IconButton onClick={() => {
                        setDeleteId(row.id)
                        setOpenConfirm(true)
                    }} className="w-[30px] mr-3 h-[30px] p-1" style={{
                        background: '#F95057',
                    }} >
                        <img src={ic_trash} alt="" />
                    </IconButton>
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getCouponGeneralPagination(date[0], date[1], page, pageSize, filterText));
    }, [date, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (couponReducer.data != null && couponReducer.data.data) {
            const data = couponReducer.data.data.data;
            setCouponData(data);
            setTotalRows(couponReducer?.data?.data?.meta?.total);
        }
    }, [couponReducer]);

    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">จัดการคูปอง</div>
            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[15px] pt-[15px]">
                    <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหา"
                                onKeyUp={handleSearch}
                            />
                        </div>
                        <Link
                            to="/coupon/manage/information"
                            className="btn px-4 h-[38px] text-white  bg-[#31A7DE]"
                        >
                            สร้างคูปอง
                        </Link>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={couponList}
                        disableColumnMenu
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: pageSize,
                                },
                            },
                        }}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        pageSizeOptions={[5, 20, 30]}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px',

                            },
                            '.MuiDataGrid-columnHeader': {
                                backgroundColor: '#F5F5F7'
                            },
                            '& .MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px',
                            },
                        }}

                    />
                </div>
            </div>
            <ModalCreate open={open} setOpen={setOpen} />
            <ModalDeleteSuccess open={openDelModal} setOpen={setOpenDelModal} />
            <ModalConfirm
                open={openConfirm}
                setOpen={setOpenConfirm}
                message={"คุณต้องการลบข้อมูลนี้ใช่หรือไม่"}
                confirm={() => deleteCoupon()}
            />
        </>
    );
}

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/coupon">
        คูปอง
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
        จัดการคูปอง
    </Typography>
];

const ModalCreate = ({ open, setOpen }) => {


    let navigate = useNavigate();

    const handleClose = () => setOpen(false);
    const [formdata, setFormdata] = useState({
        campaignName: "",
        date: "",
        campaignType: ""
    })

    const schema = yup.object().shape({
        campaignName: yup.string().required(),
        date: yup.array().required(),
        campaignType: yup.string().required(),
    });


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="bg-[#31A7DE] flex items-center w-full">
                    <div className="text-lg font-bold text-white px-5 py-2 grow mr-atuo">สร้างโปรโมชั่น</div>
                    <IconButton onClick={() => {
                        setOpen(false)
                    }}>
                        <CloseSharpIcon className="text-white" />
                    </IconButton>
                </div>
                <Formik

                    initialValues={formdata}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={async (values) => {

                        setOpen(false)

                        return navigate("/campaign/manage/1");

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="px-7 mt-4  mb-3 py-6">
                                <div className="row">
                                    <div className="col-4">
                                        <TextField

                                            label="ชื่อแคมเปญ"
                                            name='campaignName'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.campaignName}
                                            error={!!errors.campaignName}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>
                                    <div className="col-4">

                                        <div className="formdaterange">
                                            <label className="mb-1">เลือกช่วงวันที่</label>
                                            <DateRangePicker
                                                onChange={(value) => {
                                                    setFieldValue('date', value)
                                                }}
                                                value={values.date}
                                                calendarIcon={<CalendarMonthIcon className="text-[#31A7DE]" />}
                                                className={`daterang-lg w-full ${!!errors.date ? 'error' : ''} `}
                                                name="date"
                                                error={!!errors.date}
                                            />
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="divide-x-2 divide-solid mb-6  h-[1px] border-t-[1px]"></div>
                            <div className="px-7 mb-7 ">
                                <div className="text-lg text-[#4C4E64] mb-7">เลือกรูปแบบสร้างโปรโมชั่น</div>
                                <div className={` ${!!errors.campaignType ? 'border-[1px] border-[#d32f2f] p-7' : ''} `}>
                                    <ToggleButtonGroup
                                        value={values.campaignType}
                                        exclusive
                                        onChange={(event, newAlignment) => {
                                            setFieldValue('campaignType', newAlignment)
                                        }}
                                        className="flex justify-between"
                                    >
                                        <ToggleButton value="1" className="w-[30%] bg-[#FAFAFA] rounded-lg border-0 promotion-select-c" >
                                            <div className="text-center w-full py-5">
                                                <img src="/images/campaign1.png" className="w-[230px] mx-auto mb-7" />
                                                <div className="px-4">
                                                    <div className="text-left text-[#31A7DE] font-bold">รับสิทธิ์จุด A ใช้ จุด B</div>
                                                    <div className="text-left text-[#000000] text-[12px]"><b>ตัวอย่าง</b>  รับคูปองส่วนลดที่โรงแรม ใช้ส่วนลดที่ร้านอาหาร</div>
                                                </div>

                                            </div>
                                        </ToggleButton>
                                        <ToggleButton value="2" className="w-[30%] bg-[#FAFAFA] rounded-lg border-0 promotion-select-c" >
                                            <div className="text-center w-full py-5">
                                                <img src="/images/campaign2.png" className="w-[150px] mx-auto mb-7" />
                                                <div className="px-4">
                                                    <div className="text-left text-[#31A7DE] font-bold">รับสิทธิ์จุด A ใช้ จุด A</div>
                                                    <div className="text-left text-[#000000] text-[12px]"><b>ตัวอย่าง</b>   เมื่อเข้าพักที่โรงแรมรับสิทธิ์ส่วนลดทันที ใช้ส่วนลดที่ร้านอาหาร</div>
                                                </div>

                                            </div>
                                        </ToggleButton>
                                        <ToggleButton value="3" className="w-[30%] bg-[#FAFAFA] rounded-lg border-0 promotion-select-c" >

                                            <div className="text-center w-full py-5">
                                                <img src="/images/campaign3.png" className="w-[80px] mx-auto mb-7" />
                                                <div className="px-4">
                                                    <div className="text-left text-[#31A7DE] font-bold">เช็คอินได้ของรางวัล</div>
                                                    <div className="text-left text-[#000000] text-[12px]"><b>ตัวอย่าง</b>  เช็คอินที่ ร้านโรงคั่วกาแฟ รับส่วนลด 5 บาท</div>
                                                </div>

                                            </div>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </div>
                            <div className="px-7  pt-5 ">
                                <div className="flex justify-end mb-7">
                                    <Button
                                        variant="outlined"
                                        className="w-[91px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => setOpen(false)}
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="w-[91px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        type="submit"
                                    >
                                        บันทึก
                                    </Button>

                                </div>
                            </div>


                        </form>
                    )}
                </Formik>

            </Box>
        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 0,
    borderRadius: 2,
    overflow: 'hidden'
};