import * as utilityAction from "../../slices/master/utility.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getUtilityServerDatetime = () => {
  return async (dispatch) => {
    dispatch(utilityAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.UTILITY_URL}/server-datetime`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(utilityAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(utilityAction.fetchDataFailure());
    }
  };
};
