import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //prefix
  data: null,
  isFetching: false,
  isError: false,
 
};
const prefixSlice = createSlice({
  name: 'prefix',
  initialState: initialState,
  reducers: {
     // prefix
    fetchData: (state) => {
      state.isFetching = true;
      state.data = null;
      state.isError = false;
    },
    fetchDataSuccess: (state, action) => {
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    },
    fetchDataFailure: (state) => {
      return { ...state, isFetching: false, isError: true };
    },
    resetState: () => initialState,
  },
});

export const { fetchData, fetchDataSuccess, fetchDataFailure, resetState } =
  prefixSlice.actions;
export default prefixSlice.reducer;
