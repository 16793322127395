import * as userShopPositionAction from "../../slices/master/userShopPosition.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";


import { endPoint } from "../../../constants/server/api";

export const getUserShopPosition = () => {
  return async (dispatch) => {
    dispatch(userShopPositionAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.USER_SHOP_POSITION_URL}/list`,
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(userShopPositionAction.fetchDataSuccess(result.data));

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(userShopPositionAction.fetchDataFailure());
    }
  };
};