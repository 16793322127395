import { combineReducers } from "@reduxjs/toolkit";

// auth

// master
import getAllTestTableReducer from "./master/getAllTestTable.slice";
import campaignReducer from "./master/campaign.slice";
import surveyReducer from "./master/survey.slice";
import surveyListReducer from "./master/surveyList.slice";
import surveyListTypeReducer from "./master/surveyListType.slice";
import surveyReportReducer from "./master/surveyReport.slice";
import couponReducer from "./master/coupon.slice";
import couponShopReducer from "./master/couponShop.slice";
import campaignShopReducer from "./master/campaignShop.slice";
import campaignCheckInReducer from "./master/campaignCheckIn.slice";
import authReducer from "./master/auth.slice";
import shopReducer from "./master/shop.slice";
import shopBookBankReducer from "./master/shopBookBank.slice";
import districtReducer from "./master/district.slice";
import provinceReducer from "./master/province.slice";
import shopCategoryReducer from "./master/shopCategory.slice";
import subDistrictReducer from "./master/subDistrict.slice";
import tagReducer from "./master/tag.slice";
import scheduleDayReducer from "./master/scheduleDay.slice";
import userShopReducer from "./master/userShop.slice";
import shopCampaignDistributionReducer from "./master/shopCampaignDistribution.slice";
import shopCouponDistributionReducer from "./master/shopCouponDistribution.slice";
import userTravellerReducer from "./master/userTraveller.slice";
import userTravellerCouponUsedReducer from "./master/userTravellerCouponUsed.slice";
import userTravellerCampaignRedemptionReducer from "./master/userTravellerCampaignRedemption.slice";
import userTravellerCouponReducer from "./master/userTravellerCoupon.slice";
import userTravellerCouponCutReducer from "./master/userTravellerCouponCut.slice";
import userAdminReducer from "./master/userAdmin.slice";
import accountTypeReducer from "./master/accountType.slice";
import bankReducer from "./master/bank.slice";
import paymentMethodReducer from "./master/paymentMethod.slice";
import taxBranchReducer from "./master/taxBranch.slice";
import vatTypeReducer from "./master/vatType.slice";
import bannerReducer from "./master/banner.slice";
import bannerSingleReducer from "./master/bannerSingle.slice";
import dashboardReducer from "./master/dashboard.slice";
import utilityReducer from "./master/utility.slice";
import campaignReportReducer from "./master/campaignReport.slice";
import campaignShopInvoiceReducer from "./master/campaignShopInvoice.slice";
import userAdminProfileReducer from "./master/userAdminProfile.slice";

import genderReducer from "./master/gender.slice";
import nationalityReducer from "./master/nationality.slice";
import prefixReducer from "./master/prefix.slice";
import userShopRoleReducer from "./master/userShopRole.slice";
import userShopPositionReducer from "./master/userShopPosition.slice";
// logic

const rootReducer = combineReducers({
  accountTypeReducer: accountTypeReducer,
  bankReducer: bankReducer,
  taxBranchReducer: taxBranchReducer,
  paymentMethodReducer: paymentMethodReducer,
  vatTypeReducer: vatTypeReducer,
  getAllTestTableReducer: getAllTestTableReducer,
  campaignReducer: campaignReducer,
  surveyReducer: surveyReducer,
  surveyListReducer: surveyListReducer,
  surveyListTypeReducer: surveyListTypeReducer,
  surveyReportReducer: surveyReportReducer,
  couponReducer: couponReducer,
  campaignShopReducer: campaignShopReducer,
  campaignCheckInReducer: campaignCheckInReducer,
  authReducer: authReducer,
  shopReducer: shopReducer,
  shopBookBankReducer: shopBookBankReducer,
  districtReducer: districtReducer,
  provinceReducer: provinceReducer,
  subDistrictReducer: subDistrictReducer,
  shopCategoryReducer: shopCategoryReducer,
  tagReducer: tagReducer,
  scheduleDayReducer: scheduleDayReducer,
  userShopReducer: userShopReducer,
  userShopRoleReducer: userShopRoleReducer,
  userShopPositionReducer: userShopPositionReducer,
  genderReducer: genderReducer,
  nationalityReducer: nationalityReducer,
  prefixReducer: prefixReducer,
  couponShopReducer: couponShopReducer,
  shopCampaignDistributionReducer: shopCampaignDistributionReducer,
  shopCouponDistributionReducer: shopCouponDistributionReducer,
  userTravellerReducer: userTravellerReducer,
  userTravellerCouponUsedReducer: userTravellerCouponUsedReducer,
  userTravellerCampaignRedemptionReducer: userTravellerCampaignRedemptionReducer,
  userTravellerCouponReducer: userTravellerCouponReducer,
  userTravellerCouponCutReducer: userTravellerCouponCutReducer,
  userAdminReducer: userAdminReducer,
  bannerReducer: bannerReducer,
  bannerSingleReducer: bannerSingleReducer,
  dashboardReducer: dashboardReducer,
  utilityReducer: utilityReducer,
  campaignReportReducer: campaignReportReducer,
  campaignShopInvoiceReducer: campaignShopInvoiceReducer,
  userAdminProfileReducer: userAdminProfileReducer
});
export default rootReducer;
