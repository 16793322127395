import Modal from '@mui/material/Modal';
import React, { useState, useEffect, useRef } from "react";
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import IconButton from '@mui/material/IconButton';
import { ProvinceList } from "components/provinces/province";
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from "@mui/x-data-grid";
import ic_edit from "assets/svg/ic_edit.svg";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { getShopPagination } from "../../redux/handlers/master/campaignCheckIn";
import { useAppDispatch, useAppSelector } from "../../redux/store/configureStore";
import { apiImageUrl } from "../../constants/server/api";
export const ModalSelectCampaignCheckinShop = ({ open, setOpen, selectLocation, campaignID }) => {
    const dispatch = useAppDispatch();

    const campaignCheckInReducer = useAppSelector((state) => state.campaignCheckInReducer);

    // State for pagination and filter
    const [shoplist, setShoplist] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [totalRows, setTotalRows] = useState(0);


    useEffect(() => {
        dispatch(getShopPagination(campaignID, page, pageSize, filterText));
    }, [campaignID, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (campaignCheckInReducer.dataShop) {

            const data = campaignCheckInReducer.dataShop.data.data;
            setDataShop(data);

            setTotalRows(campaignCheckInReducer.dataShop.data.meta.total);
        }
    }, [campaignCheckInReducer]);

    const setDataShop = (data) => {
        if (data && data.length > 0) {
            const shopData = data.map((element) => ({
                id: element.id,
                locationCode: element?.code ? element?.code : "",
                locationName: element?.name ? element?.name : "",
                locationImage: `${element?.logo_image}`,
                locationProvince: element?.subDistrict.district.province.name_th ?? "",
                locationType: element?.shopCategory.name ?? "",
                phoneNumber: element?.phone_number ?? "",
                address: element?.address ? element?.address : "",
                coordinates: `${element?.lat}  ${element?.ln} `,
                checkinScope: '',
                locationStatus: element?.is_status ? 1 : 0,
                isActive: element?.is_active
            }));
            setShoplist(shopData);
        } else {
            setShoplist([]);
        }
    };
    const handleClose = () => setOpen(false);

    const columns = [
        {
            field: 'locationCode',
            headerName: 'รหัสรายการ',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            editable: false,
            headerAlign: 'start',
            align: 'start',
            renderCell: ({ row }) => (
                <div>
                    {row.isActive &&
                        <a href={`/manage/shop/${row.id}/information`} target="_blank" className="text-[#31A7DE]">{row.locationCode}</a>
                    }
                    {!row.isActive &&
                        <span>{row.locationCode}</span>
                    }
                </div>
            ),
        },
        {
            field: 'locationName',
            headerName: 'ชื่อสถานที่',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            editable: false,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <img src={row.locationImage} className="w-[62px] h-[62px] rounded-lg" />
                    <div className="ml-3">{row.locationName}</div>
                </div>
            ),
        },
        {
            field: 'address',
            headerName: 'ที่ตั้ง',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 260,
            center: false,
            headerAlign: 'start',
            align: 'start'
        },
        {
            field: 'locationType',
            headerName: 'ประเภทร้านค้า/สถานที่',
            headerClassName: 'bg-[#F5F5F7]',
            headerAlign: 'center',
            sortable: false,
            width: 140,
            renderCell: ({ row }) => (
                <div className="">
                    <div className="">{row.locationType}</div>
                </div>
            ),
        },

        {
            field: 'phoneNumber',
            headerName: 'เบอร์โทรศัพท์',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'id',
            headerName: 'สถานะ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <Chip label={"เปิดใช้งาน"} className="w-[88px]" style={{
                        color: 'rgba(114, 225, 40, 0.87)',
                        background: '#EEFBE5',
                    }} />
                </>

            ),
        },
    ];

    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };

    // Function to handle search input change
    const handleSearch = (e) => {
        setFilterText(e.target.value);
        setPage(1);
    };

    const [shopSelectedID, setShopSelectedID] = useState([])



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal} className="pb-10">
                <div className="bg-[#31A7DE] flex items-center w-full">
                    <div className="text-lg font-bold text-white px-5 py-2 grow mr-atuo">ค้นหาร้านค้า / สถานที่</div>
                    <IconButton onClick={() => {
                        setOpen(false)
                    }}>
                        <CloseSharpIcon className="text-white" />
                    </IconButton>
                </div>
                <div className="flex justify-end px-6 mt-7">
                    <Button
                        variant="outlined"
                        className="w-[91px] border-[#F95057]/[0.5] text-[#F95057] rounded-[8px] text-sm mr-3  h-[38px]"
                        onClick={() => setOpen(false)}
                    >
                        ยกเลิก
                    </Button>
                    <Chip label={`เลือกไว้ ${shopSelectedID.length} รายการ`} className='rounded-[8px]  h-[38px] text-sm text-[#31A7DE] mr-3' />

                    <Button
                        variant="contained"
                        className="w-[91px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] "
                        onClick={() => {
                            selectLocation(shopSelectedID)
                        }}
                        disabled={!shopSelectedID.length}
                    >
                        ยืนยัน
                    </Button>

                </div>
                <div className='px-6 py-3 '>
                    <div className='text-white bg-[#31A7DE] rounded-lg d-inline-block px-3 py-2'>ร้านค้า/สถานที่ทั้งหมด</div>
                </div>
                <div className="d-flex align-items-center px-4 mb-6 flex-wrap">
                    <div className="mr-5" >

                    </div>
                    <div className="mr-auto w-[300px]">


                    </div>
                    <div className="me-3 ">
                        <input
                            type="text"
                            className="form-control form-st-4 h-[38px] w-[350px]"
                            placeholder="ค้นหาร้านค้า / สถานที่"
                            onKeyUp={handleSearch}
                        />
                    </div>
                </div>

                <div className="mb-7 overflow-auto h-[calc(100vh-278px)]">
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={shoplist}
                        columns={columns}
                        rowCount={totalRows}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: pageSize,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick
                        checkboxSelection
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                backgroundColor: '#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px',
                                color: "#4C4E64",
                                fontWeight: 700
                            },
                            '.MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-footerContainer': {
                                marginRight: '20px'
                            }
                        }}

                        onRowSelectionModelChange={(ids) => {

                            setShopSelectedID(ids)
                        }}
                    />
                </div>
            </Box>
        </Modal>
    )
}

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 0,
    borderRadius: 2,
    overflow: 'hidden'
};