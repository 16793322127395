import * as userTravellerCampaignRedemptionAction from "../../slices/master/userTravellerCampaignRedemption.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";
import { endPoint } from "../../../constants/server/api";

export const getUserTravellerCampaignRedemptionPagination = (user_traveller_id = "", page = 1, perPage = 12, s = "") => {
  return async (dispatch) => {
    dispatch(userTravellerCampaignRedemptionAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.USER_TRAVELLER_CAMPAIGN_REDEMPTION_URL}/pagination`,
        params: {
          user_traveller_id,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(userTravellerCampaignRedemptionAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(userTravellerCampaignRedemptionAction.fetchDataFailure());
    }
  };
};

export const getUserTravellerCampaignRedemptionById = (id) => {
  return async (dispatch) => {
    dispatch(userTravellerCampaignRedemptionAction.fetchData());
    try {
      const config = {
        method: "get",
        url: `${endPoint.USER_TRAVELLER_CAMPAIGN_REDEMPTION_URL}/${id}`,
        headers: setHeaders()
      };
      const result = await http(config);

      dispatch(userTravellerCampaignRedemptionAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};