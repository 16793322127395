const domain = process.env.REACT_APP_API_URL;

export { domain };

export const apiUrl = process.env.REACT_APP_API_URL;
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY

export const apiImageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const YES = "YES";
export const NO = "NO";
export const OK = "OK";
export const TOKEN = "TOKEN";
export const VER = process.env.VITE_VERSION;

export const endPoint = {
  ACCOUNT_TYPE_URL: `${apiUrl}account-type`,
  BANK_URL: `${apiUrl}bank`,
  PAYMENT_METHOD_URL: `${apiUrl}payment-method`,
  TAX_BRANCH_URL: `${apiUrl}tax-branch`,
  VAT_TYPE_URL: `${apiUrl}vat-type`,
  TEST_TABLE_URL: `${apiUrl}test-table`,
  CAMPAIGN_URL: `${apiUrl}campaign`,
  COUNT_CAMPAIGN_PROCESS_URL: `${apiUrl}campaign/count_campaign_process`,
  CAMPAIGN_TYPE_URL: `${apiUrl}campaign-type`,
  SURVEY_URL: `${apiUrl}survey`,
  SURVEY_LIST_URL: `${apiUrl}survey-list`,
  SURVEY_LIST_TYPE_URL: `${apiUrl}survey-list-type`,
  SURVEY_REPORT_URL: `${apiUrl}survey-report`,
  COUPON_URL: `${apiUrl}coupon`,
  COUPON_GENERAL_URL: `${apiUrl}coupon-general`,
  COUPON_SHOP_URL: `${apiUrl}coupon-shop`,
  CAMPAIGN_SHOP_URL: `${apiUrl}campaign-shop`,
  SHOP_URL: `${apiUrl}shop`,
  SHOP_BOOK_BANK_URL: `${apiUrl}shop-book-bank`,
  SHOP_CAMPAIGN_DISTRIBUTION_URL: `${apiUrl}shop-campaign-distribution`,
  SHOP_COUPON_DISTRIBUTION_URL: `${apiUrl}shop-coupon-distribution`,
  CAMPAIGN_CHECK_IN_URL: `${apiUrl}campaign-check-in`,
  AUTH_URL: `${apiUrl}user-admin`,
  DISTRICT_URL: `${apiUrl}district`,
  SUB_DISTRICT_URL: `${apiUrl}sub-district`,
  PROVINCE_URL: `${apiUrl}province`,
  SHOP_CATEGORY_URL: `${apiUrl}shop-category`,
  TAG_URL: `${apiUrl}tag`,
  SCHEDULE_DAY_URL: `${apiUrl}schedule-day`,
  USER_SHOP_URL: `${apiUrl}user-shop`,
  USER_TRAVELLER_URL: `${apiUrl}user-traveller`,
  USER_TRAVELLER_COUPON_URL: `${apiUrl}user-traveller-coupon`,
  USER_TRAVELLER_COUPON_USED_URL: `${apiUrl}user-traveller-coupon-used`,
  USER_TRAVELLER_COUPON_CUT_URL: `${apiUrl}user-traveller-coupon-cut`,
  USER_TRAVELLER_CAMPAIGN_REDEMPTION_URL: `${apiUrl}user-traveller-campaign-redemption`,
  USER_SHOP_EMPLOYEE_BO_URL: `${apiUrl}user-shop-employee-bo`,
  USER_ADMIN_URL: `${apiUrl}user-admin`,
  GENDER_URL: `${apiUrl}gender`,
  NATIONALITY_URL: `${apiUrl}nationality`,
  PREFIX_URL: `${apiUrl}prefix-name`,
  USER_SHOP_POSITION_URL: `${apiUrl}user-shop-position`,
  USER_SHOP_ROLE_URL: `${apiUrl}user-shop-role`,
  BANNER_URL: `${apiUrl}banner`,
  BANNER_SINGLE_URL: `${apiUrl}banner-single`,
  DASHBOARD_URL: `${apiUrl}dashboard`,
  UTILITY_URL: `${apiUrl}utility`,
  CAMPAIGN_REPORT_URL: `${apiUrl}campaign-report`,
  INVOICE_URL: `${apiUrl}invoice`,
  USER_ADMIN_PROFILE_URL: `${apiUrl}user-admin-profile`,
};
