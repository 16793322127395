import * as paymentMethodAction from "../../slices/master/paymentMethod.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getPaymentMethodList = () => {
  return async (dispatch) => {
    dispatch(paymentMethodAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.PAYMENT_METHOD_URL}/list`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(paymentMethodAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(paymentMethodAction.fetchDataFailure());
    }
  };
};

export const getPaymentMethod = (id) => {
  return async (dispatch) => {
    dispatch(paymentMethodAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.PAYMENT_METHOD_URL}/${id}`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(paymentMethodAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(paymentMethodAction.fetchDataFailure());
    }
  };
};