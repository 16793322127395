
import React, { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';

import Marker from "components/layouts/Marker";

import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ThailandAddressTypeahead, ThailandAddressValue } from 'react-thailand-address-typeahead'
import MenuItem from '@mui/material/MenuItem';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import { googleApiKey } from "../../../../constants/server/api";

import { info, infoinitial, shoptype } from "mocks/shop";
import Form from 'react-bootstrap/Form'
import { TagsInput } from "react-tag-input-component";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { ModalSuccess } from "components/layouts/ModalSuccess";
import GoogleMap, { LatLngLiteral } from 'google-maps-react-markers'
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { updateShop, createShop, getShopById } from "../../../../redux/handlers/master/shop";

import { getProvince } from "../../../../redux/handlers/master/province";
import { getShopCategory } from "../../../../redux/handlers/master/shopCategory";

import { getSubDistrict } from "../../../../redux/handlers/master/subDistrict";
import { getDistrict } from "../../../../redux/handlers/master/district";
import { getScheduleDay } from "../../../../redux/handlers/master/scheduleDay";
import { QuillCustom } from "components/fragments/QuillCustom";

export function InformationShop() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    let { shopID } = useParams();
    // Now you can use the id (which in your example would be '3')
    useEffect(() => {
        if (shopID != 0) {
            dispatch(getShopById(shopID));
        }
    }, [shopID])
    const [modalSaveOpen, setModalSaveOpen] = useState(false)
    const shopReducer = useAppSelector((state) => state.shopReducer);
    const provinceReducer = useAppSelector((state) => state.provinceReducer);
    const districtReducer = useAppSelector((state) => state.districtReducer);
    const subDistrictReducer = useAppSelector((state) => state.subDistrictReducer);
    const shopCategoryReducer = useAppSelector((state) => state.shopCategoryReducer);
    const scheduleDayReducer = useAppSelector((state) => state.scheduleDayReducer);

    const [provinceList, setProvinceList] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [subDistrictList, setSubDistrictList] = useState([])
    const [scheduleDayList, setScheduleDayList] = useState([])
    const [ShopCategoryList, setShopCategoryList] = useState([])

    useEffect(() => {
        dispatch(getProvince());
        dispatch(getShopCategory());
        dispatch(getScheduleDay());
    }, [])

    useEffect(() => {
        if (shopReducer.data) {
            const data = shopReducer.data.data;
            let dataTranfer = {
                "code": data?.code,
                "logo_image": data?.logo_image,
                "banner_image": data?.banner_image,
                "name": data?.name,
                "detail": data?.detail,
                "shop_category_id": data?.shop_category_id,
                "link": data?.link,
                "address": data?.address,
                "sub_district_id": data?.sub_district_id,
                "district_id": data?.subDistrict?.district?.id,
                "province_id": data?.subDistrict?.district?.province_id,
                "zipcode": data?.subDistrict?.zip_code,
                "uidline": data?.uid_line,
                "phone_number": data?.phone_number,
                "shop_tag": [],
                "lat": data?.lat ? Number(data?.lat) : null,
                "ln": data?.lat ? Number(data?.ln) : null,
            }
            if (data.shopTag && data.shopTag.length > 0) {
                let tagData = []
                for (const element of data.shopTag) {
                    tagData.push(element.tag.name)
                }
                dataTranfer.shop_tag = tagData
            }
            if (data.shopHour && data.shopHour.length > 0) {
                let dateData = day
                for (const element of data.shopHour) {

                    if (element.schedule_day_id == 1) {
                        setAllDay(

                            {
                                "id": 1,

                                "timeStart": convertISOToTime(element.opening_time),
                                "timeEnd": convertISOToTime(element.closing_time),
                                "status": 1
                            }
                        )
                    }
                    if (element.schedule_day_id == 2) {
                        dateData[0] = {
                            "id": element.schedule_day_id,
                            "day": "monday",
                            "dayTh": "วันจันทร์",
                            "timeStart": convertISOToTime(element.opening_time),
                            "timeEnd": convertISOToTime(element.closing_time),
                            "status": 1
                        }
                    }
                    if (element.schedule_day_id == 3) {
                        dateData[1] = {
                            "id": element.schedule_day_id,
                            "day": "tuesday",
                            "dayTh": "วันอังคาร",
                            "timeStart": convertISOToTime(element.opening_time),
                            "timeEnd": convertISOToTime(element.closing_time),
                            "status": 1
                        }
                    }
                    if (element.schedule_day_id == 4) {
                        dateData[2] = {
                            "id": element.schedule_day_id,
                            "day": "wednesday",
                            "dayTh": "วันพุธ",
                            "timeStart": convertISOToTime(element.opening_time),
                            "timeEnd": convertISOToTime(element.closing_time),
                            "status": 1
                        }
                    }
                    if (element.schedule_day_id == 5) {
                        dateData[3] = {
                            "id": element.schedule_day_id,
                            "day": "thursday",
                            "dayTh": "วันพฤหัสบดี",
                            "timeStart": convertISOToTime(element.opening_time),
                            "timeEnd": convertISOToTime(element.closing_time),
                            "status": 1
                        }
                    }
                    if (element.schedule_day_id == 6) {
                        dateData[4] = {
                            "id": element.schedule_day_id,
                            "day": "friday",
                            "dayTh": "วันศุกร์",
                            "timeStart": convertISOToTime(element.opening_time),
                            "timeEnd": convertISOToTime(element.closing_time),
                            "status": 1
                        }
                    }
                    if (element.schedule_day_id == 7) {
                        dateData[5] = {
                            "id": element.schedule_day_id,
                            "day": "saturday",
                            "dayTh": "วันเสาร์",
                            "timeStart": convertISOToTime(element.opening_time),
                            "timeEnd": convertISOToTime(element.closing_time),
                            "status": 1
                        }
                    }
                    if (element.schedule_day_id == 8) {
                        dateData[6] = {
                            "id": element.schedule_day_id,
                            "day": "sunday",
                            "dayTh": "วันอาทิตย์",
                            "timeStart": convertISOToTime(element.opening_time),
                            "timeEnd": convertISOToTime(element.closing_time),
                            "status": 1
                        }
                    }




                }
                setDay(dateData)
            }
            if (dataTranfer.lat && dataTranfer.ln) {
                setCoordinates({
                    lat: dataTranfer.lat,
                    lng: dataTranfer.ln,
                    name: ""
                })
            }

            dispatch(getDistrict(dataTranfer.province_id));
            dispatch(getSubDistrict(dataTranfer.district_id));

            setFormdata(dataTranfer)
        }
    }, [shopReducer]);

    useEffect(() => {
        if (scheduleDayReducer.data) {
            const data = scheduleDayReducer.data.data;
            setScheduleDayList(data);
        }
    }, [scheduleDayReducer]);

    useEffect(() => {
        if (shopCategoryReducer.data) {
            const data = shopCategoryReducer.data.data;
            if (data && data.length > 0) {
                let ArrayData = []
                for (const element of data) {
                    ArrayData.push({
                        label: element.name,
                        value: element.id
                    })
                }
                setShopCategoryList(ArrayData);
            }
        }
    }, [shopCategoryReducer]);

    useEffect(() => {
        if (provinceReducer.data) {
            const data = provinceReducer.data.data;
            if (data && data.length > 0) {
                let ArrayData = []
                for (const element of data) {
                    ArrayData.push({
                        label: element.name_th,
                        value: element.id
                    })
                }
                setProvinceList(ArrayData);
            }
        }
    }, [provinceReducer]);

    useEffect(() => {
        if (districtReducer.data) {
            const data = districtReducer.data.data;
            if (data && data.length > 0) {
                let ArrayData = []
                for (const element of data) {
                    ArrayData.push({
                        label: element.name_th,
                        value: element.id,

                    })
                }
                setDistrictList(ArrayData);
            }
        }

    }, [districtReducer])

    useEffect(() => {
        if (subDistrictReducer.data) {
            const data = subDistrictReducer.data.data;
            if (data && data.length > 0) {
                let ArrayData = []
                for (const element of data) {
                    ArrayData.push({
                        label: element.name_th,
                        value: element.id,
                        code: element.code,
                        zip_code: element.zip_code
                    })
                }
                setSubDistrictList(ArrayData);
            }
        }

    }, [subDistrictReducer])

    const handleChangeProvince = (province_id) => {
        dispatch(getDistrict(province_id))
    }
    const handleChangeDistrict = (district_id) => {
        dispatch(getSubDistrict(district_id))
    }


    const inputFileRef = useRef(null);
    const inputFileCoverRef = useRef(null);

    const [image, setImage] = useState(null);
    const [imagecover, setImagecover] = useState(null);

    const [formdata, setFormdata] = useState(infoinitial)


    const schema = yup.object().shape({
        name: yup.string().required(),
        lat: yup.string().required(),
        ln: yup.string().required(),


    });




    const [allday, setAllDay] = useState(
        {
            "id": 1,
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        },
    )

    const [day, setDay] = useState([
        {
            "id": 2,
            "day": "monday",
            "dayTh": "วันจันทร์",
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        },
        {
            "id": 3,
            "day": "tuesday",
            "dayTh": "วันอังคาร",
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        },
        {
            "id": 4,
            "day": "wednesday",
            "dayTh": "วันพุธ",
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        },
        {
            "id": 5,
            "day": "thursday",
            "dayTh": "วันพฤหัสบดี",
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        },
        {
            "id": 6,
            "day": "friday",
            "dayTh": "วันศุกร์",
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        },
        {
            "id": 7,
            "day": "saturday",
            "dayTh": "วันเสาร์",
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        },
        {
            "id": 8,
            "day": "sunday",
            "dayTh": "วันอาทิตย์",
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        }
    ])

    const [breaktime, setBreaktime] = useState(
        {
            "id": 9,
            "timeStart": "00:00",
            "timeEnd": "00:00",
            "status": 0
        },
    )



    const mapRef = useRef(null)
    const [mapReady, setMapReady] = useState(false)

    const onGoogleApiLoaded = ({ map, maps }) => {
        mapRef.current = map
        setMapReady(true)
    }

    const onMarkerClick = (e, { markerId, lat, lng }) => {


        let latf = parseFloat(lat)
        let lngf = parseFloat(lng)

        mapRef.current.setCenter({
            lat: latf,
            lng: lngf
        })
    }

    const [coordinates, setCoordinates] = useState(
        {
            lat: 16.0545427,
            lng: 103.6523976,
            name: "สวนสมเด็จ"
        }
    )
    const convertTimeToISO = (timeString) => {
        const date = new Date("2024-07-04");
        const [hours, minutes] = timeString.split(':').map(Number);

        // กำหนดเวลา (ชั่วโมงและนาที)
        date.setHours(hours, minutes, 0, 0);

        // แปลงเป็น ISO 8601 string
        return date.toISOString();


    };

    const convertISOToTime = (isoString) => {
        const date = new Date(isoString);
        // แยกชั่วโมงและนาทีจาก date
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };


    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            let shop_hour = []
            if (values.allday.status == 1) {
                shop_hour.push({
                    schedule_day_id: values.allday.id,
                    opening_time: convertTimeToISO(values.allday.timeStart),
                    closing_time: convertTimeToISO(values.allday.timeEnd),
                })
            }

            if (values.breaktime.status == 1) {
                shop_hour.push({
                    schedule_day_id: values.breaktime.id,
                    opening_time: convertTimeToISO(values.allday.timeStart),
                    closing_time: convertTimeToISO(values.allday.timeEnd),
                })
            }
            for (const element of values.day) {

                if (element.day == "monday" && element.status == 1) {
                    shop_hour.push({
                        schedule_day_id: element.id,
                        opening_time: convertTimeToISO(element.timeStart),
                        closing_time: convertTimeToISO(element.timeEnd),
                    })
                }
                if (element.day == "tuesday" && element.status == 1) {
                    shop_hour.push({
                        schedule_day_id: element.id,
                        opening_time: convertTimeToISO(element.timeStart),
                        closing_time: convertTimeToISO(element.timeEnd),
                    })
                }
                if (element.day == "wednesday" && element.status == 1) {
                    shop_hour.push({
                        schedule_day_id: element.id,
                        opening_time: convertTimeToISO(element.timeStart),
                        closing_time: convertTimeToISO(element.timeEnd),
                    })
                }
                if (element.day == "thursday" && element.status == 1) {
                    shop_hour.push({
                        schedule_day_id: element.id,
                        opening_time: convertTimeToISO(element.timeStart),
                        closing_time: convertTimeToISO(element.timeEnd),
                    })
                }
                if (element.day == "friday" && element.status == 1) {
                    shop_hour.push({
                        schedule_day_id: element.id,
                        opening_time: convertTimeToISO(element.timeStart),
                        closing_time: convertTimeToISO(element.timeEnd),
                    })
                }
                if (element.day == "saturday" && element.status == 1) {
                    shop_hour.push({
                        schedule_day_id: element.id,
                        opening_time: convertTimeToISO(element.timeStart),
                        closing_time: convertTimeToISO(element.timeEnd),
                    })
                }
                if (element.day == "sunday" && element.status == 1) {
                    shop_hour.push({
                        schedule_day_id: element.id,
                        opening_time: convertTimeToISO(element.timeStart),
                        closing_time: convertTimeToISO(element.timeEnd),
                    })
                }
            }

            const formData = new FormData();
            formData.append('shop_hour', JSON.stringify(shop_hour));
            formData.append('name', values.name);
            formData.append('detail', values.detail);
            formData.append('phone_number', values.phone_number);
            formData.append('address', values.address);
            formData.append('sub_district_id', values.sub_district_id);
            // formData.append('uid_line', values.uidline);

            if (values.lat) {
                formData.append('lat', values.lat);
            }
            if (values.lat) {
                formData.append('ln', values.ln);
            }

            if (image) {
                formData.append('logo_image', image);
            }
            if (imagecover) {
                formData.append('banner_image', imagecover);
            }
            if (values.shop_category_id) {
                formData.append('shop_category_id', values.shop_category_id);
            }
            if (values.link) {
                formData.append('link', values.link);
            }

            if (values.shop_tag && values.shop_tag.length > 0) {
                const shopTagString = values.shop_tag.join(',');
                // เพิ่ม string ที่ได้ลงใน FormData
                formData.append('shop_tag', shopTagString);
            }


            if (shopID != 0) {
                const response = await dispatch(updateShop(shopID, formData));
                if (response.message === 'success') {
                    // alert('Save success');
                    setModalSaveOpen(true)
                }
            } else {
                const response = await dispatch(createShop(formData));
                if (response.message === 'success') {
                    // alert('Save success');
                    setModalSaveOpen(true)
                    navigate(`/manage/shop/list`)
                }
            }

        } catch (error) {
            console.error('Error creating campaign', error);
        } finally {
            setSubmitting(false);
        }
    };




    return (
        <>

            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px]">
                <div className="text-[#31A7DE] font-bold text-lg mb-5">ข้อมูลร้านค้า/สถานที่</div>
                <div className="text-[#4C4E64]/[0.68]  text-base mb-5">โลโก้ร้านค้า/สถานที่</div>



                <Formik

                    initialValues={formdata}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            setFieldValue("allday", allday)
                            setFieldValue("day", day)
                            setFieldValue("breaktime", breaktime)

                            handleSubmit();
                        }}>
                            <div className="flex items-center mb-9">
                                <Avatar
                                    alt=""
                                    variant="square"
                                    src={image ? URL.createObjectURL(image) : `${values.logo_image}`}
                                    sx={{ width: 101, height: 101, borderRadius: '5px' }}
                                />
                                <div className="ml-6">
                                    <Button
                                        variant="contained"
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileRef.current.value = ""
                                            setImage(null)
                                        }}
                                    >
                                        ลบ
                                    </Button>


                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 5MB</div>
                                    <input
                                        ref={inputFileRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {
                                            const newImage = event.target?.files?.[0];
                                            if (newImage) {
                                                const maxSizeInMB = 5;
                                                if (newImage.size > maxSizeInMB * 1024 * 1024) {
                                                    alert(`แสดงว่าขนาดไฟล์ของคุณมีขนาดเกินกำหนด`);
                                                    return; // Exit the function to prevent further processing
                                                }
                                                setImage(newImage);  // เก็บเป็นไฟล์แทน URL
                                                setFieldValue('logo_image', newImage); // เก็บไฟล์ไว้ใน form data
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center mb-9">
                                <Avatar
                                    alt=""
                                    variant="square"
                                    src={imagecover ? URL.createObjectURL(imagecover) : `${values.banner_image}`}

                                    sx={{ width: 273, height: 132, borderRadius: '5px' }}
                                />
                                <div className="ml-6">
                                    <Button
                                        variant="contained"
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileCoverRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileCoverRef.current.value = ""
                                            setImagecover(null)
                                        }}
                                    >
                                        ลบ
                                    </Button>


                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 10MB</div>
                                    <input
                                        ref={inputFileCoverRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {
                                            const newImage = event.target?.files?.[0];
                                            if (newImage) {
                                                const maxSizeInMB = 10;
                                                if (newImage.size > maxSizeInMB * 1024 * 1024) {
                                                    alert(`แสดงว่าขนาดไฟล์ของคุณมีขนาดเกินกำหนด`);
                                                    return; // Exit the function to prevent further processing
                                                }
                                                setImagecover(newImage);  // เก็บเป็นไฟล์แทน URL
                                                setFieldValue('banner_image', newImage); // เก็บไฟล์ไว้ใน form data
                                            }
                                        }}


                                    />
                                </div>
                            </div>



                            <div className="grid grid-cols-12 gap-7 mb-6">
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        disabled
                                        label="รหัสร้านค้า/สถานที่"
                                        value={values.code}
                                        name='code'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.code}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>

                                <div className="lg:col-span-5 col-span-6">
                                    <TextField

                                        label="ชื่อร้านค้า/สถานที่"
                                        name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        error={!!errors.name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />

                                </div>

                                <div className="lg:col-span-4 col-span-6">
                                    <TextField

                                        select
                                        label="ประเภทร้านค้า/สถานที่"
                                        name='shop_category_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.shop_category_id}

                                        error={!!errors.shop_category_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {ShopCategoryList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <div className="col-span-12">
                                    <div className="text-[#4C4E64]/[.87] text-lg mb-2">รายละเอียดร้าน</div>
                                    <div className="">
                                        <QuillCustom
                                            value={values.detail}
                                            onChange={(val) => {

                                                setFieldValue('detail', val)
                                            }}
                                            placeholder="รายละเอียดร้าน"

                                        />
                                    </div>
                                </div>

                                <div className="col-span-12">
                                    <TextField
                                        label="URL ร้านค้า/สถานที่"
                                        value={values.link}
                                        name='link'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.link}
                                        className={`border-[#4C4E64]/[0.22] border-[1px] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>

                                <div className="col-span-12">
                                    <TextField

                                        label="ชื่อเลขที่บริษัท ถนน และอื่นๆ"
                                        value={values.address}
                                        name='address'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.address}
                                        className={`border-[#4C4E64]/[0.22] border-[1px] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-6">

                                    <Autocomplete
                                        options={provinceList}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(e, value) => {
                                            handleChangeProvince(value ? value.value : '');
                                            setFieldValue('province_id', value ? value.value : '');
                                        }}
                                        onBlur={handleBlur}
                                        value={provinceList.find(option => option.value === values.province_id) || null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="จังหวัด"
                                                error={!!errors.province_id}
                                                className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-6">
                                    <TextField

                                        select
                                        label="อำเภอ/เขต"
                                        name='district_id'
                                        onChange={(e) => {
                                            handleChangeDistrict(e.target.value)
                                            setFieldValue('district_id', e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.district_id}

                                        error={!!errors.district_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {districtList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-6 col-span-6">
                                    <TextField

                                        select
                                        label="แขวง/ตำบล"
                                        name='sub_district_id'
                                        onChange={(e) => {
                                            let value = subDistrictList.filter(x => x.value == e.target.value)
                                            setFieldValue('zipcode', value[0].zip_code)
                                            setFieldValue('sub_district_id', e.target.value)

                                        }}
                                        onBlur={handleBlur}
                                        value={values.sub_district_id}

                                        error={!!errors.sub_district_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {subDistrictList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-6 col-span-6">
                                    <TextField

                                        label="รหัสไปรษณีย์"
                                        name='zipcode'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.zipcode}
                                        error={!!errors.zipcode}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-7 mb-6">
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="เบอร์โทร"
                                        name='phone_number'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone_number}
                                        error={!!errors.phone_number}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                                {/* <div className="lg:col-span-6 col-span-12">
                                    <TextField

                                        label="UID LINE"
                                        name='uidline'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.uidline}
                                        error={!!errors.uidline}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div> */}
                                <div className="col-span-12 tag-st-1">
                                    <Form.Group className="form-st-3">
                                        <Form.Label className="mb-1">TAG</Form.Label>
                                        <TagsInput
                                            value={values.shop_tag}
                                            onChange={(val) => {

                                                setFieldValue('shop_tag', val)

                                            }}
                                            name="shop_tag"
                                            placeHolder=""
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="mb-7">
                                <div className="mb-4">ตำแหน่งร้านค้า/สถานที่</div>
                                <div className="mb-6">
                                    <GoogleMap
                                        apiKey={googleApiKey}
                                        defaultCenter={{ lat: coordinates.lat, lng: coordinates.lng }}
                                        defaultZoom={13}
                                        options={{
                                            mapTypeId: 'roadmap'
                                        }}
                                        mapMinHeight="400px"
                                        onGoogleApiLoaded={onGoogleApiLoaded}
                                        onChange={(map) => console.log('Map moved', map)}
                                    >
                                        <Marker
                                            lat={coordinates.lat}
                                            lng={coordinates.lng}
                                            markerId={coordinates.name}
                                            onClick={onMarkerClick} // you need to manage this prop on your Marker component!
                                            draggable={true}
                                            onDragStart={(e, { latLng }) => {

                                            }}
                                            onDrag={(e, { latLng }) => {
                                                // console.log(latLng)
                                            }}
                                            onDragEnd={(e, { latLng }) => {
                                                setFieldValue('lat', latLng.lat)
                                                setFieldValue('ln', latLng.lng)

                                            }}
                                        />

                                    </GoogleMap>
                                </div>
                                <div className="grid grid-cols-12 gap-7 mb-6">
                                    <div className="col-span-6">
                                        <TextField

                                            label="ละติจูด "
                                            value={values.lat}
                                            name='lat'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.lat}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>

                                    <div className="col-span-6">
                                        <TextField

                                            label="ลองติจูด"
                                            name='ln'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ln}
                                            error={!!errors.ln}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />

                                    </div>
                                </div>
                            </div>




                            <div className="text-[#31A7DE] font-bold mb-7">ตั้งค่าเวลา เปิด - ปิดทำการ</div>
                            <ShopOpenTime
                                allday={allday}
                                setAllDay={setAllDay}
                                day={day}
                                setDay={setDay}
                                breaktime={breaktime}
                                setBreaktime={setBreaktime}
                            />


                            <div className="flex justify-end mb-7">
                                <Button
                                    variant="outlined"
                                    className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                    href="/manage/shop/list"
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    variant="contained"
                                    className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>

                            </div>
                        </form>
                    )}
                </Formik>

            </div>
            <ModalSuccess setOpen={setModalSaveOpen} open={modalSaveOpen} />
        </>
    )
}


const ShopOpenTime = ({ allday, setAllDay, day, setDay, breaktime, setBreaktime }) => {

    const handleCheckday = (e, id) => {
        let check = e.target.checked === true ? 1 : 0;
        setDay((prev) =>
            prev.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        status: el.status = check
                    }
                    : el
            )
        );
        setAllDay((prev) => ({ ...prev, status: 0, timeStart: "00:00", timeEnd: "00:00" }));
    }

    const handlestartTime = (e, id) => {
        setDay((prev) =>
            prev.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        timeStart: el.timeStart = e
                    }
                    : el
            )
        );
    }
    const handleendTime = (e, id) => {
        setDay((prev) =>
            prev.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        timeEnd: el.timeEnd = e
                    }
                    : el
            )
        );
    }

    return (
        <>
            <div className="max-w-[500px]">
                <div className='row align-items-center mb-2 pb-1'>
                    <div className='col-6'>
                        <Form.Check
                            type='checkbox'
                            id="alldays"
                            label="เปิดทำการทุกวัน"
                            className='form-check-st-1 tc-7'
                            checked={allday.status === 1}
                            onChange={(e) => {
                                if (e.target.checked === true) {
                                    setAllDay((prev) => ({ ...prev, status: 1 }));
                                    setDay((prev) =>
                                        prev.map((el) =>
                                        ({
                                            ...el
                                            , timeStart: "00:00"
                                            , status: 0
                                            , timeEnd: "00:00"
                                        })
                                        )
                                    );
                                } else {
                                    setAllDay((prev) => ({ ...prev, status: 0 }));
                                }
                            }}

                        />
                    </div>
                    <div className='col-3'>
                        <TimePicker

                            value={allday.timeStart}
                            clockIcon={null}
                            clearIcon={null}
                            format="HH:mm"
                            className="form-time"
                            disabled={allday.status === 0}
                            onChange={(e) => {
                                setAllDay((prev) => ({ ...prev, timeStart: e }));
                            }}

                        />
                    </div>
                    <div className='col-3'>
                        <TimePicker

                            value={allday.timeEnd}
                            clockIcon={null}
                            clearIcon={null}
                            format="HH:mm"
                            className="form-time"
                            disabled={allday.status === 0}
                            onChange={(e) => {
                                setAllDay((prev) => ({ ...prev, timeEnd: e }));
                            }}
                        />
                    </div>
                </div>
                <div>
                    {day.map((item, index) =>
                        <div className='row align-items-center mb-2 pb-1' key={item.id}>
                            <div className='col-6 pl-9'>
                                <Form.Check
                                    type='checkbox'
                                    id={"day" + item.id}
                                    label={item.dayTh}
                                    className='form-check-st-1 tc-7'
                                    checked={item.status === 1}
                                    onChange={(e) => {
                                        handleCheckday(e, item.id)
                                    }}

                                />
                            </div>
                            <div className='col-3'>
                                <TimePicker
                                    onChange={(e) => {
                                        handlestartTime(e, item.id)
                                    }}
                                    value={item.timeStart}
                                    clockIcon={null}
                                    clearIcon={null}
                                    format="HH:mm"
                                    className="form-time"
                                    disabled={item.status === 0}
                                />
                            </div>
                            <div className='col-3'>
                                <TimePicker
                                    onChange={(e) => {
                                        handleendTime(e, item.id)
                                    }}
                                    value={item.timeEnd}
                                    clockIcon={null}
                                    clearIcon={null}
                                    format="HH:mm"
                                    className="form-time"
                                    disabled={item.status === 0}
                                />
                            </div>
                        </div>
                    )
                    }
                </div>
                <div className='row align-items-center mb-3'>
                    <div className='col-6'>
                        <Form.Check
                            type='checkbox'
                            id="btime"
                            label="เวลาพักเบก"
                            className='form-check-st-1 tc-7'
                            checked={breaktime.status === 1}
                            onChange={(e) => {
                                if (e.target.checked === true) {
                                    setBreaktime((prev) => ({ ...prev, status: 1 }));
                                } else {
                                    setBreaktime((prev) => ({ ...prev, status: 0 }));
                                }
                            }}

                        />
                    </div>
                    <div className='col-3'>
                        <TimePicker
                            onChange={(e) => {
                                setBreaktime((prev) => ({ ...prev, timeStart: e }));
                            }}
                            value={breaktime.timeStart}
                            clockIcon={null}
                            clearIcon={null}
                            format="HH:mm"
                            className="form-time"
                            disabled={breaktime.status === 0}

                        />
                    </div>
                    <div className='col-3'>
                        <TimePicker
                            onChange={(e) => {
                                setBreaktime((prev) => ({ ...prev, timeEnd: e }));
                            }}
                            value={breaktime.timeEnd}
                            clockIcon={null}
                            clearIcon={null}
                            format="HH:mm"
                            className="form-time"
                            disabled={breaktime.status === 0}
                        />
                    </div>
                </div>

            </div>
        </>
    )
}