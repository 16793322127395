import * as vatTypeAction from "../../slices/master/vatType.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getVatTypeList = () => {
  return async (dispatch) => {
    dispatch(vatTypeAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.VAT_TYPE_URL}/list`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(vatTypeAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(vatTypeAction.fetchDataFailure());
    }
  };
};

export const getVatType = (id) => {
  return async (dispatch) => {
    dispatch(vatTypeAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.VAT_TYPE_URL}/${id}`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(vatTypeAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(vatTypeAction.fetchDataFailure());
    }
  };
};