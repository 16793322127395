import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const isAuthenticated = () => {
  if( localStorage.getItem('token')){
    return true
  }else{
    return false
  } 
};

const GuestRoute = ({ children }) => {
  return !isAuthenticated() ? children : <Navigate to="/" />;
};

export default GuestRoute;