import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from "../../redux/store/configureStore";
import { getUserAdminProfile } from "../../redux/handlers/master/userAdminProfile";

export function Navcustom() {
    const dispatch = useAppDispatch();
    const userAdminProfileReducer = useAppSelector((state) => state.userAdminProfileReducer);
    const [userAdminProfile, setUserAdminProfile] = useState(null);
    const [openProfileAvatar, setOpenProfileAvatar] = useState(false)
    function handleSidebar(e) {
        var sidebar = document.getElementsByClassName('app');
        for (var i = 0; i < sidebar.length; i++) {
            sidebar[i].classList.toggle('sidenav-toggled')
        }
    }
    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenProfileAvatar(false); // Close dropdown
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(getUserAdminProfile())
    }, [])

    useEffect(() => {
        if (userAdminProfileReducer.data) {
            const data = userAdminProfileReducer.data.data;
            setUserAdminProfile(data);
        }
    }, [userAdminProfileReducer.data]);

    return (
        <nav className="navbar fixed-top navbar-st-1 shadow-none" style={{ backgroundColor: '#31A7DE' }}>
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <div className="app-sidebar__toggle  me-4" data-toggle="sidebar" onClick={handleSidebar} >
                        <a className="open-toggle" href="#"></a>
                        <a className="close-toggle" href="#"></a>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="dropdown nav-item main-header-notification me-xl-3 me-2">
                        <a className="new nav-link" href="#" data-bs-toggle="dropdown"><div className="ih-1 p-4"></div></a>
                        <div className="dropdown-menu">
                        </div>
                    </div>
                    <div className="dropdown me-xl-5" ref={dropdownRef}>
                        <a className={`btn dropdown-toggle-1 drop-left ${openProfileAvatar ? 'show' : ''}`} onClick={() => setOpenProfileAvatar(!openProfileAvatar)} role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <div className="d-flex align-items-center">
                                <img src={(userAdminProfile?.image_path) ? userAdminProfile?.image_path : "/images/Avatar.png"} className="Avatar" />
                            </div>
                        </a>
                        <ul className={`dropdown-menu ${openProfileAvatar ? 'show' : ''}`} data-bs-popper="static">
                            {userAdminProfile &&
                                <li>
                                    <button className="dropdown-item text-color-6 fs-14 spacing-1 ms-2">{`${userAdminProfile?.first_name} ${userAdminProfile.last_name}`}</button>
                                </li>
                            }
                            <li>
                                <button className="dropdown-item text-color-6 fs-14 spacing-1 ms-2" onClick={() => { localStorage.clear(); window.location.reload(); }}>ออกจากระบบ</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}