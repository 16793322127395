import * as userAdminProfileAction from "../../slices/master/userAdminProfile.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getUserAdminProfile = () => {
  return async (dispatch) => {
    dispatch(userAdminProfileAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.USER_ADMIN_PROFILE_URL}`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(userAdminProfileAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(userAdminProfileAction.fetchDataFailure());
    }
  };
};
