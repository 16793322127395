import * as campaignAction from "../../slices/master/campaign.slice";
import { http } from "../../../utils/http";
import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";


import { endPoint } from "../../../constants/server/api";

export const getCampaignType= () => {
  return async (dispatch) => {
    dispatch(campaignAction.fetchDataCampaginType());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_TYPE_URL}/list`,
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(campaignAction.fetchDataCampaginTypeSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
      dispatch(campaignAction.fetchDataCampaginTypeFailure());
    }
  };
};

export const getCountCampaignProcess = (start_date,end_date,s="") => {
  return async (dispatch) => {
    dispatch(campaignAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.COUNT_CAMPAIGN_PROCESS_URL}`,
        params: {
          start_date,
          end_date,
          s
        }
      };

      const result = await http(config); 
      dispatch(campaignAction.fetchDataCountCampaignProcessSuccess(result.data));
      
    } catch (error) {
      if( error.response.statusText === "Unauthorized" ){
        handleUnauthorized()
      }
      dispatch(campaignAction.fetchDataCountCampaignProcessFailure());
    }
  };
};

export const getCampaignPagination = (start_date,end_date,page=1,perPage=12,s="") => {
  return async (dispatch) => {
    dispatch(campaignAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.CAMPAIGN_URL}`,
        params: {
          start_date,
          end_date,
          page,
          perPage,
          s
        }
      };

      const result = await http(config); 
      dispatch(campaignAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if( error.response.statusText === "Unauthorized" ){
        handleUnauthorized()
      }
      dispatch(campaignAction.fetchDataFailure());
    }
  };
};

export const getAllCampaignPagination = (start_date,end_date,page=1,perPage=12,s="", campaign_id = null) => {
  return async (dispatch) => {
    dispatch(campaignAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.CAMPAIGN_URL}/pagination-all-campaign`,
        params: {
          start_date,
          end_date,
          page,
          perPage,
          s,
          campaign_id
        }
      };

      const result = await http(config); 
      dispatch(campaignAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if( error.response.statusText === "Unauthorized" ){
        handleUnauthorized()
      }
      dispatch(campaignAction.fetchDataFailure());
    }
  };
};

export const createCampaign = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.CAMPAIGN_URL}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };

      const result = await http(config);

      return result.data; 

    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
    }
  };

};

export const updateCampaign = (data,id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.CAMPAIGN_URL}/${id}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};

export const copyCampaign = (campaignIds) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.CAMPAIGN_URL}/copy`,
        data: { ids: campaignIds },
        headers: setHeaders()
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
      throw error; 
    }
  };
};

export const deleteCampaign = (campaignIds) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.CAMPAIGN_URL}/delete`,
        data: { ids: campaignIds },
        headers: setHeaders()
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const getCampaignById = (id) => {
  return async (dispatch) => {
    dispatch(campaignAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_URL}/${id}`,
        headers: setHeaders()
      };

      const result = await http(config);
      dispatch(campaignAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
    }
  };
  
};

export const downloadExcelCampaign = (start_date,end_date, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_URL}/download/excel`,
        params: {
          start_date,
          end_date,
          s 
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'campaign.xlsx'; // Default file name
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);

    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }

    }
  };
};

// overview

export const getCampaignByShopId = (shopId) => {
  return async (dispatch) => {
    dispatch(campaignAction.fetchDataCampaignShop());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_URL}/shopId/${shopId}`,
        headers: setHeaders()
      };

      const result = await http(config);
      dispatch(campaignAction.fetchDataCampaignShopSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
    }
  };
};

export const getCampaignEntierOverview = (shopId, campaign_id = '') => {
  return async (dispatch) => {
    dispatch(campaignAction.fetchDataCampaignEntierOverview());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_URL}/entierOverview/shopId/${shopId}`,
        headers: setHeaders(),
        params: {
          campaign_id
        }
      };

      const result = await http(config);
      dispatch(campaignAction.fetchDataCampaignEntierOverviewSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
    }
  };
};

export const getCampaignDueOverview = (shopId, start_date = '', end_date = '') => {
  return async (dispatch) => {
    dispatch(campaignAction.fetchDataCampaignDueOverview());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_URL}/dueOverview/shopId/${shopId}`,
        headers: setHeaders(),
        params: {
          start_date,
          end_date
        }
      };

      const result = await http(config);
      dispatch(campaignAction.fetchDataCampaignDueOverviewSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText === "Unauthorized"){
        handleUnauthorized()
      }
    }
  };
};