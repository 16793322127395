import * as userTravellerCouponUsedAction from "../../slices/master/userTravellerCouponUsed.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";
import { endPoint } from "../../../constants/server/api";

export const getUserTravellerCouponUsedPagination = (user_traveller_id = "", page = 1, perPage = 12, s = "") => {
  return async (dispatch) => {
    dispatch(userTravellerCouponUsedAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.USER_TRAVELLER_COUPON_USED_URL}/pagination`,
        params: {
          user_traveller_id,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(userTravellerCouponUsedAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(userTravellerCouponUsedAction.fetchDataFailure());
    }
  };
};

export const downloadExcelUserTravellerCouponUsed = (user_traveller_id = "", s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.USER_TRAVELLER_COUPON_USED_URL}/download/excel`,
        params: {
          user_traveller_id,
          s
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'user-traveller-coupon-used.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};