import { LatLngLiteral } from 'google-maps-react-markers'
import React from 'react';
import PushPinIcon from '@mui/icons-material/PushPin';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PinDropSharpIcon from '@mui/icons-material/PinDropSharp';
import IconButton from '@mui/material/IconButton';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
const Marker = ({
	className,
	lat,
	lng,
	markerId,
	onClick,
	draggable,
	onDrag,
	onDragEnd,
	onDragStart,
	...props
}) =>
	lat && lng ? (

		<IconButton
			onClick={(e) => (onClick ? onClick(e, { markerId, lat, lng }) : null)}
			
			alt={markerId}
			className='text-[#FF0000] bg-white/[0.4] p-1'
			width={35}
			height={35}
			{...props}
			size="large"
		>
			<LocationOnSharpIcon style={{ fontSize: 40 }} />
		</IconButton>

	) : null;

export default Marker;

