import DownloadExcel from "components/utils/DownloadExcel";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';
import Chip from '@mui/material/Chip';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import FormatDate from "components/utils/FormatDate";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getCampaignReportPaginationUsed, downloadExcelCampaignReportUsed } from "../../../../redux/handlers/master/campaignReport";

export function ReportCampaignUsers() {
    let { campaignID } = useParams();
    const dispatch = useAppDispatch();
    const campaignReportReducer = useAppSelector((state) => state.campaignReportReducer);
    const [userTravellerList, setUserTravellerList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const setUserTravellerData = (data) => {
        if (data && data.length > 0) {
            const userTravellerData = data.map((element) => ({
                id: element.id,
                code: (element?.shopCouponDistribution) ? element?.shopCouponDistribution[0]?.code : '',
                name: `${element?.userTraveller?.first_name} ${element?.userTraveller?.last_name}`,
                shop: (element?.userTravellerCouponUsed) ? element?.userTravellerCouponUsed[0]?.shop.name : '',
                date: (element?.userTravellerCouponUsed) ? FormatDate(element?.userTravellerCouponUsed[0]?.created_at) : '',
                discount: (element?.coupon?.discount_type == 'amount') ? element.coupon.discount_value : 0,
            }));

            setUserTravellerList(userTravellerData);
        } else {
            setUserTravellerList([]);
        }
    };
    const download = () => {
        dispatch(downloadExcelCampaignReportUsed(campaignID, filterText))
    }
    const columns = [
        {
            field: 'code',
            headerName: 'รหัสผู้ใช้สิทธิ',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            minWidth: 200,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` text-[#3FA5FF]`}>{row.code}</div>
                </div>
            ),
        },
        {
            field: 'name',
            headerName: 'ชื่อผู้ใช้สิทธิ์แคมเปญ',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.name}</div>
                </div>
            ),
        },
        {
            field: 'shop',
            headerName: 'ร้านค้า/สถานที่ที่ใช้สิทธิ',
            flex: 1,
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.shop}</div>
                </div>
            ),
        },
        {
            field: 'date',
            headerName: 'วันที่ใช้สิทธิ',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.date}</div>
                </div>
            ),
        },
        {
            field: 'discount',
            headerName: 'ส่วนลดที่ได้รับ',
            headerClassName: 'bg-[#F5F5F7]',
            width: 140,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.discount}</div>
                    <div className=""></div>
                </div>
            ),
        },

    ];

    useEffect(() => {
        dispatch(getCampaignReportPaginationUsed(campaignID, page, pageSize, filterText));
    }, [campaignID, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        setUserTravellerList([]);

        if (campaignReportReducer.data != null) {
            const data = campaignReportReducer.data.data.data;

            setUserTravellerData(data);
            setTotalRows(campaignReportReducer?.data?.data?.meta?.total);
        }
    }, [campaignReportReducer.data]);

    return (
        <div>

            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[10px]">
                <div className="px-[15px] pt-[10px]">
                    <div className="text-lg text-bold text-[#31A7DE] mb-4">ผู้ใช้สิทธิจากแคมเปญ</div>
                    <div className="d-flex align-items-center mb-5 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหาชื่อร้านค้า/สถานที่"
                                onKeyUp={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={userTravellerList}
                        disableColumnMenu
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px',

                            },
                            '.MuiDataGrid-columnHeader': {
                                backgroundColor: '#F5F5F7'
                            },
                            '& .MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px',
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}