
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Chip from '@mui/material/Chip';
import ic_edit from "assets/svg/ic_edit.svg";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';


import Switch from '@mui/material/Switch';
import { InvPrint } from "components/pages/report/invoice/printinvoice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as yup from "yup";
import Button from '@mui/material/Button';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useReactToPrint } from "react-to-print";

import { Document } from './detailTab/document';
import { DocumentLog } from './detailTab/doucumentLog';
import { Payment } from './detailTab/payment';

import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { cancelCampaignShopInvoice, getInvoiceById, recoverCampaignShopInvoice } from "../../../../redux/handlers/master/campaignShopInvoice";
import { ModalConfirm } from 'components/layouts/ModalConfirm';

export function ReportInvoiceDetail() {
    let { invoiceID } = useParams();
    const dispatch = useAppDispatch();
    const campaignShopInvoiceReducer = useAppSelector((state) => state.campaignShopInvoiceReducer);
    const [invoice, setInvoice] = useState(null);
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="#">
            รายงาน
        </Link>,
        <Link underline="hover" key="1" color="inherit" to="/report/campaign">
            ใบแจ้งหนี้
        </Link>,
        <Link underline="hover" key="1" color="inherit" to={`/report/campaign/${invoice?.campaign_id}/invoice`}>
            {invoice ? invoice?.campaign?.name : ''}
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            {invoice ? invoice?.shop?.name : ''}
        </Typography>
    ];
    const [tab, setTab] = React.useState(0);
    const handleChangetab = (event, newValue) => {
        setTab(newValue);
    };
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [isStatus, setIsStatus] = React.useState(true);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [messageConfirm, setMessageConfirm] = React.useState("");

    const handleConfirm = (data) => {
        setOpenConfirm(false)
        handleSubmitCancel(data)
    }

    const handleSubmitCancel = async (value) => {
        console.log(value);
        if (!value) {
            dispatch(cancelCampaignShopInvoice(invoiceID)).then(_ => {
                dispatch(getInvoiceById(invoiceID));
            })
        } else {
            dispatch(recoverCampaignShopInvoice(invoiceID)).then(_ => {
                dispatch(getInvoiceById(invoiceID));
            })
        }

        setOpenConfirm(false)
    }

    useEffect(() => {
        dispatch(getInvoiceById(invoiceID));
    }, [invoiceID]);

    useEffect(() => {
        if (campaignShopInvoiceReducer.data) {
            const data = campaignShopInvoiceReducer.data.data
            setInvoice(data)
            setIsStatus(data.is_status)
        }
    }, [campaignShopInvoiceReducer.data]);

    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">{invoice ? invoice?.shop?.name : ''}</div>
            {/* <CustomTabPanel value={tab} index={2}>
                <div className='flex justify-center'>
                    <div className='min-w-[550px] flex-[0_0_550px] mb-7'>
                        <Steps docstatus={3} />
                    </div>
                </div>
            </CustomTabPanel> */}
            <div className="bg-white rounded-[7px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[10px] mb-7">
                <div className="px-[15px] pt-[5px]">
                    <div className="d-flex align-items-center px-4 mb-4 flex-wrap">
                        <div className="mr-auto" >
                            เอกสารเลขที่ <span className="text-[#31A7DE] font-medium ml-2">{invoice?.code}</span>
                        </div>
                        <div className="flex items-center">
                            <div className="mr-3">สถานะ</div>
                            {
                                invoice && !invoice?.is_status ? (
                                    <Chip label={"ยกเลิกเอกสาร"} className="w-[128px]" style={{
                                        color: '#FFF',
                                        background: 'red',
                                    }} />
                                ) : (
                                    invoice && invoice?.invoice_status == 1 ? (
                                        <Chip label={"ชำระบางส่วน"} className="w-[128px]" style={{
                                            color: '#FFAA00',
                                            background: '#FFF6F0',
                                        }} />
                                    ) : (

                                        invoice && invoice?.invoice_status == 2 ? (
                                            <Chip label={"ชำระเเล้ว"} className="w-[128px]" style={{
                                                color: '#72E128',
                                                background: '#EEFBE5',
                                            }} />
                                        ) : (
                                            <Chip label={"ค้างชำระ"} className="w-[128px]" style={{
                                                color: '#F24236',
                                                background: '#FFECEC',
                                            }} />
                                        )
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Tabs value={tab} onChange={handleChangetab} aria-label=""
                sx={{
                    '& .MuiTab-root': {
                        minHeight: '40px'
                    },
                    '& .MuiTab-root.Mui-selected': {
                        background: '#31A7DE',
                        color: "#FFFFFF",
                        fontSize: '16px',
                        borderRadius: '10px',
                        padding: '10px 15px',
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none'
                    }
                }}
            >
                <Tab label="ข้อมูลเอกสาร" {...a11yProps(0)} />
                <Tab label="ข้อมูลชำระ" {...a11yProps(1)} />
                <Tab label="ข้อมูลประวัติ" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={tab} index={0}>
                <Document invoice={invoice} />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
                <Payment invoice={invoice} />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={2}>
                <DocumentLog invoice={invoice} />
            </CustomTabPanel>
            <div className="bg-white rounded-[7px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden py-[10px] mb-7">
                <div className="px-[15px] py-[10px] flex items-center">
                    <div className=' mr-auto'>
                        <div style={{ display: "none" }}>
                            <InvPrint
                                innerRef={(el) => (componentRef.current = el)}
                                isSplit={true}
                                invoice={invoice}
                            />
                        </div>
                        <Button
                            variant="outlined"
                            className="w-[131px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                            onClick={() => handlePrint()}
                        >
                            <LocalPrintshopOutlinedIcon className='text-[18px] mr-2' />พิมพ์รายการ
                        </Button>
                        {
                            invoice && invoice.is_status ? (
                                <Button
                                    variant="outlined"
                                    className="w-[131px] border-[#F95057]/[0.5] text-[#F95057] rounded-[8px] text-sm mr-3  h-[44px]"
                                    type="submit"
                                    onClick={() => { setOpenConfirm(true); setIsStatus(false); setMessageConfirm("คุณต้องการยกเลิกเอกสารนี้หรือไม่") }}
                                >
                                    ยกเลิกเอกสาร
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    className="w-[131px] border-[#F95057]/[0.5] text-[#F95057] rounded-[8px] text-sm mr-3  h-[44px]"
                                    type="submit"
                                    onClick={() => { setOpenConfirm(true); setIsStatus(true); setMessageConfirm("คุณต้องการกู้คืนเอกสารนี้หรือไม่") }}
                                >
                                    กู้คืนเอกสาร
                                </Button>
                            )
                        }
                    </div>
                    <Button
                        variant="outlined"
                        className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                        href="/report/campaign"
                    >
                        ย้อนกลับ
                    </Button>
                </div>
            </div>
            <ModalConfirm
                open={openConfirm}
                setOpen={setOpenConfirm}
                message={messageConfirm}
                confirm={() => handleConfirm(isStatus)}
            />
        </>
    )
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Steps({ docstatus, ...props }) {
    const steps = [
        "ใบรับสินค้า",
        "บันทึกค่าใช้จ่าย",
        "ชำระเงิน"
    ];
    const [activeStep, setActiveStep] = useState(2);

    useEffect(() => {
        setActiveStep((docstatus));
    }, [docstatus]);

    return (
        <Stepper activeStep={activeStep} alternativeLabel
            sx={{
                '& .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed': {
                    color: '#31A7DE'
                }
            }}
        >
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps} style={{ fontSize: "16px" }}>
                            {label}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}