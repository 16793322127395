import * as surveyListAction from "../../slices/master/surveyList.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";

export const getSurveyList = (id) => {
  return async (dispatch) => {
    dispatch(surveyListAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.SURVEY_LIST_URL}/${id}/list`,
        headers: setHeaders(),
      };

      const result = await http(config);

      dispatch(surveyListAction.fetchDataSuccess(result.data));
    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }

      dispatch(surveyListAction.fetchDataFailure());
    }
  };
};

export const createSurveyList = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.SURVEY_LIST_URL}`,
        data: data,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data;
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const updateSurveyList = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.SURVEY_LIST_URL}`,
        data: data,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const deleteSurveyList = (id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "delete",
        url: `${endPoint.SURVEY_LIST_URL}/${id}`,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};
