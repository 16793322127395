import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import React, { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';

import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { prefix, date, months, years, gender, nationality, campaign } from "mocks/shop";
import Chip from '@mui/material/Chip';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { QuillCustom } from "components/fragments/QuillCustom";
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import FormatDate from "components/utils/FormatDate";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getCampaignReportCampaign } from "../../../../redux/handlers/master/campaignReport";

export function ReportCampaignDetail() {
    let { campaignID } = useParams();
    const dispatch = useAppDispatch();
    const campaignReportReducer = useAppSelector((state) => state.campaignReportReducer);
    const [formData, setformData] = useState({
        campaignCode: '',
        campaignName: '',
        campaignDateStart: '',
        campaignDateEnd: '',
        detailShort: '',
        detail: '',
        condition: '',
        campaignShop: '',
        campaignCoupon: '',
        campaignRight: '',
        campaignStatus: '',
        totalDiscount: ''
    })
    const schema = yup.object().shape({});

    useEffect(() => {
        dispatch(getCampaignReportCampaign(campaignID));
    }, [campaignID]);

    useEffect(() => {
        if (campaignReportReducer.data != null) {
            const data = campaignReportReducer.data.data;

            setformData({
                campaignCode: data?.code,
                campaignName: data?.name,
                campaignDateStart: data?.start_date,
                campaignDateEnd: data?.end_date,
                detailShort: data?.detail_short,
                detail: data?.detail,
                condition: (data?.coupon) ? data?.coupon[0].terms_and_conditions : '',
                campaignShop: data?.total_shop,
                campaignCoupon: data?.total_user_used,
                campaignRight: data?.total_user_redemption,
                campaignStatus: data?.campaign_status,
                totalDiscount: data?.total_discount
            });
        }
    }, [campaignReportReducer.data]);

    return (
        <>
            <div className="bg-white  rounded-[10px] drop-shadow py-4 mb-4 p-[25px]">
                <div className="flex  mb-7">
                    <div className="text-[#31A7DE] font-bold text-lg mr-auto">รายละเอียดเเคมเปญ</div>
                    {(formData?.campaignStatus == 1) &&
                        <Chip label={"ดำเนินการอยู่"} className="w-[148px]" style={{
                            color: 'rgba(114, 225, 40, 0.87)',
                            background: '#EEFBE5',
                        }} />
                    }
                    {(formData?.campaignStatus == 2) &&
                        <Chip label={"สิ้นสุดแล้ว"} className="w-[148px]" style={{
                            color: '#FF0000',
                            background: '#ff00001f',
                        }} />
                    }
                </div>
                <Formik
                    initialValues={formData}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={async (values) => {
                        alert('save')
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-12 gap-7 mb-6">
                                <div className="lg:col-span-4 col-span-6">
                                    <TextField
                                        label="รหัสแคมเปญ"
                                        value={values.campaignCode}
                                        name='campaignCode'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.campaignCode}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled
                                    />
                                </div>
                                <div className="lg:col-span-8 col-span-6">
                                    <TextField
                                        label="ชื่อแคมเปญ"
                                        name='campaignName'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.campaignName}
                                        error={!!errors.campaignName}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled
                                    />
                                </div>
                                <div className="col-span-12">
                                    <TextField
                                        label="รายละเอียดแคมเปญอย่างย่อ"
                                        name='detailShort'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.detailShort}
                                        error={!!errors.detailShort}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled
                                    />
                                </div>
                                <div className="col-span-12">
                                    <div className="text-[#4C4E64]/[.87] text-lg mb-2">รายละเอียดแคมเปญ</div>
                                    <div className="">
                                        <QuillCustom
                                            value={values.detail}
                                            onChange={(value) => {
                                                setFieldValue('detail', value)
                                            }}
                                            placeholder="ระบุรายละเอียด"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-12">
                                <div className="text-[#4C4E64]/[.87] text-lg mb-2">เงื่อนไขในการใช้สิทธิ</div>
                                    <div className="">
                                        <QuillCustom
                                            value={values.condition}
                                            onChange={(value) => {
                                                setFieldValue('condition', value)
                                            }}
                                            placeholder="ระบุเงื่อนไขในการใช้สิทธิ"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="วันที่เริ่มต้น"
                                            className='w-full'
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        sx: { width: '100%', },
                                                    },
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                            onChange={(newValue) => {
                                                setFieldValue(`campaignDateStart`, newValue)
                                            }}
                                            value={dayjs(values.campaignDateStart)}
                                            disabled
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="วันที่สิ้นสุด"
                                            className='w-full'
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        sx: { width: '100%', },
                                                    },
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                            onChange={(newValue) => {
                                                setFieldValue(`campaignDateEnd`, newValue)
                                            }}
                                            value={dayjs(values.campaignDateEnd)}
                                            disabled
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="ร้านที่เข้าร่วมแคมเปญ"
                                        name='campaignShop'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.campaignShop}
                                        error={!!errors.campaignShop}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="จำนวนผู้เข้าร่วมแคมเปญ"
                                        name='campaignRight'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.campaignRight}
                                        error={!!errors.campaignRight}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="จำนวนคูปองที่ถูกใช้งาน"
                                        name='campaignCoupon'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.campaignCoupon}
                                        error={!!errors.campaignCoupon}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="มูลค่ารวมคูปอง"
                                        name='totalDiscount'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.totalDiscount}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    )
}