
import DownloadExcel from "components/utils/DownloadExcel";
import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { ModalDeleteSuccess } from 'components/layouts/ModalDeleteSuccess';
import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getUserTravellerPagination, downloadExcelUserTraveller } from "../../../redux/handlers/master/userTraveller";
import { getProvinceTraveller } from "../../../redux/handlers/master/province";

export function TouristList() {
    const dispatch = useAppDispatch();
    const userTravellerReducer = useAppSelector((state) => state.userTravellerReducer);
    const provinceReducer = useAppSelector((state) => state.provinceReducer);
    const [openDelModal, setOpenDelModal] = React.useState(false);
    const [userTravellerList, setUserTravellerList] = useState([]);
    const [province, setProvince] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [filterProvice, setFilterProvince] = useState(0);
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const handleSelectProvince = (e) => {
        if (e.target.value && e.target.value != 0) {
            console.log(e.target.value)
            setFilterProvince(e.target.value);
        } else if (e.target.value == 0) {
            setFilterProvince(e.target.value);
        }
    };
    const setUserTravellerData = (data) => {
        if (data && data.length > 0) {
            const userTravellerData = data.map((element) => ({
                id: element.id,
                touristCode: element.code,
                touristName: `${element?.first_name} ${element?.last_name}`,
                touristImage: element?.image_path,
                touristProvince: element?.province?.name_th,
                touristTelephoneNumber: element?.phone_number,
            }));

            setUserTravellerList(userTravellerData);
        } else {
            setUserTravellerList([]);
        }
    };
    const download = () => {
        dispatch(downloadExcelUserTraveller(filterText, filterProvice))
    }
    const columns = [
        {
            field: 'touristCode',
            headerName: 'รหัสนักท่องเที่ยว',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            editable: false,
            renderCell: ({ row }) => (
                <a href={`/tourist/manage/${row.id}/information`} target="_blank" className="text-[#31A7DE]">{row.touristCode}</a>
            ),
        },
        {
            field: 'touristName',
            headerName: 'ชื่อ-นามสกุล',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            editable: false,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <img src={row.touristImage} className="w-10 h-10 rounded-full" />
                    <div className="ml-3">{row.touristName}</div>
                </div>
            ),
        },
        {
            field: 'touristProvince',
            headerName: 'จังหวัดภูมิลำเนา',
            headerClassName: 'bg-[#F5F5F7]',
            width: 110,
            flex: 1,
            editable: false,
        },
        {
            field: 'touristTelephoneNumber',
            headerName: 'เบอร์โทรศัพท์',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <IconButton href={`/tourist/manage/${row.id}/information`} className="w-[30px] mr-3 h-[30px] p-1" style={{
                        background: '#E3E8F0',
                    }} >
                        <img src={ic_edit} alt="" />
                    </IconButton>
                    <ShopButtonStatus status={row.shopStatus} href={`/manage/shop/${row.id}/information`} />
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getUserTravellerPagination(page, pageSize, filterText, filterProvice));
    }, [page, pageSize, filterText, filterProvice, dispatch]);

    useEffect(() => {
        dispatch(getProvinceTraveller());
    }, []);

    useEffect(() => {
        if (provinceReducer.data != null) {
            const data = provinceReducer.data.data;
            setProvince(data);
        }
    }, [provinceReducer]);

    useEffect(() => {
        if (userTravellerReducer.data != null && userTravellerReducer.data.data) {
            const data = userTravellerReducer.data.data.data;
            setUserTravellerData(data);
            setTotalRows(userTravellerReducer?.data?.data?.meta?.total);
        }
    }, [userTravellerReducer]);

    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">รายชื่อนักท่องเที่ยว</div>
            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[15px] py-[10px]">
                    <div className="text-[#4C4E64]/[.87] text-2xl  px-4 font-semibold mb-5">รายชื่อนักท่องเที่ยว</div>
                    <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
                        <div className="mr-auto flex" >
                            <div className="mr-3" >
                                <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>

                            </div>
                            <div className=" ">
                                <select
                                    className="form-control form-st-4 h-[38px] w-[250px] custom-select"
                                    onChange={handleSelectProvince} // Replace handleSelect with your function to handle selection
                                >
                                    <option value="0" selected>
                                        จังหวัดทั้งหมด
                                    </option>
                                    {
                                        province.map((data, index) => (
                                            <option key={index} value={data.id}>{data.name_th}</option>
                                        ))
                                    }

                                </select>
                            </div>
                        </div>

                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหาเพิ่มรายชื่อนักท่องเที่ยว"
                                onKeyUp={handleSearch}
                            />
                        </div>
                        <Link
                            to="/tourist/manage/information"
                            className="btn px-4 h-[38px] text-white  bg-[#31A7DE]"

                        >
                            เพิ่มรายชื่อนักท่องเที่ยว
                        </Link>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={userTravellerList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: pageSize,
                                },
                            },
                        }}
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        pageSizeOptions={[5, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                backgroundColor: '#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px'
                            }
                        }}
                    />
                </div>
            </div>
            <ModalDeleteSuccess open={openDelModal} setOpen={setOpenDelModal} />
        </>
    );
}

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        นักท่องเที่ยว
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
        รายชื่อนักท่องเที่ยว
    </Typography>
];