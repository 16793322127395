import * as userShopAction from "../../slices/master/userShop.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";


import { endPoint } from "../../../constants/server/api";


export const getUserShopPagination = (approve_status="all", page=1,perPage=12,s="") => {
  return async (dispatch) => {
    dispatch(userShopAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.USER_SHOP_URL}`,
        params: {
          approve_status,
          page,
          perPage,
          s
        }
      };

      const result = await http(config); 
      dispatch(userShopAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if( error.response.statusText == "Unauthorized" ){
        handleUnauthorized()
      }
      dispatch(userShopAction.fetchDataFailure());
    }
  };
};

export const createUserShop = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.USER_SHOP_URL}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };

      const result = await http(config);

      return result.data; 

    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
    }
  };

};

export const updateUserShop = (id,data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.USER_SHOP_URL}/${id}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};



export const deleteUserShop = (shopIds) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.USER_SHOP_URL}/delete`,
        data: { ids: shopIds },
        headers: setHeaders()
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };
};


export const getUserShopById = (id) => {
  return async (dispatch) => {
    dispatch(userShopAction.fetchData());
    try {
      const config = {
        method: "get",
        url: `${endPoint.USER_SHOP_URL}/shopID/${id}`,

        headers: setHeaders()
      };

      const result = await http(config);
      dispatch(userShopAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
    }
  };
  
};

