
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Chip from '@mui/material/Chip';
import ic_edit from "assets/svg/ic_edit.svg";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';


import Switch from '@mui/material/Switch';
import { InvPrint } from "components/pages/report/invoice/printinvoice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as yup from "yup";
import Button from '@mui/material/Button';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useReactToPrint } from "react-to-print";

import DeleteIcon from '@mui/icons-material/Delete';
import thaiDate from 'utils/thaiDate';

export const Payment = ({ invoice }) => {

    const [data, setData] = useState({
        campaignCode: "",
        invoiceCode: "",
        totalRecordedValue: 0,
        netTotalAmountDue: 0,
        totalAmountPaid: 0,
        paymentCompletedDate: '',
        payments: [
        ]
    });


    useEffect(() => {
        console.log(invoice);
        let payments = [];
        let paymentCompletedDate = "";

        for (const campaignShopInvoicePaymentRecord of invoice.campaignShopInvoicePaymentRecord) {
            let payment_detail = [];
            for (const campaignShopInvoicePaymentMethod of campaignShopInvoicePaymentRecord.campaignShopInvoicePaymentMethod) {
                payment_detail.push({
                    "id": campaignShopInvoicePaymentMethod.id,
                    "payment_method": campaignShopInvoicePaymentMethod.paymentMethod.name,
                    "amount_paid": campaignShopInvoicePaymentMethod.payment_total,
                    "payment_date": thaiDate(campaignShopInvoicePaymentMethod.payment_date),
                    "note": campaignShopInvoicePaymentMethod.remark
                })

                paymentCompletedDate = thaiDate(campaignShopInvoicePaymentMethod.payment_date);
            }

            payments.push({
                payment_detail,
            });
        }
        setData({
            campaignCode: invoice.campaign.code,
            invoiceCode: invoice.code,
            totalRecordedValue: invoice.sub_total,
            netTotalAmountDue: invoice.total_remain,
            totalAmountPaid: invoice.total_paid,
            paymentCompletedDate,
            payments: payments
        })

    }, []);

    return (
        <>
            <div className="bg-white rounded-[7px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden py-[20px] mb-7">
                <div className="px-[15px] py-[10px] mb-[60px] ">
                    <div className='flex justify-between px-[25px] mb-4'>
                        <div className='text-xl'>ใบบันทึกซื้อสินค้า/ค่าใช้จ่าย เลขที่ {data.invoiceCode}</div>
                        <div>
                            <div className='text-sm text-[#4C4E64]/[0.68]'>อ้างอิง</div>
                            <div> {data.campaignCode}</div>
                        </div>
                        <div c>
                            <div className='text-sm text-[#4C4E64]/[0.68]'>เลขที่ใบบันทึกซื้อสินค้า/ค่าใช้จ่าย</div>
                            <div> {data.invoiceCode}</div>
                        </div>
                    </div>
                    <hr />

                    <div className="grid grid-cols-4 gap-4 my-4 items-end px-[25px]">
                        <div className='col-span-2'>
                            <div className="grid grid-cols-4 gap-4 my-3 items-end">
                                <div className='col-span-2'>มูลค่าเต็มบันทึกสินค้า :</div>
                                <div className='col-span-2 text-right'>{data.totalRecordedValue} บาท</div>
                            </div>
                            <div className="grid grid-cols-4 gap-4 my-3 items-end">
                                <div className='col-span-2'>มูลค่าสุทธิที่ต้องชำระทั้งสิ้น :</div>
                                <div className='col-span-2 text-right'>{data.netTotalAmountDue} บาท</div>
                            </div>
                            <div className="grid grid-cols-4 gap-4 my-3 items-end">
                                <div className='col-span-2'>มูลค่าที่ชำระแล้ว {data.payments.length} รายการ :</div>
                                <div className='col-span-2 text-right'>{data.totalAmountPaid} บาท</div>
                            </div>
                        </div>
                        {
                            data.netTotalAmountDue <= 0 ? (
                                <div className='col-span-2 flex justify-end'>
                                    <div className='mb-3'>
                                        <div className='text-[#31A7DE] text-xl flex items-center font-bold'> <CheckCircleIcon className='text-2xl mr-3' /> ชำระเต็มจำนวน</div>
                                        <div className='ml-[34px]'>ชำระครบเมื่อวันที่ : {data.paymentCompletedDate}</div>
                                    </div>
                                </div>
                            ) : ''
                        }

                    </div>


                    <hr />



                </div>
            </div>
            <div className="bg-white rounded-[7px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden py-[15px] mb-7">
                <div className="px-[15px] py-[10px] ">
                    <div className='text-lg font-semibold'>การชำระเงิน</div>
                    <hr className='my-4' />
                    {data && data.payments && data.payments.map((payment, payment_index) => (
                        <div className="mb-3" key={`${payment_index}`}>
                            <div className="text-[#242F48] font-bold mb-3 flex items-center">
                                <CheckCircleIcon className="mr-2 text-[#31A7DE]" /> ชำระเงินครั้งที่ {payment_index + 1}
                            </div>
                            {
                                payment && payment.payment_detail && payment.payment_detail.map((detail, detail_index) => (
                                    <div key={`${detail_index}`}>

                                        <div className='flex text-[#4C4E64]/[0.68] mb-2'>
                                            <div className='w-[250px] mr-10'>{detail.payment_method}</div>
                                            <div>{detail.amount_paid} บาท</div>
                                        </div>
                                        <div className='text-sm text-[#4C4E64]/[0.68]'>วันที่ชำระเงิน {detail.payment_date}</div>
                                    </div>

                                ))}
                            <hr className='my-4' />
                        </div>
                    ))}

                    <div className='flex justify-end px-7 pt-5 mb-4'>
                        <div className='mr-5 pt-2'>ชำระเงินรวม : {data.totalAmountPaid} บาท</div>
                        <div className='rounded-lg bg-[#31A7DE] text-[#FFF] flex items-center p-4'>
                            <div className='mr-5'>มูลค่าสุทธิรวม</div>
                            <div><span className='text-3xl font-bold'>{data.totalRecordedValue}</span> บาท</div>
                        </div>
                    </div>
                    <div className='text-right px-7 pb-5'>ต้องชำระเงินอีก {data.netTotalAmountDue} บาท</div>
                </div>
            </div>
        </>
    )
}

