
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { Link } from 'react-router-dom';
export default function CardCampaign({item}) {

    return (

            <Card className='drop-shadow ' sx={{ boxShadow:'none',borderRadius:'10px'}}>
                <CardActionArea className='!flex  !p-[10px]' href={'givecoupon/'+item.id}>
                    <CardMedia
                    component="img"
                    height="106"
                    image={item.image}
                    alt=""
                    className='h-[106px] !w-[106px] rounded-lg'
                    />
                    <CardContent className='!grow text-left !py-0 '>
                    <div className='text-[#131A22] text-lg leading-5 mb-2'>{item.name}</div>
                    <div className='text-base text-[#75788D] leading-5'>{item.date}</div>
                    </CardContent>
                </CardActionArea>
            </Card>

      );
}

 