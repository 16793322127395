import * as dashboardAction from "../../slices/master/dashboard.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getDashboardSummary = (start_date, end_date, filter_type, campaign_id = null) => {
  return async (dispatch) => {
    dispatch(dashboardAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.DASHBOARD_URL}/summary`,
        headers: setHeaders(),
        params: {
          start_date,
          end_date,
          filter_type,
          campaign_id
        }
      };
      const result = await http(config);

      dispatch(dashboardAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(dashboardAction.fetchDataFailure());
    }
  };
};

export const getCampaignList = (start_date, end_date) => {
  return async (dispatch) => {
    dispatch(dashboardAction.fetchCampaignList());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.CAMPAIGN_URL}/list`,
        params: {
          start_date,
          end_date,
        }
      };
      const result = await http(config); 

      dispatch(dashboardAction.fetchCampaignListSuccess(result.data));
    } catch (error) {
      if( error.response.statusText === "Unauthorized" ){
        handleUnauthorized()
      }
      
      dispatch(dashboardAction.fetchDataFailure());
    }
  };
};