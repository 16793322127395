
import DownloadExcel from "components/utils/DownloadExcel";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';
import Chip from '@mui/material/Chip';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import FormatDate from "components/utils/FormatDate";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getInvoicePaginationCampaign, downloadExcelInvoiceCampaign } from "../../../../redux/handlers/master/campaignShopInvoice";

export function ReportCampaignList() {
    const dispatch = useAppDispatch();
    const campaignShopInvoiceReducer = useAppSelector((state) => state.campaignShopInvoiceReducer);
    const [campaignList, setCampaignList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const setCampaignData = (data) => {
        if (data && data.length > 0) {
            const campaignData = data.map((element) => ({
                id: element.id,
                campaignCode: element?.code,
                campaignName: element?.name,
                campaignDateStart: FormatDate(element?.start_date),
                campaignDateEnd: FormatDate(element?.end_date),
                campaignStatus: element?.campaign_status
            }));

            setCampaignList(campaignData);
        } else {
            setCampaignList([]);
        }
    };
    const download = () => {
        dispatch(downloadExcelInvoiceCampaign(filterText))
    }
    const columns = [
        {
            field: 'campaignCode',
            headerName: 'รหัสแคมเปญ',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            minWidth: 200,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <a href={`/campaign/manage/${row.id}`} target="_blank" className={` text-[#3FA5FF]`}>{row.campaignCode}</a>
                </div>
            ),
        },
        {
            field: 'campaignName',
            headerName: 'ชื่อร้านแคมเปญ',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.campaignName}</div>
                </div>
            ),
        },
        {
            field: 'campaignDateStart',
            headerName: 'วันที่เริ่ม',
            headerClassName: 'bg-[#F5F5F7]',
            width: 140,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.campaignDateStart}</div>
                    <div className=""></div>
                </div>
            ),
        },
        {
            field: 'campaignDateEnd',
            headerName: 'วันที่สิ้นสุด',
            headerClassName: 'bg-[#F5F5F7]',
            width: 140,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.campaignDateEnd}</div>
                    <div className=""></div>
                </div>
            ),
        },
        {
            field: 'campaignStatus',
            headerName: 'สถานะ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 220,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    {
                        row.campaignStatus == 1 ? (
                            <Chip label={"ดำเนินการอยู่"} className="w-[148px]" style={{
                                color: 'rgba(114, 225, 40, 0.87)',
                                background: '#EEFBE5',
                            }} />
                        ) : (
                            <Chip label={"สิ้นสุดแล้ว"} className="w-[148px]" style={{
                                color: '#FF0000',
                                background: '#ff00001f',
                            }} />
                        )
                    }
                </>
            ),
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <IconButton href={`/report/campaign/${row.id}/invoice`} className="w-[30px]  h-[30px] p-2" style={{
                        background: '#FFF3EA',
                        color: '#F4852A'
                    }} >
                        <RemoveRedEyeOutlinedIcon className="text-[20px]" />
                    </IconButton>
                </>
            ),
        },
    ];
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/campaign">
            รายงาน
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            ใบแจ้งหนี้
        </Typography>
    ];

    useEffect(() => {
        dispatch(getInvoicePaginationCampaign(page, pageSize, filterText));
    }, [page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (campaignShopInvoiceReducer.data != null && campaignShopInvoiceReducer.data.data) {
            const data = campaignShopInvoiceReducer.data.data.data;
            
            setCampaignData(data);
            setTotalRows(campaignShopInvoiceReducer?.data?.data?.meta?.total);
        }
    }, [campaignShopInvoiceReducer]);

    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">ใบแจ้งหนี้</div>
            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[10px]">
                <div className="px-[15px] pt-[15px]">
                    <div className="d-flex align-items-center px-4 mb-5 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหาชื่อร้านค้า/สถานที่"
                                onKeyUp={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={campaignList}
                        disableColumnMenu
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px',

                            },
                            '.MuiDataGrid-columnHeader': {
                                backgroundColor: '#F5F5F7'
                            },
                            '& .MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px',
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
}