const thaiDate = (dateStr, isShowTime = false) => {
  if (!dateStr) return '';

  // Parse the start and end dates
  const date = new Date(dateStr);

  // Check for invalid dates
  if (isNaN(date)) return '';

  // Extract day, month, year
  const day = date.getDate();
  const month = date.getMonth(); // months are 0-indexed (0-11)
  const year = date.getFullYear();

  // Convert to Thai year (Buddhist Era)
  const thaiYear = year + 543;

  // Map months to Thai abbreviations
  const thaiMonths = [
    'ม.ค.', // January
    'ก.พ.', // February
    'มี.ค.', // March
    'เม.ย.', // April
    'พ.ค.', // May
    'มิ.ย.', // June
    'ก.ค.', // July
    'ส.ค.', // August
    'ก.ย.', // September
    'ต.ค.', // October
    'พ.ย.', // November
    'ธ.ค.', // December
  ];
  const monthThai = thaiMonths[month];
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  let dateRangeString = "";
  if (isShowTime) {
    dateRangeString = `${day} ${monthThai} ${thaiYear} ${hours}:${minutes}`;
  } else {
    dateRangeString = `${day} ${monthThai} ${thaiYear}`;
  }

  return dateRangeString;
}

export default thaiDate;