const formatDatetime = (dateString) => {
  const date = new Date(dateString);

  // Get day, month, year, hours, and minutes
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
  const year = date.getUTCFullYear() + 543; // Buddhist calendar year = Gregorian year + 543
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Format the date as "dd/mm/yyyy hh:mm"
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export default formatDatetime;