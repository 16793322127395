import DownloadExcel from "components/utils/DownloadExcel";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';
import Chip from '@mui/material/Chip';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import FormatDate from "components/utils/FormatDate";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getCampaignReportPaginationRedemption, getCampaignReportPaginationRedemptionCheckin, downloadExcelCampaignReportRedemption, downloadExcelCampaignReportRedemptionCheckin } from "../../../../redux/handlers/master/campaignReport";
import { getCampaignById } from "../../../../redux/handlers/master/campaign";

export function ReportCampaignParticipants() {
    let { campaignID } = useParams();
    const dispatch = useAppDispatch();
    const campaignReducer = useAppSelector((state) => state.campaignReducer);
    const [campaign, setCampaign] = useState(null);

    useEffect(() => {
        dispatch(getCampaignById(campaignID));
    }, [campaignID]);

    useEffect(() => {
        if (campaignReducer.data) {
            const data = campaignReducer.data.data
            setCampaign(data)
        }
    }, [campaignReducer.data]);

    return (
        <>
            {
                (campaign?.campaign_type_id == 3) ? (
                    <ReportTypeCheckin />
                ) : (
                    <ReportTypeOther />
                )
            }
        </>
    )
}

const ReportTypeOther = () => {
    let { campaignID } = useParams();
    const dispatch = useAppDispatch();
    const campaignReportReducer = useAppSelector((state) => state.campaignReportReducer);
    const [redemptionList, setRedemptionList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const setRedemptionData = (data) => {
        if (data && data.length > 0) {
            const redemptionData = data.map((element) => ({
                id: element.id,
                code: element?.userTraveller?.code,
                name: `${element?.userTraveller?.first_name} ${element?.userTraveller?.last_name}`,
                phoneNumber: element?.userTraveller?.phone_number,
                province: element?.userTraveller?.province?.name_th,
                date: FormatDate(element?.created_at),
                shop: element?.shop?.name,
                userTraveller: element?.userTraveller
            }));

            setRedemptionList(redemptionData);
        } else {
            setRedemptionList([]);
        }
    };
    const download = () => {
        dispatch(downloadExcelCampaignReportRedemption(campaignID, filterText))
    }
    const columns = [
        {
            field: 'code',
            headerName: 'รหัสผู้เข้าร่วม',
            headerClassName: 'bg-[#F5F5F7]  pl-4',
            width: 150,
            minWidth: 200,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div className="py-3 pl-4">
                    {row.userTraveller?.is_active &&
                        <a href={`/tourist/manage/${row.userTraveller.id}/information`} target="_blank" className={` text-[#3FA5FF]`}>{row.code}</a>
                    }
                    {!row.userTraveller?.is_active &&
                        <a className={` text-[#3FA5FF]`}>{row.code}</a>
                    }
                </div>
            ),
        },
        {
            field: 'name',
            headerName: 'ชื่อผู้เข้าร่วม',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.name}</div>
                </div>
            ),
        },
        {
            field: 'shop',
            headerName: 'ร้านค้ามอบสิทธิ',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.shop}</div>
                </div>
            ),
        },
        {
            field: 'phoneNumber',
            headerName: 'เบอร์โทรศัพท์',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.phoneNumber}</div>
                </div>
            ),
        },
        {
            field: 'province',
            headerName: 'ภูมิลำเนา',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.province}</div>
                </div>
            ),
        },
        {
            field: 'date',
            headerName: 'วันที่เข้าร่วม',
            headerClassName: 'bg-[#F5F5F7]',
            width: 140,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.date}</div>
                    <div className=""></div>
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getCampaignReportPaginationRedemption(campaignID, page, pageSize, filterText));
    }, [campaignID, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        setRedemptionList([]);

        if (campaignReportReducer.data != null && campaignReportReducer.data.data) {
            const data = campaignReportReducer.data.data.data;

            setRedemptionData(data);
            setTotalRows(campaignReportReducer?.data?.data?.meta?.total);
        }
    }, [campaignReportReducer.data]);

    return (
        <div>
            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[10px]">
                <div className="px-[15px] pt-[10px]">
                    <div className="text-lg text-bold text-[#31A7DE] mb-4">ผู้เข้าร่วมแคมเปญ</div>
                    <div className="d-flex align-items-center mb-5 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหาชื่อร้านค้า/สถานที่"
                                onKeyUp={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={redemptionList}
                        disableColumnMenu
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        disableRowSelectionOnClick
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px',

                            },
                            '.MuiDataGrid-columnHeader': {
                                backgroundColor: '#F5F5F7'
                            },
                            '& .MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px',
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const ReportTypeCheckin = () => {
    let { campaignID } = useParams();
    const dispatch = useAppDispatch();
    const campaignReportReducer = useAppSelector((state) => state.campaignReportReducer);
    const [redemptionCheckInList, setRedemptionCheckInList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const setRedemptionCheckInData = (data) => {
        if (data && data.length > 0) {
            const redemptionCheckInData = data.map((element) => {
                let checkinPlace = []

                if (element?.UserTravellerCampaignCheckIn) {
                    for (const userTravellerCampaignCheckIn of element?.UserTravellerCampaignCheckIn) {
                        checkinPlace.push({
                            locationName: userTravellerCampaignCheckIn.campaignCheckIn.shop.name,
                            date: FormatDate(userTravellerCampaignCheckIn.created_at)
                        })
                    }
                }

                return {
                    id: element.id,
                    name: `${element?.first_name} ${element?.last_name}`,
                    phoneNumber: element?.phone_number,
                    date: (element?.UserTravellerCampaignRedemption) ? FormatDate(element?.UserTravellerCampaignRedemption[0].created_at) : '',
                    checkin_location: checkinPlace
                }
            });

            setRedemptionCheckInList(redemptionCheckInData);
        } else {
            setRedemptionCheckInList([]);
        }
    };
    const download = () => {
        dispatch(downloadExcelCampaignReportRedemptionCheckin(campaignID, filterText))
    }
    const columns = [
        {
            field: 'name',
            headerName: 'ชื่อผู้เข้าร่วม',
            headerClassName: 'bg-[#F5F5F7] pl-5',
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div className="py-1 pl-3">
                    <div className={` `}>{row.name}</div>
                </div>
            ),
        },
        {
            field: 'phoneNumber',
            headerName: 'เบอร์โทรศัพท์',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.phoneNumber}</div>
                </div>
            ),
        },
        {
            field: 'date',
            headerName: 'วันที่เข้าร่วม',
            headerClassName: 'bg-[#F5F5F7]',
            width: 140,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.date}</div>
                    <div className=""></div>
                </div>
            ),
        },
        {
            field: 'checkin_location',
            headerName: 'สถานที่เช็คอิน',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            minWidth: 1300,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div className="pr-7">
                    <div className="flex">
                        {
                            row.checkin_location && row.checkin_location.map((item, index) => (
                                <div className=" w-[150px] mr-4">
                                    <div className="text-[#242F48] truncate ...">{item.locationName}</div>
                                    <div className="text-[#242F48] text-[14px]">{item.date}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getCampaignReportPaginationRedemptionCheckin(campaignID, page, pageSize, filterText));
    }, [campaignID, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        setRedemptionCheckInList([]);

        if (campaignReportReducer.data != null && campaignReportReducer.data.data) {
            const data = campaignReportReducer.data.data.data;
            
            setRedemptionCheckInData(data);
            setTotalRows(campaignReportReducer?.data?.data?.meta?.total);
        }
    }, [campaignReportReducer.data]);

    return (
        <div className="">
            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]  pt-[10px]">
                <div className="px-[15px] pt-[10px]">
                    <div className="text-lg text-bold text-[#31A7DE] mb-4">ผู้เข้าร่วมแคมเปญ</div>
                    <div className="d-flex align-items-center mb-5 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหาชื่อผู้เข้าร่วม"
                                onKeyUp={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div >
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={redemptionCheckInList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        disableRowSelectionOnClick
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px',

                            },
                            '.MuiDataGrid-columnHeader': {
                                backgroundColor: '#F5F5F7'
                            },
                            '& .MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px',
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}