import Modal from '@mui/material/Modal';
import React, { useState, useEffect,useRef } from "react";
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
export const ModalConfirm = ({open,setOpen,message="",confirm}) => {

    const handleClose = () => setOpen(false);
    

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal} className="py-[30px]">
                <div className='text-center'>
                    <ErrorOutlineIcon className='text-[84px] text-[#FF9900]' />
                </div>
                <div className='text-center text-2xl my-7 font-semibold'>{message}</div>
                <div className='flex justify-center mb-3 pt-1'>
                    <Button 
                    variant="outlined"
                    className="btn px-7 text-base w-[90px]  h-[42px] text-[#6D788D]  rounded-[8px] mx-2 border-[1px] border-[#6D788D]"
                    onClick={handleClose}
                    >
                        ยกเลิก
                    </Button>
                    <Button 
                    variant="contained"
                    className="btn px-7 text-base w-[90px]  h-[42px] text-white  rounded-[8px] mx-2 bg-[#31A7DE]"
                    onClick={() => {
                        confirm()
                    }}
                    >
                        ตกลง
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 438,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 0,
    borderRadius:2,
    overflow:'hidden',
    outline:'none'
};