import * as userShopRoleAction from "../../slices/master/userShopRole.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";


import { endPoint } from "../../../constants/server/api";

export const getUserShopRole = () => {
  return async (dispatch) => {
    dispatch(userShopRoleAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.USER_SHOP_ROLE_URL}/list`,
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(userShopRoleAction.fetchDataSuccess(result.data));

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(userShopRoleAction.fetchDataFailure());
    }
  };
};