import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// import {prefix,date,months,years,gender,nationality,user,userinitial,permissions} from "mocks/user";
import Checkbox from '@mui/material/Checkbox';

import { ModalSuccess } from 'components/layouts/ModalSuccess';
import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getUserAdminById, createUserAdmin, updateUserAdmin } from "../../../redux/handlers/master/userAdmin";
import { getGender } from "../../../redux/handlers/master/gender";
import { getPrefix } from "../../../redux/handlers/master/prefix";

export function UserManage() {
    let { userID } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userAdminReducer = useAppSelector((state) => state.userAdminReducer);
    const [openModalSuccess, setOpenModalSuccess] = useState(false)
    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);
    const prefixReducer = useAppSelector((state) => state.prefixReducer);
    const [prefixList, setPrefixList] = useState([])
    const prefix = [];
    const [formdata, setFormdata] = useState(null)
    const initialFormValues = formdata || {
        id: null,
        image_path: null,
        username: null,
        password: null,
        prefix_name_id: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        is_status: null,
    };
    const schema = yup.object().shape({
        prefix_name_id: yup.string().required(),
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        username: yup.string().min(6).max(10).required(),
        password: (!userID) ? yup.string().required().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ) : null,
        phone_number: yup.string().required(),
        email: yup.string().email().required(),
        is_status: yup.boolean().required(),
    });
    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = new FormData();

            if (image) {
                formData.append('image_path', image);
            }

            if (values.prefix_name_id) {
                formData.append('prefix_name_id', values.prefix_name_id);
            }

            formData.append('email', values.email);
            formData.append('username', values.username);
            formData.append('password', values.password);
            formData.append('first_name', values.first_name);
            formData.append('last_name', values.last_name);
            formData.append('phone_number', values.phone_number);
            formData.append('is_status', values.is_status ? values.is_status : false);
            // formData.append('gender_id', 1);

            if (formdata && formdata.id) {
                dispatch(updateUserAdmin(formData, formdata.id))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenModalSuccess(true)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating user admin', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                dispatch(createUserAdmin(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenModalSuccess(true)
                            navigate(`/setting/users`)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating user admin', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        } catch (error) {
            console.error('Error submitting the form', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        dispatch(getPrefix());
    }, []);

    useEffect(() => {
        if (prefixReducer.data) {
            setPrefixList(prefixReducer.data.data);
        }
    }, [prefixReducer]);

    if (prefixList && prefixList.length > 0) {
        for (const element of prefixList) {
            prefix.push({
                label: element.name,
                value: element.id
            })
        }
    }

    useEffect(() => {
        if (userID) {
            dispatch(getUserAdminById(userID));
        }
    }, [userID, dispatch]);

    useEffect(() => {
        if (userAdminReducer.data) {
            const responseData = userAdminReducer.data.data;
            const data = {
                ...responseData,
                password: null
            };

            setFormdata(data);
        }
    }, [userAdminReducer.data]);

    return (
        <>
            <div className="text-[#4C4E64]/[0.87] text-2xl mb-4 font-medium">ตั้งค่าผู้ใช้งาน</div>
            <Formik
                initialValues={initialFormValues}
                validationSchema={schema}
                enableReinitialize
                onSubmit={handleFormSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit} className="max-w-[800px]">
                        <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px] ">
                            <div className="text-[#4C4E64]/[0.87]  font-medium text-xl mb-5">ข้อมูลการใช้งานระบบ</div>
                            <div className="text-[#4C4E64]/[0.68]  text-base mb-5">รูปโปรไฟล์</div>
                            <div className="flex items-center mb-9">
                                <Avatar
                                    alt=""
                                    src={image ? URL.createObjectURL(image) : `${values.image_path}`}
                                    sx={{ width: 101, height: 101 }}
                                />
                                <div className="ml-6">
                                    <Button
                                        variant="contained"
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileRef.current.value = ""
                                            setImage(null)
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">รองรับนามสกุล JPG, BMP,
                                        GIF, PNG ขนาดไม่เกิน 5 MB</div>
                                    <input
                                        ref={inputFileRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {
                                            const newImage = event.target?.files?.[0];

                                            if (newImage) {
                                                const maxSizeInMB = 5;
                                                if (newImage.size > maxSizeInMB * 1024 * 1024) {
                                                    alert(`แสดงว่าขนาดไฟล์ของคุณมีขนาดเกินกำหนด`);
                                                    return; // Exit the function to prevent further processing
                                                }
                                                setImage(newImage);  // เก็บเป็นไฟล์แทน URL
                                                setFieldValue('image_path', newImage); // เก็บไฟล์ไว้ใน form data
                                            }
                                        }}

                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 gap-5 mb-6">
                                <div className="lg:col-span-8 col-span-12">
                                    <div className="mb-2">ชื่อผู้ใช้งาน <span className="text-[#FF0000]">*</span></div>
                                    <TextField
                                        label=""
                                        name='username'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.username}
                                        error={!!errors.username}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                    <div className="text-[#929292] text-sm mt-2">ชื่อผู้ใช้งานควรมี 6-10 ตัวอักษร รองรับเฉพาะภาษาอังกฤษเท่านั้น</div>

                                </div>
                                <div className="lg:col-span-4 col-span-12"></div>
                                <div className="lg:col-span-8 col-span-12">
                                    <div className="mb-2">รหัสผ่าน <span className="text-[#FF0000]">*</span></div>
                                    <TextField
                                        type="password"
                                        label=""
                                        name='password'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        error={!!errors.password}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                    <div className="text-[#929292] text-sm mt-2">รหัสผ่านควรมี 8-16 ตัวอักษร ประกอบตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ ตัวเลข
                                        อักขระพิเศษ เช่น . - @ #  </div>
                                </div>
                            </div>
                            <FormControlLabel control={<Checkbox onChange={(e) => {
                                if (e.target.checked) {
                                    setFieldValue('password', 'P@ssw0rd')
                                } else {
                                    setFieldValue('password', '')
                                }
                            }} />} label="ใช้รหัสผ่านเริ่มต้น = P@ssw0rd" />
                        </div>
                        <div className="bg-white  rounded-[10px] drop-shadow pt-6 pb-3 mb-4 p-[25px] ">
                            <div className="text-[#4C4E64]/[0.87]  font-medium text-xl mb-5">ข้อมูลผู้ใช้งาน</div>
                            <div className="grid grid-cols-12 gap-7 mb-6">
                                <div className="lg:col-span-6 col-span-12">
                                    <div className="mb-2">คำนำหน้า</div>
                                    <TextField
                                        select
                                        label=""
                                        name='prefix_name_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.prefix_name_id}
                                        error={!!errors.prefix_name_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {prefix.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-6 col-span-12"></div>
                                <div className="lg:col-span-6 col-span-12">
                                    <div className="mb-2">ชื่อ</div>
                                    <TextField
                                        label=""
                                        name='first_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                        error={!!errors.first_name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <div className="mb-2">นามสกุล</div>
                                    <TextField
                                        label=""
                                        name='last_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                        error={!!errors.last_name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <div className="mb-2">เบอร์โทร</div>
                                    <TextField
                                        label=""
                                        name='phone_number'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone_number}
                                        error={!!errors.phone_number}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <div className="mb-2">อีเมล</div>
                                    <TextField
                                        label=""
                                        name='email'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        error={!!errors.email}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="bg-white  rounded-[10px] drop-shadow pt-6 pb-3 mb-4 p-[25px] ">
                                <div className="text-[#4C4E64]/[0.87]  font-medium text-xl mb-5">สิทธิ์การใช้งาน</div>
                                <div className="grid grid-cols-12 gap-7 mb-6">
                                    <div className="lg:col-span-6 col-span-12">
                                        <div className="mb-2">สิทธิ์การใช้งาน</div>
                                        <TextField 
                                            select
                                            label=""
                                            name='permissions'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.permissions}
                                            error={!!errors.permissions}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        >
                                            {permissions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div> */}
                        <div className="bg-white  rounded-[10px] drop-shadow pt-6 pb-1 mb-4 p-[25px] ">
                            <div className="mb-6">
                                <FormControl>
                                    <div>สถานะ</div>
                                    <RadioGroup
                                        row
                                        aria-labelledby=""
                                        name="is_status"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.is_status}
                                        value={values.is_status}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="เปิดใช้งาน" />
                                        <FormControlLabel value="false" control={<Radio />} label="ปิดใช้งาน" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="flex justify-end mb-7">
                            <Button
                                variant="outlined"
                                className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                href="/setting/users"
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                variant="contained"
                                className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                type="submit"
                            >
                                บันทึก
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
            <ModalSuccess setOpen={setOpenModalSuccess} open={openModalSuccess} />
        </>
    )
}


