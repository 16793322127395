import * as campaignReportAction from "../../slices/master/campaignReport.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getCampaignReportPaginationCampaign = (page, perPage, s = '') => {
  return async (dispatch) => {
    dispatch(campaignReportAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/pagination/campaign`,
        headers: setHeaders(),
        params: {
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(campaignReportAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignReportAction.fetchDataFailure());
    }
  };
};

export const getCampaignReportCampaign = (campaignId) => {
  return async (dispatch) => {
    dispatch(campaignReportAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/campaign/${campaignId}`,
        headers: setHeaders()
      };
      const result = await http(config);

      dispatch(campaignReportAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignReportAction.fetchDataFailure());
    }
  };
};

export const getCampaignReportPaginationRedemption = (campaign_id, page, perPage, s = '') => {
  return async (dispatch) => {
    dispatch(campaignReportAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/pagination/redemption`,
        headers: setHeaders(),
        params: {
          campaign_id,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(campaignReportAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignReportAction.fetchDataFailure());
    }
  };
};

export const getCampaignReportPaginationRedemptionCheckin = (campaign_id, page, perPage, s = '') => {
  return async (dispatch) => {
    dispatch(campaignReportAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/pagination/redemption-checkin`,
        headers: setHeaders(),
        params: {
          campaign_id,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(campaignReportAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignReportAction.fetchDataFailure());
    }
  };
};

export const getCampaignReportPaginationUsed = (campaign_id, page, perPage, s = '') => {
  return async (dispatch) => {
    dispatch(campaignReportAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/pagination/used`,
        headers: setHeaders(),
        params: {
          campaign_id,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(campaignReportAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignReportAction.fetchDataFailure());
    }
  };
};

export const getCampaignReportPaginationShop = (campaign_id, page, perPage, s = '') => {
  return async (dispatch) => {
    dispatch(campaignReportAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/pagination/shop`,
        headers: setHeaders(),
        params: {
          campaign_id,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(campaignReportAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignReportAction.fetchDataFailure());
    }
  };
};

export const downloadExcelCampaignReportCampaign = (s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/download/excel/campaign`,
        params: {
          s
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'campaign-report.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const downloadExcelCampaignReportRedemption = (campaign_id, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/download/excel/redemption`,
        params: {
          campaign_id,
          s,
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'campaign-report-redemption.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const downloadExcelCampaignReportRedemptionCheckin = (campaign_id, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/download/excel/redemption-checkin`,
        params: {
          campaign_id,
          s,
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'campaign-report-redemption-checkin.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const downloadExcelCampaignReportUsed = (campaign_id, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/download/excel/used`,
        params: {
          campaign_id,
          s,
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'campaign-report-used.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const downloadExcelCampaignReportShop = (campaign_id, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_REPORT_URL}/download/excel/shop`,
        params: {
          campaign_id,
          s,
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'campaign-report-shop.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};