import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DataTable from "react-data-table-component";
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import StarIcon from '@mui/icons-material/Star';
import Checkbox from '@mui/material/Checkbox';

import CheckboxTable from 'components/fragments/CheckboxTable'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as yup from "yup";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
    toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import DownloadExcel from "components/utils/DownloadExcel";

import FormatDate from "components/utils/FormatDate";
import formatDatetime from "utils/formatDatetime";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getSurveyReportPagination, getSurveyReportTravellerById, getSurveyReportShopById, downloadExcelSurveyReport } from "../../../../redux/handlers/master/surveyReport";

export function ReportSurveyList() {
    const dispatch = useAppDispatch();
    const surveyReportReducer = useAppSelector((state) => state.surveyReportReducer);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [surveyReportList, setSurveyReportList] = useState([]);
    const columns = [
        {
            name: "ชื่อแคมเปญ",
            selector: (row) => row.campaignName,
            minWidth: "210px",
            cell: (row, index, column, id) => {
                return <div>{row.campaignName}</div>;
            },
        },
        {
            name: "รหัสแบบสำรวจ",
            selector: (row) => row.surveyCode,
            width: "150px",
            cell: (row, index, column, id) => {
                return <a href={`/setting/survey/manage/${row.survey_id}`} target="_blank" className="text-[#31A7DE] font-semibold">{row.surveyCode}</a>;
            },
        },
        {
            name: "ชื่อแบบสำรวจ",
            selector: (row) => row.surveyName,
            minWidth: "210px",
            cell: (row, index, column, id) => {
                return <div>{row.surveyName}</div>;
            },
        },
        {
            name: "ประเภท",
            selector: (row) => row.surveyType,
            width: "110px",
            cell: (row, index, column, id) => {
                return <div>{row.surveyType}</div>;
            },
        },
        {
            name: "จำนวนผู้ทำ แบบสำรวจ",
            selector: (row) => row.surveyUsers,
            width: "180px",
            cell: (row, index, column, id) => {
                return <div>{row.surveyUsers}</div>;
            },
        },
        {
            name: "วันที่ใช้งาน",
            selector: (row) => row.dateStart,
            width: "110px",
            cell: (row, index, column, id) => {
                return <div>{row.dateStart}</div>;
            },
        },
        {
            name: "วันที่สิ้นสุด",
            selector: (row) => row.dateEnd,
            width: "110px",
            cell: (row, index, column, id) => {
                return <div>{row.dateEnd}</div>;
            },
        },
    ];
    const columnsSurvey = [
        {
            name: "วันที่ทำรายการ",
            selector: (row) => row.date,
            width: "160px",
            cell: (row, index, column, id) => {
                return (
                    <div>{row.date}</div>
                );
            },
        },
        {
            name: "ชื่อผู้ทำรายการ",
            selector: (row) => row.name,
            minWidth: "110px",
            cell: (row, index, column, id) => {
                return (
                    <div>{row.name}</div>
                );
            },
        },
        {
            name: "จังหวัด",
            selector: (row) => row.province,
            minWidth: "110px",
            cell: (row, index, column, id) => {
                return (
                    <div>{row.province}</div>
                );
            },
        },
        {
            name: "เบอร์โทรศัพท์",
            selector: (row) => row.phoneNumber,
            minWidth: "110px",
            cell: (row, index, column, id) => {
                return (
                    <div>{row.phoneNumber}</div>
                );
            },
        },
        {
            name: "ประเภทผู้ใช้งาน",
            selector: (row) => row.type,
            minWidth: "110px",
            cell: (row, index, column, id) => {
                return (
                    <div>{row.type}</div>
                );
            },
        },
        {
            name: "ผลสำรวจ",
            selector: (row) => row.result,
            minWidth: "110px",
            cell: (row, index, column, id) => {
                return (
                    <div className="flex items-center"><span className="text-[#31A7DE] text-xl font-bold mr-2">{row.result}</span><StarIcon className="text-[#FFA825]" /></div>
                );
            },
        },
        // {
        //     name: "ความคิดเห็นเพิ่มเติม",
        //     selector: (row) => row.comments,
        //     minWidth: "110px",
        //     cell: (row, index, column, id) => {
        //         return (
        //             <div>{row.comments}</div>
        //         );
        //     },
        // },
        {
            name: "จัดการ",
            selector: (row) => row.name,
            width: "110px",
            cell: (row, index, column, id) => {
                return (
                    <>
                        <IconButton className="w-[30px]  h-[30px] p-2" style={{
                            background: '#FFF3EA',
                            color: '#F4852A'
                        }}
                            onClick={() => {
                                setSurveyID(row.id)
                                setSurveyStatus(row.surveyStatus)
                                setOpen(true)
                            }}
                        >
                            <RemoveRedEyeOutlinedIcon className="text-[20px]" />
                        </IconButton>
                    </>
                );
            },
        },

    ];
    const [open, setOpen] = useState(false);
    const [surveyID, setSurveyID] = useState(null);
    const [surveyStatus, setSurveyStatus] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const [disableDownload, setDisableDownload] = useState(true);
    const handlePageChange = (page) => {
        // console.log('handlePageChange',page)
        setPage(page);
    };
    const handleRowsPerPageChange = (newPerPage, page) => {
        // console.log('handleRowsPerPageChange', page)
        setPageSize(newPerPage);
        setPage(page);
      };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const setSurveyReportData = (data) => {
        if (data && data.length > 0) {
            const surveyReportData = data.map((element) => ({
                ...element,
                campaignName: element?.report_info.name,
                surveyCode: element?.report_info.survey_code,
                surveyName: element?.report_info.survey_name,
                surveyType: element?.report_info.survey_status,
                surveyUsers: element?.report_info.total_user,
                dateStart: FormatDate(element?.report_info.start_date),
                dateEnd: FormatDate(element?.report_info.end_date),
                userSurvey: element?.survey.user_survey
            }));

            setSurveyReportList(surveyReportData);
        } else {
            setSurveyReportList([]);
        }
    };
    const download = () => {
        const expandedIds = expandedRows.join(',')
        dispatch(downloadExcelSurveyReport(filterText, expandedIds))
    }
    const ExpandedComponent = ({ data }) => {
        let userSurvey = []

        if (data.userSurvey.length) {
            userSurvey = data.userSurvey.map((element) => ({
                id: element?.id,
                date: FormatDate(element?.created_at),
                name: `${element?.user_info.first_name} ${element?.user_info.last_name}`,
                province: element?.user_info.province_name,
                phoneNumber: element?.user_info.phone_number,
                type: data?.report_info.survey_status,
                result: element?.rating,
                comments: null,
                surveyStatus: data?.survey_status
            }))
        }

        return (
            <div className="hideexpandable">
                <DataTable
                    columns={columnsSurvey}
                    data={userSurvey}
                    // noTableHead={true}
                    customStyles={customStylesExpander}
                />
            </div>
        );
    };
    const handleRowExpandToggled = (isExpanded, row) => {
        // console.log(`Row ${isExpanded ? 'expanded' : 'collapsed'}`, row);
        let expandedData = expandedRows

        if (isExpanded) {
            expandedData.push(row.id)
        } else {
            expandedData = expandedData.filter(value => value !== row.id);
        }

        setExpandedRows(expandedData);

        // set disable/enable download btn
        if (expandedData.length) {
            setDisableDownload(false)
        } else {
            setDisableDownload(true)
        }
    };

    useEffect(() => {
        dispatch(getSurveyReportPagination(page, pageSize, filterText));
    }, [page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (surveyReportReducer.data != null && surveyReportReducer.data.data) {
            const data = surveyReportReducer.data.data.data;
            setSurveyReportData(data);
            setTotalRows(surveyReportReducer?.data?.data?.meta?.total);
        }
    }, [surveyReportReducer.data]);

    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-xl font-semibold my-5">รายงานแบบสำรวจ</div>
            <div className="bg-white rounded-[7px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[5px] mb-7">
                <div className=" ">
                    <div className="text-[#4C4E64]/[.87] text-xl px-4 font-semibold my-4">รายงานแบบสำรวจ</div>
                    <div className="d-flex align-items-center px-4 mb-5 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(disableDownload)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหา"
                                onKeyUp={handleSearch}
                            />
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={surveyReportList}
                        customStyles={customStyles}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationDefaultPage={page}
                        paginationPerPage={pageSize}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        paginationComponentOptions={paginationComponentOptions}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        onRowExpandToggled={handleRowExpandToggled}
                    />
                    <ModalSurvey open={open} setOpen={setOpen} surveyID={surveyID} setSurveyID={setSurveyID} surveyStatus={surveyStatus} setSurveyStatus={setSurveyStatus} />
                </div>
            </div>
        </>
    )
}

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="#">
        รายงาน
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
        รายงานแบบสำรวจ
    </Typography>
];

const ModalSurvey = ({ open, setOpen, surveyID, setSurveyID, surveyStatus, setSurveyStatus }) => {
    const dispatch = useAppDispatch();
    const surveyReportReducer = useAppSelector((state) => state.surveyReportReducer);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setSurveyID(null)
        setSurveyStatus(null)
        setSurvey(null)
        setOpen(false);
    }
    const [survey, setSurvey] = useState(null);
    const schema = yup.object().shape({})

    useEffect(() => {
        if (surveyID) {
            if (surveyStatus == 'TRAVELLER') {
                dispatch(getSurveyReportTravellerById(surveyID));
            } else {
                dispatch(getSurveyReportShopById(surveyID));
            }
        }
    }, [surveyID, surveyStatus]);

    useEffect(() => {
        if (surveyReportReducer.dataInfo) {
            const data = surveyReportReducer.dataInfo.data
            setSurvey(data)
        }
    }, [surveyReportReducer.dataInfo]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="text-[#131A22] text-xl font-bold mb-3" >แบบสำรวจความคิดเห็น</div>
                <div className="text-[#131A22] mb-1">แคมเปญ : {survey?.report_info.name}</div>
                {/* <div className="text-[#131A22] mb-1">ประเภท : การใช้สิทธิ์</div> */}
                <div className="text-[#31A7DE] mb-5">ทำเมื่อ {formatDatetime(survey?.created_at)}</div>
                <div
                    className="bg-[#E9EEF5] text-center rounded-lg p-2  text-xl font-bold mt-3 mb-5 text-[#31A7DE]"
                >ผลสำรวจ</div>
                <Formik
                    enableReinitialize
                    initialValues={{}}
                    validationSchema={schema}
                    onSubmit={(values) => { }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        setValues,
                        isInvalid,
                        isValid,
                        isSubmitting
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-7">
                                <FieldArray
                                    name="questions"
                                    render={arrayHelpers => (
                                        <div>
                                            {survey?.survey_list && survey?.survey_list.map((item, index) => (
                                                <div className="mb-3" key={index}>
                                                    {item.answer.survey_list_type_code == 'SINGLE_CHOICE' &&
                                                        <div>
                                                            <div className="text-left mb-2 font-semibold">{item.answer.question}</div>
                                                            <div className="max-w-[340px]">
                                                                <StyledToggleButtonGroup
                                                                    color="primary"
                                                                    value={Number(item.answer.answer)}
                                                                    name={`question[${index}].answer`}
                                                                    aria-label=""
                                                                    sx={{
                                                                        display: 'flex',
                                                                        width: '100%',
                                                                        justifyContent: 'space-between'
                                                                    }}
                                                                >
                                                                    <ToggleButton value={1}>1</ToggleButton>
                                                                    <ToggleButton value={2}>2</ToggleButton>
                                                                    <ToggleButton value={3}>3</ToggleButton>
                                                                    <ToggleButton value={4}>4</ToggleButton>
                                                                    <ToggleButton value={5}>5</ToggleButton>
                                                                </StyledToggleButtonGroup>
                                                                <div className="flex justify-between text-[10px] mt-1">
                                                                    <div>ไม่พอใจมาก</div>
                                                                    <div>พอใจอย่างมาก</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.answer.survey_list_type_code == 'QUESTION' &&
                                                        <div>
                                                            <div className="font-semibold mt-5 mb-3">ความคิดเห็นเพิ่มเติม</div>
                                                            <div className="mb-4">
                                                                <TextField
                                                                    id=""
                                                                    label=""
                                                                    multiline
                                                                    rows={4}
                                                                    value={item.answer.answer}
                                                                    className="w-full"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
                <Button
                    variant="contained"
                    className="w-full bg-[#31A7DE] text-white rounded-[8px] text-base  h-[48px] mb-5 mr-3"
                    onClick={handleClose}
                >
                    ปิดหน้าต่าง
                </Button>
            </Box>
        </Modal>
    )
}

const customStyles = {
    rows: {
        style: {
            minHeight: "52px",
        },
    },
    headCells: {
        style: {
            paddingLeft: "15px",
            paddingRight: "15px",
            color: "rgba(76, 78, 100, 0.87)",
            fontFamily: "Sarabun",
            fontSize: "15px",
            paddingTop: "5px",
            paddingBottom: "5px",
            fontWeight: "500",
        },
    },
    cells: {
        style: {
            paddingLeft: "15px",
            paddingRight: "15px",
            color: "rgba(76, 78, 100, 0.87)",
            fontFamily: "Sarabun",

            fontSize: "15px",
            fontWeight: "300",
        },
    },
    expanderRow: {
        style: {
            minHeight: "52px",
        },
    },
    pagination: {
        style: {
            borderBottomRightRadius: "0.75rem",
            borderBottomLeftRadius: "0.75rem",
        },
    },
};

const customStylesExpander = {
    rows: {
        style: {
            minHeight: "52px",
        },
    },
    headCells: {
        style: {
            background: " #FFF5E5",
            color: "#FF9900",
            fontSize: "15px",
        },
    },
    cells: {
        style: {
            paddingLeft: "15px",
            paddingRight: "15px",
            color: "rgba(76, 78, 100, 0.87)",
            fontFamily: "Sarabun",
            background: " #FFFFFF",
            fontSize: "15px",
            fontWeight: "300",
        },
    },
};

const paginationComponentOptions = {
    rowsPerPageText: "จำนวนรายการต่อหน้า",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '95%',
    overflow: 'auto',
    bgcolor: 'background.paper',
    border: '0',
    boxShadow: 24,
    borderRadius: '15px',
    p: 2,
    outline: 'none'
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-root': {
        display: 'flex'
    },
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: '1px solid #E8EAED',
        background: '#FFF',
        width: '36px',
        height: '36px',
        borderRadius: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
        [`&.${toggleButtonGroupClasses.selected}`]: {
            background: '#31A7DE',
            color: '#FFF'
        }

    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {

        borderLeft: '1px solid #E8EAED',
    },
}));