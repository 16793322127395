import * as couponShopAction from "../../slices/master/couponShop.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { saveAs } from 'file-saver';
import axios from 'axios';
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const createCouponShop = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.COUPON_SHOP_URL}`,
        data: data,
        headers: setHeaders(),
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const deleteCouponShop = (id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "delete",
        url: `${endPoint.COUPON_SHOP_URL}/${id}`,
        headers: setHeaders(),
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const downloadExcelCouponShop = (coupon_id, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.COUPON_SHOP_URL}/download/excel`,
        params: {
          coupon_id,
          s
        },
        headers: setHeaders(),
        responseType: 'blob' // Important to handle binary data
      };
      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');

      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'couponShop.xlsx'; // Default file name
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const getCouponShopPagination = (coupon_id, page = 1, perPage = 12, s = "") => {
  return async (dispatch) => {
    dispatch(couponShopAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.COUPON_SHOP_URL}`,
        headers: setHeaders(),
        params: {
          coupon_id,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(couponShopAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(couponShopAction.fetchDataFailure());
    }
  };
};

export const getShopForCouponPagination = (coupon_id, page = 1, perPage = 12, s = "") => {
  return async (dispatch) => {
    dispatch(couponShopAction.fetchShopData());
    try {
      const config = {
        method: "get",
        url: `${endPoint.SHOP_URL}/coupon`,
        headers: setHeaders(),
        params: {
          coupon_id,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(couponShopAction.fetchShopDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(couponShopAction.fetchShopDataFailure());
    }
  };
};