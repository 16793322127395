
  export default function formatDateToBE(date ){

    date = new Date(date)
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const yearBE = date.getFullYear() + 543; // Convert to Buddhist Era
  
    // Add leading zeros to day and month if necessary
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
  
    return `${formattedDay}/${formattedMonth}/${yearBE}`;
}