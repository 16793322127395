import * as shopAction from "../../slices/master/shop.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";


import { endPoint } from "../../../constants/server/api";


export const getShopPagination = (approve_status = "all", page = 1, perPage = 12, s = "", province_id = "", shop_category_id = "") => {
  return async (dispatch) => {
    dispatch(shopAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.SHOP_URL}`,
        params: {
          approve_status,
          page,
          perPage,
          s,
          province_id,
          shop_category_id
        }
      };

      const result = await http(config);
      dispatch(shopAction.fetchDataSuccess(result.data));

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(shopAction.fetchDataFailure());
    }
  };
};

export const createShop = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.SHOP_URL}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };

      const result = await http(config);

      return result.data;

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };

};

export const updateShop = (id, data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.SHOP_URL}/${id}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};
export const updateShopStatus = (id, data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.SHOP_URL}/update-status/${id}`,
        data: data,
        headers: setHeaders()
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};



export const copyShop = (shopIds) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.CAMPAIGN_URL}/copy`,
        data: { ids: shopIds },
        headers: setHeaders()
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error;
    }
  };
};

export const deleteShop = (shopIds) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.CAMPAIGN_URL}/delete`,
        data: { ids: shopIds },
        headers: setHeaders()
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };
};


export const getShopById = (id) => {
  return async (dispatch) => {
    dispatch(shopAction.fetchData());
    try {
      const config = {
        method: "get",
        url: `${endPoint.SHOP_URL}/${id}`,
        headers: setHeaders()
      };

      const result = await http(config);
      dispatch(shopAction.fetchDataSuccess(result.data));

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };

};

export const downloadExcelShop = (s = "", province_id = "", shop_category_id = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.SHOP_URL}/download/excel`,
        params: {
          s,
          province_id,
          shop_category_id
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'shop.xlsx'; // Default file name
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

    }
  };
};

