

import DownloadExcel from "components/utils/DownloadExcel";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ic_edit from "assets/svg/ic_edit.svg";
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { ModalSuccess } from "components/layouts/ModalSuccess";
import { ModalConfirm } from "components/layouts/ModalConfirm";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getBanners, createBanner, updateBanner, deleteBanner, sortBanner } from "../../../redux/handlers/master/banner";
import { getActiveBannerSingle, createBannerSingle, updateBannerSingle, deleteBannerSingle } from "../../../redux/handlers/master/bannerSingle";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
// import DataTable from 'react-data-table-component';
import DataTable from 'datatables.net-react';
import 'datatables.net-rowreorder-dt';
import 'datatables.net-responsive-bs5';
import DT from 'datatables.net-dt';



DataTable.use(DT);



export function Banner() {
    const dispatch = useAppDispatch();
    const bannerReducer = useAppSelector((state) => state.bannerReducer);
    const bannerSingleReducer = useAppSelector((state) => state.bannerSingleReducer);
    const [bannerList, setBannerList] = useState([]);
    const inputFileRef = useRef(null);
    const [imageSingle, setImageSingle] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDeleteSuccess, setOpenDeleteSuccess] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openConfirmSingle, setOpenConfirmSingle] = React.useState(false);
    const [openSaveSuccess, setOpenSaveSuccess] = React.useState(false);
    const [bannerDeleteId, setBannerDeleteId] = useState(null);
    const [bannerFormData, setBannerFormData] = useState(null);
    const [bannerSingleFormData, setBannerSingleFormData] = useState({});
    const bannerSingleFormDataInit = {
        image_path: null,
    }

    const [initialFormValues, setInitialFormValues] = useState({
        id: null,
        image_path: null,
        link: null,
    })

    const schema = yup.object().shape({
        image_path: yup.string().required(),
        link: yup.string().required(),
    });
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const setBannerData = (data) => {
        if (data && data.length > 0) {
            setBannerList(data);
        } else {
            setBannerList([]);
        }
    };
    const setBannerSingleData = (data) => {
        if (data) {
            setBannerSingleFormData(data);
        } else {
            setBannerSingleFormData(bannerSingleFormDataInit);
        }
    };
    const deleteBannerData = () => {
        dispatch(deleteBanner(bannerDeleteId))
            .then((response) => {
                if (response.message === "success") {
                    setBannerDeleteId(null)
                    dispatch(getBanners());
                    setOpenConfirm(false)
                    setOpenDeleteSuccess(true)
                }
            })
            .catch((error) => {
                console.error('Error delete banner', error);
            })
    }
    const deleteBannerSingleData = () => {
        dispatch(deleteBannerSingle(bannerSingleFormData.id))
            .then((response) => {
                if (response.message === "success") {
                    dispatch(getActiveBannerSingle());
                    inputFileRef.current.value = ""
                    setImageSingle(null)
                    setOpenConfirmSingle(false)
                    setOpenDeleteSuccess(true)
                }
            })
            .catch((error) => {
                console.error('Error delete banner single', error);
            })
    }


    const columns = [
        {
            field: 'bannerImage',
            headerName: 'รูป',
            headerClassName: 'bg-[#F5F5F7] border-0',
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <img src={row.image_path} className="rounded-lg  w-[132px] h-[60]" />
                </div>
            ),
            renderHeader: () => (
                <div className="text-[#4C4E64]/[0.87]  font-bold">
                    {'ชื่อแคมเปญ '}
                </div>
            ),
        },
        {
            field: 'bannerName',
            headerName: 'ชื่อแบนเนอร์',
            headerClassName: 'bg-[#F5F5F7] border-0 ',
            cellClassName: 'self-center',
            width: 140,
            flex: 1,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#4C4E64]/[0.87] text-lg mb-1">{row.name}</div>
                </div>
            ),
            renderHeader: () => (
                <div className="text-[#4C4E64]/[0.87] font-bold">
                    {'ชื่อแบนเนอร์ '}
                </div>
            ),
        },
        {
            field: 'link',
            headerName: 'URL ส่งออก',
            headerClassName: 'bg-[#F5F5F7] border-0',
            cellClassName: 'self-center',
            width: 240,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">
                        <a href={row.link} target="_blank" rel="noopener noreferrer">{row.link}</a>

                    </div>
                    <div className=""></div>
                </div>
            ),
            renderHeader: () => (
                <div className="text-[#4C4E64]/[0.87]  font-bold">
                    {'URL ส่งออก '}
                </div>
            ),
        },
        {
            field: 'campaignDateEnd',
            headerName: 'เรียงลำดับ',
            headerClassName: 'bg-[#F5F5F7] border-0',
            cellClassName: 'self-center',
            width: 240,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.sort_order}</div>
                    <div className=""></div>
                </div>
            ),
            renderHeader: () => (
                <div className="text-[#4C4E64]/[0.87]  font-bold">
                    {'เรียงลำดับ '}
                </div>
            ),
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName: 'bg-[#F5F5F7] border-0',
            cellClassName: 'self-center',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <IconButton className="w-[30px] mr-3 h-[30px] p-1" style={{
                        background: '#E3E8F0',
                    }}
                        onClick={() => {
                            setBannerFormData(row)
                            setOpenForm(true)
                        }}
                    >
                        <img src={ic_edit} alt="" />
                    </IconButton>
                    <IconButton className="w-[30px] mr-3 h-[30px] p-1" style={{
                        background: '#F95057',
                    }}
                        onClick={() => {
                            setBannerDeleteId(row.id)
                            setOpenConfirm(true)
                        }}
                    >
                        <img src={ic_trash} alt="" />
                    </IconButton>
                </>

            ),
        },
    ];
    const handleSubmitBannerSingle = (values) => {
        try {
            let formData = new FormData()

            formData.append('image_path', values.image_path)
            formData.append('link', values.link)

            if (bannerSingleFormData && bannerSingleFormData.id) {
                dispatch(updateBannerSingle(formData, bannerSingleFormData.id))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenSaveSuccess(true)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating banner single', error);
                    })
            } else {
                dispatch(createBannerSingle(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenSaveSuccess(true)
                            dispatch(getActiveBannerSingle());
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating banner single', error);
                    })
            }
        } catch (error) {
            console.error('Error submitting the form', error);
        }
    }

    useEffect(() => {
        dispatch(getBanners());
        dispatch(getActiveBannerSingle());
    }, [dispatch]);

    useEffect(() => {
        if (bannerReducer.data != null && bannerReducer.data.data) {
            const data = bannerReducer.data.data;
            setBannerData(data);
            setTableData(data)
        }
    }, [bannerReducer]);

    useEffect(() => {
        if (bannerSingleReducer.data != null && bannerSingleReducer.data.data) {
            const data = bannerSingleReducer.data.data;
            setInitialFormValues(data);
            setBannerSingleData(data);

        } else {
            setBannerSingleData(null)
        }
    }, [bannerSingleReducer]);

    const [tableData, setTableData] = useState([
    ]);
    const tableDataRef = useRef(tableData);

    useEffect(() => {
        tableDataRef.current = tableData;
    }, [tableData]);

    const columns3 = [
        { data: 'image_path', orderable: false, width: '200px', className: '!bg-[#FFF] !shadow-none' },
        { data: 'name', orderable: false, className: '!bg-[#FFF] !shadow-none' },
        { data: 'link', orderable: false, className: '!bg-[#FFF] !shadow-none' },
        { data: 'sort_order', orderable: false, className: 'text-center !bg-[#FFF] !shadow-none', width: '200px' },
        { data: 'id', orderable: false, className: 'text-center !bg-[#FFF] !shadow-none', width: '150px' },
    ];

    const moveUp = (id) => {
        // let index = tableData.findIndex(e => e.id == id);
        // console.log(index)
        // if (index > 0) {
        //   let el = tableData[index];
        //   tableData[index] = tableData[index - 1];
        //   tableData[index - 1] = el;
        // }
        // setTableData(tableData)
    }

    const moveDown = (id) => {
        // let index = tableData.findIndex(e => e.id == id);
        // console.log(index)
        // if (index !== -1 && index < tableData.length - 1) {
        //   let el = tableData[index];
        //   tableData[index] = tableData[index + 1];
        //   tableData[index + 1] = el;
        // }
    }
    // console.log(tableData)
    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">ตั้งค่าแบนเนอร์</div>
            <div className="bg-white mb-5 rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden py-[15px] px-[25px]" >
                <div className=" pt-[15px]">
                    <div className="d-flex align-items-center  mb-6 flex-wrap">
                        <div className="pr-5 mr-auto" >
                            <div className="font-semibold text-xl text-[#040C22]">แบนเนอร์สไลด์ส่วนบน</div>
                        </div>
                        <div className="">

                            <Button
                                className="btn px-5  h-[38px] text-white flex items-center rounded-[12px] bg-[#31A7DE]"
                                onClick={() => {
                                    setBannerFormData()
                                    setOpenForm(true)
                                }}
                            >
                                เพิ่มแบนเนอร์
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] mb-[20px]  rounded-[5px] overflow-hidden ">
                    <div className=" ">
                        {/* <DataTable
                            columns={columns2}
                            data={bannerList}
                            customStyles={customStyles}
                        />
                         */}
                        <DataTable
                            data={tableData}
                            columns={columns3}

                            className="display"
                            options={{
                                responsive: true,
                                rowReorder: true,
                                ordering: true,
                                rowReorder: {
                                    selector: '.reorder',
                                    update: false
                                },
                                bStateSave: true,
                                searching: false,
                                info: false,
                                lengthChange: false,
                                scrollY: '400px',
                                scrollCollapse: true,
                                paging: false
                            }}
                            onRowReorder={(e, newPositionData) => {

                                console.log(newPositionData);
                                let originalData = tableDataRef.current;

                                // Initialize reorderedData with nulls for the length of originalData
                                const reorderedData = new Array(originalData.length).fill(null);

                                // Place items in their new positions from newPositionData
                                newPositionData.forEach(({ node, newPosition }) => {
                                    const originalIndex = node._DT_RowIndex;
                                    reorderedData[newPosition] = originalData[originalIndex];
                                });

                                // Fill in remaining positions with items from originalData that were not moved
                                originalData.forEach((item, index) => {
                                    if (!reorderedData.includes(item)) {
                                        const emptyIndex = reorderedData.indexOf(null);
                                        reorderedData[emptyIndex] = item;
                                    }
                                });

                                const result = reorderedData.filter(item => item.id != null).map(item => ({ id: item.id }));
                                console.log(result);

                                dispatch(sortBanner(result));
                            }}

                            slots={
                                {
                                    0: (data, row) => (
                                        <img src={row.image_path} className="rounded-lg  w-[132px] h-[60]" />
                                    ),
                                    3: (data, row) => (
                                        <>
                                            {/* <IconButton className="w-[30px] mr-2 h-[30px] p-1" style={{
                                            background: '#FFF',
                                        }}
                                            onClick={() => {
                                            }}
                                        >
                                            <ArrowUpwardIcon className="text-[16px]" />
                                        </IconButton>
                                        <IconButton className="w-[30px] mr-2 h-[30px] p-1" style={{
                                            background: '#FFF',
                                            border:'1px solid #596882'
                                        }}
                                            onClick={() => {
                                                moveUp(row.id)
                                            }}
                                        >
                                            <ArrowDownwardIcon className="text-[16px]" />
                                        </IconButton> */}
                                            <IconButton className="w-[30px] reorder mr-2 h-[30px] p-1" style={{
                                                background: '#E3E8F0',
                                                border: '1px solid #596882'
                                            }}
                                                onClick={() => {
                                                    moveDown(row.id)
                                                }}
                                            >
                                                <ControlCameraIcon className="text-[16px]" />
                                            </IconButton>
                                        </>
                                    ),
                                    4: (data, row) => (
                                        <>
                                            <IconButton className="w-[30px] mr-3 h-[30px] p-1" style={{
                                                background: '#E3E8F0',
                                            }}
                                                onClick={() => {
                                                    setBannerFormData(row)
                                                    setOpenForm(true)
                                                }}
                                            >
                                                <img src={ic_edit} alt="" />
                                            </IconButton>
                                            <IconButton className="w-[30px] mr-3 h-[30px] p-1" style={{
                                                background: '#F95057',
                                            }}
                                                onClick={() => {
                                                    setBannerDeleteId(row.id)
                                                    setOpenConfirm(true)
                                                }}
                                            >
                                                <img src={ic_trash} alt="" />
                                            </IconButton>
                                        </>
                                    ),
                                }}
                        >
                            <thead className="bg-[#4C4E64]/[0.02]">
                                <tr className="bg-[#4C4E64]/[0.02]">
                                    <th className="py-4 bg-[#4C4E64]/[0.02]">รูป</th>
                                    <th className="bg-[#4C4E64]/[0.02]">ชื่อแบนเนอร์</th>
                                    <th className="bg-[#4C4E64]/[0.02]">URL</th>
                                    <th className="text-center bg-[#4C4E64]/[0.02]">เรียงลำดับ</th>
                                    <th className="text-center bg-[#4C4E64]/[0.02]">จัดการ</th>
                                </tr>
                            </thead>
                        </DataTable>

                    </div>

                    {/* 
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={bannerList}
                        disableColumnMenu
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        // rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        // paginationMode="server"
                        // onPaginationModelChange={handlePageChange}
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            border: 0,
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                backgroundColor: '#F5F5F7'
                            },
                            '.MuiDataGrid-cell': {
                                border: 0,
                                paddingTop: '15px',
                                paddingBottom: '15px',
                            },
                        }}
                    /> */}
                </div>
            </div>
            <div className="bg-white mb-5 rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden py-[15px] px-[25px]" >
                <div className=" pt-[15px]">
                    <div className="d-flex align-items-center  mb-6 flex-wrap">
                        <div className="pr-5 mr-auto" >
                            <div className="font-semibold text-xl text-[#040C22]">แบนเนอร์ส่วนล่าง</div>
                        </div>
                    </div>
                    <div>
                        <Formik
                            initialValues={initialFormValues}
                            validationSchema={schema}
                            enableReinitialize
                            onSubmit={handleSubmitBannerSingle}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                submitForm
                            }) => (
                                <form onSubmit={handleSubmit} className="px-2">
                                    <div className="flex items-center mb-9">
                                        {
                                            imageSingle || values.image_path ? (
                                                <img src={imageSingle ? URL.createObjectURL(imageSingle) : values.image_path} className="w-[536px] h-[219px] object-cover rounded-lg " />
                                            ) : (
                                                <div className="w-[536px] h-[219px] bg-[#F2F2F2] rounded-lg flex items-center justify-center">
                                                    <CameraAltIcon className="text-[#BDBDBD] text-[50px]" />
                                                </div>
                                            )
                                        }
                                        <div className="ml-6">
                                            <Button
                                                variant="contained"
                                                className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                                onClick={() => inputFileRef.current.click()}
                                            >
                                                อัปโหลดรูปภาพ
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                                onClick={() => {
                                                    // setOpenConfirmSingle(true)
                                                    inputFileRef.current.value = ""
                                                    setImageSingle(null)
                                                    setFieldValue('image_path', null)
                                                }}
                                            >
                                                ลบ
                                            </Button>
                                            <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 10MB</div>
                                            <input
                                                ref={inputFileRef}
                                                accept="image/*"
                                                hidden
                                                id="avatar-image-upload"
                                                type="file"
                                                onChange={(event) => {
                                                    const newImage = event.target?.files?.[0];

                                                    if (newImage) {
                                                        const maxSizeInMB = 10;
                                                        if (newImage.size > maxSizeInMB * 1024 * 1024) {
                                                            alert(`แสดงว่าขนาดไฟล์ของคุณมีขนาดเกินกำหนด`);
                                                            return; // Exit the function to prevent further processing
                                                        }
                                                        inputFileRef.current.value = ""
                                                        setImageSingle(newImage)
                                                        setFieldValue('image_path', newImage)
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <div className="mb-6">
                                        <TextField
                                            label="URL ส่งออก *"
                                            name='link'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.link ?? ''}
                                            error={!!errors.link}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                            InputLabelProps={{
                                                shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                            }}
                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <Button
                                            variant="outlined"
                                            className="w-[111px] border-[#6D788D]/[0.5]  text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                        >
                                            ยกเลิก
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="w-[111px] bg-[#31A7DE] text-white  rounded-[8px] text-sm  h-[44px] "
                                            type="submit"
                                        >
                                            บันทึก
                                        </Button>
                                    </div>

                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <ModalSuccess
                open={openDeleteSuccess}
                setOpen={setOpenDeleteSuccess}
                message={"ลบข้อมูลสำเร็จ"}
            />
            <ModalSuccess
                open={openSaveSuccess}
                setOpen={setOpenSaveSuccess}
                message={"บันทึกข้อมูลสำเร็จ"}
            />
            <ModalConfirm
                open={openConfirm}
                setOpen={setOpenConfirm}
                message={"คุณต้องการลบข้อมูลแบนเนอร์นี้ใช่หรือไม่"}
                confirm={() => deleteBannerData()}
            />
            <ModalConfirm
                open={openConfirmSingle}
                setOpen={setOpenConfirmSingle}
                message={"คุณต้องการลบข้อมูลแบนเนอร์นี้ใช่หรือไม่"}
                confirm={() => deleteBannerSingleData()}
            />
            <ModalCreate open={openForm} setOpen={setOpenForm} setOpenSaveSuccess={setOpenSaveSuccess} bannerData={bannerFormData} setOpenConfirm={setOpenConfirm} setBannerDeleteId={setBannerDeleteId} />
        </>
    );
}

const ModalCreate = ({ open, setOpen, bannerData = null, setOpenConfirm, setOpenSaveSuccess, setBannerDeleteId }) => {
    const dispatch = useAppDispatch();
    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);
    const handleClose = () => setOpen(false);
    const [bannerFormData, setFormBannerData] = useState(null)
    const defaultFormValues = {
        id: null,
        image_path: null,
        name: null,
        is_status: null,
    }
    const initialFormValues = bannerFormData || defaultFormValues;
    const schema = yup.object().shape({
        name: yup.string().required(),
    });
    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = new FormData();

            if (image) {
                formData.append('image_path', image);
            }

            formData.append('name', values.name);
            formData.append('link', values.link);
            formData.append('is_status', values.is_status ? values.is_status : false);

            if (bannerFormData && bannerFormData.id) {
                dispatch(updateBanner(formData, bannerFormData.id))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            dispatch(getBanners())
                            setOpen(false)
                            // setOpenSaveSuccess(true)
                            window.location.reload();

                        }
                    })
                    .catch((error) => {
                        console.error('Error creating banner', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                dispatch(createBanner(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            dispatch(getBanners())
                            setOpen(false)
                            // setOpenSaveSuccess(true)
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating banner', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        } catch (error) {
            console.error('Error submitting the form', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (open) {
            setFormBannerData(bannerData)
        } else {
            setFormBannerData(defaultFormValues)
        }
    }, [open, bannerData]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="p-5">
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit} className="px-2">
                            <div className="flex mb-3">
                                <div className="mr-auto text-xl text-[#4C4E64]/[0.87]">
                                    {values?.id ? "แก้ไข " + values?.name : "เพิ่มแบนเนอร์สไลด์"}

                                </div>
                                <IconButton onClick={() => {
                                    setOpen(false)
                                }}>
                                    <CloseSharpIcon className="text-[#4C4E64]/[0.54]" />
                                </IconButton>
                            </div>
                            <div className="flex items-center mb-9">
                                {
                                    image || values.image_path ? (
                                        <img src={image ? URL.createObjectURL(image) : values.image_path} className="w-[150px] h-[85px] object-cover rounded-lg " />
                                    ) : (
                                        <div className="w-[150px] h-[85px] bg-[#F2F2F2] rounded-lg flex items-center justify-center">
                                            <CameraAltIcon className="text-[#BDBDBD] text-[50px]" />
                                        </div>
                                    )
                                }
                                <div className="ml-6">
                                    <Button
                                        variant="contained"
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileRef.current.value = ""
                                            setImage(null)
                                            setFieldValue('image', null)
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 10MB</div>
                                    <input
                                        ref={inputFileRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {
                                            const newImage = event.target?.files?.[0];

                                            if (newImage) {
                                                const maxSizeInMB = 10;
                                                if (newImage.size > maxSizeInMB * 1024 * 1024) {
                                                    alert(`แสดงว่าขนาดไฟล์ของคุณมีขนาดเกินกำหนด`);
                                                    return; // Exit the function to prevent further processing
                                                }
                                                setImage(newImage)
                                                setFieldValue('image', newImage)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mb-6">
                                <TextField
                                    label="ชื่อแบนเนอร์ *"
                                    name='name'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name ?? ''}
                                    error={!!errors.name}
                                    className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    InputLabelProps={{
                                        shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                    }}
                                />
                            </div>
                            <div className="mb-6">
                                <TextField
                                    label="URL ส่งออก"
                                    name='link'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.link ?? ''}
                                    error={!!errors.link}
                                    className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="mb-6">
                                {/* <FormControl>
                                    <div>สถานะ</div>
                                    <RadioGroup
                                        row
                                        aria-labelledby=""
                                        name="is_status"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.is_status}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="เปิดใช้งาน" />
                                        <FormControlLabel value="false" control={<Radio />} label="ปิดใช้งาน" />
                                    </RadioGroup>
                                </FormControl> */}
                            </div>
                            <div className="flex  my-4">
                                {
                                    values.id ? (
                                        <Button
                                            variant="outlined"
                                            className="w-[111px] border-[#FF4D49] text-[#FF4D49] rounded-[8px] text-sm mr-auto  h-[44px]"
                                            onClick={() => {
                                                setOpen(false)
                                                setBannerDeleteId(values.id)
                                                setOpenConfirm(true)
                                            }}
                                        >
                                            ลบ
                                        </Button>
                                    ) : (
                                        <div className="mr-auto"></div>
                                    )
                                }
                                <Button
                                    variant="outlined"
                                    className="w-[111px] border-[#6D788D]/[0.5]  text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    variant="contained"
                                    className="w-[111px] bg-[#31A7DE] text-white  rounded-[8px] text-sm  h-[44px] "
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    )
}

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/campaign">
        ร้านค้า/สถานที่
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
        ข้อมูลร้านค้า/สถานที่
    </Typography>
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 458,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 0,
    borderRadius: 2,
    overflow: 'hidden',
    outline: 'none'
};