import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Chip from '@mui/material/Chip';
import ic_edit from "assets/svg/ic_edit.svg";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';


import Switch from '@mui/material/Switch';
import { InvPrint } from "components/pages/report/invoice/printinvoice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as yup from "yup";
import Button from '@mui/material/Button';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useReactToPrint } from "react-to-print";

import DeleteIcon from '@mui/icons-material/Delete';
import thaiDate from 'utils/thaiDate';

export const DocumentLog = ({ invoice }) => {

    const [data, setData] = useState({
        campaignCode: "",
        invoiceCode: "",
        campaignShopInvoiceLog: [
        ]
    });



    useEffect(() => {

        setData({
            campaignCode: invoice.campaign.code,
            invoiceCode: invoice.code,
            campaignShopInvoiceLog: invoice.campaignShopInvoiceLog
        })


    }, []);

    return (
        <>
            <div className="bg-white rounded-[7px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden py-[20px] mb-7">
                <div className="px-[15px] py-[10px] mb-[60px] ">
                    <div className='flex justify-between px-[25px] mb-4'>
                        <div className='text-xl'>ใบบันทึกซื้อสินค้า/ค่าใช้จ่าย เลขที่ {data.invoiceCode}</div>
                        <div>
                            <div className='text-sm text-[#4C4E64]/[0.68]'>อ้างอิง</div>
                            <div>{data.campaignCode}</div>
                        </div>
                        <div c>
                            <div className='text-sm text-[#4C4E64]/[0.68]'>เลขที่ใบบันทึกซื้อสินค้า/ค่าใช้จ่าย</div>
                            <div>{data.invoiceCode}</div>
                        </div>
                    </div>
                    <hr />
                    <div className='p-4'>
                        <ul className='liststep'>
                            {
                                data && data.campaignShopInvoiceLog.map((item, index) => (
                                    <li key={index}>
                                        <div className='date'>{thaiDate(item.created_at, true)}</div>
                                        <div className='icon rounded-full w-[14px] h-[14px] bg-[#FFF]'></div>
                                        <div>

                                            <div className='border-[1px] rounded-lg py-2 px-3 w-[332px] text-[#5C5E71]'>
                                                <div className='mb-2'>{item.userAdmin.first_name} {item.userAdmin.last_name}</div>
                                                <div className='text-right'>{item.detail}</div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
