import * as scheduleDayAction from "../../slices/master/scheduleDay.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";



export const getScheduleDay = () => {
  return async (dispatch) => {
    dispatch(scheduleDayAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.SCHEDULE_DAY_URL}/list`,
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(scheduleDayAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      dispatch(scheduleDayAction.fetchDataFailure());
    }
  };
};
