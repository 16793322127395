
import DownloadExcel from "components/utils/DownloadExcel";
import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { getShopPagination, downloadExcelShop } from "../../../../redux/handlers/master/shop";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getProvinceShop } from "../../../../redux/handlers/master/province";
import { getShopCategoryShop } from "../../../../redux/handlers/master/shopCategory";
export function ListShop() {

    const dispatch = useAppDispatch();


    const shopReducer = useAppSelector((state) => state.shopReducer);
    const provinceReducer = useAppSelector((state) => state.provinceReducer);
    const shopCategoryReducer = useAppSelector((state) => state.shopCategoryReducer);
    const [activebtnstatus, setActivebtnstatus] = useState(null);
    const [shoplist, setShoplist] = useState([]);
    const [provincelist, setProvincelist] = useState([]);
    const [shopCategorylist, setShopCategorylist] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [waitCount, setWaitCount] = useState(0);
    const [approveCount, setApproveCount] = useState(0);
    const [cancelCount, setCancelCount] = useState(0);
    const [filterProvice, setFilterProvince] = useState(0);
    const [filterShopCategory, setFilterShopCategory] = useState(0);


    useEffect(() => {
        if (activebtnstatus == null) {
            dispatch(getShopPagination('all', page, pageSize, filterText, filterProvice, filterShopCategory));
        } else {
            if (activebtnstatus == 0) {
                dispatch(getShopPagination('wait', page, pageSize, filterText, filterProvice, filterShopCategory));
            } else if (activebtnstatus == 1) {
                dispatch(getShopPagination('approve', page, pageSize, filterText, filterProvice, filterShopCategory));
            } else if (activebtnstatus == 2) {
                dispatch(getShopPagination('cancel', page, pageSize, filterText, filterProvice, filterShopCategory));
            }
        }
    }, [page, pageSize, filterText, filterProvice, filterShopCategory, dispatch]);

    useEffect(() => {
        dispatch(getProvinceShop());
        dispatch(getShopCategoryShop());
    }, []);


    const changeStatus = (status) => {
        if (status == 0) {
            dispatch(getShopPagination('wait', 1, pageSize, filterText, filterProvice, filterShopCategory));
        } else if (status == 1) {
            dispatch(getShopPagination('approve', 1, pageSize, filterText, filterProvice, filterShopCategory));
        } else if (status == 2) {
            dispatch(getShopPagination('cancel', 1, pageSize, filterText, filterProvice, filterShopCategory));
        } else {
            dispatch(getShopPagination('all', 1, pageSize, filterText, filterProvice, filterShopCategory));
        }
    }
    useEffect(() => {
        if (shopReducer.data) {
            const data = shopReducer.data.data.data;
            setDataShop(data);
            setTotalRows(shopReducer?.data?.data?.meta?.total);
            setWaitCount(shopReducer?.data?.data?.meta?.waitCount)
            setApproveCount(shopReducer?.data?.data?.meta?.approveCount)
            setCancelCount(shopReducer?.data?.data?.meta?.cancelCount)

        }
    }, [shopReducer]);

    useEffect(() => {
        if (provinceReducer.data != null) {
            const data = provinceReducer.data.data;
            setProvincelist(data);
        }
    }, [provinceReducer]);

    useEffect(() => {
        if (shopCategoryReducer.data != null) {
            const data = shopCategoryReducer.data.data;
            setShopCategorylist(data);
        }
    }, [shopCategoryReducer]);

    const formatOpenTime = (data) => {
        if (data) {
            const jsonObject = JSON.parse(data);
            return `${jsonObject.open} - ${jsonObject.close}`
        }
    }
    const setDataShop = (data) => {
        if (data && data.length > 0) {
            const shopData = data.map((element) => (
                {
                    id: element.id,
                    shopCode: element?.code,
                    shopName: element?.name,
                    shopImage: element?.logo_image,
                    shopAddress: element?.address,
                    shopType: element?.shopCategory?.name,
                    shopOpen: element?.operation_time ? formatOpenTime(element?.operation_time) : '',
                    shopTelephoneNumber: element?.phone_number,
                    shopStatus: element?.approve_status == 'wait' ? 0 : element?.approve_status == 'approve' ? 1 : 2,
                    is_status: element.is_status
                }));
            setShoplist(shopData);
        } else {
            setShoplist([]);
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };

    const handleSearch = (e) => {

        setFilterText(e.target.value);
    };

    const handleSelectProvince = (e) => {
        if (e.target.value && e.target.value != 0) {
            console.log(e.target.value)
            setFilterProvince(e.target.value);
        } else if (e.target.value == 0) {
            setFilterProvince(e.target.value);
        }
    };
    const handleSelectShopCategory = (e) => {
        if (e.target.value && e.target.value != 0) {
            console.log(e.target.value)
            setFilterShopCategory(e.target.value);
        } else if (e.target.value == 0) {
            setFilterShopCategory(e.target.value);
        }
    };



    const [btnstatus, setBtnstatus] = useState([
        { name: "ทั้งหมด", status: null },
        { name: "ร้านค้า/สถานที่รออนุมัติ", status: 0 },
        { name: "ร้านค้า/สถานที่ที่อนุมัติแล้ว", status: 1 },
        { name: "ร้านค้า/สถานที่ที่ยกเลิก", status: 2 }
    ]
    );

    const download = () => {
        dispatch(downloadExcelShop(filterText, filterProvice, filterShopCategory))
    }






    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>

            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">ร้านค้า/สถานที่</div>


            <div className="mb-5">
                {
                    btnstatus && btnstatus.map((item, index) => (
                        <Button
                            variant={`${item.status == activebtnstatus ? 'contained' : 'text'}`}
                            onClick={() => {
                                setActivebtnstatus(item.status)
                                changeStatus(item.status)

                            }}
                            className={`rounded-lg mr-3 text-base ${item.status == activebtnstatus ? 'bg-[#31A7DE]' : 'text-[#9C9C9C]'}`}
                        >
                            <span className="mr-2">{item.name}   </span>
                            {
                                item.status !== null ? (
                                    <>
                                        {item.status == 0 && '( ' + waitCount + ' )'}
                                        {item.status == 1 && '( ' + approveCount + ' )'}
                                        {item.status == 2 && '( ' + cancelCount + ' )'}
                                    </>
                                ) : ''
                            }

                        </Button>
                    )


                    )}

            </div>

            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[15px] py-[15px]">
                    <div className="d-flex align-items-center px-4 mb-2 flex-wrap">

                        <div className="mr-auto flex" >
                            <div className="mr-3" >
                                <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>

                            </div>
                            <div className="mr-3">
                                <select
                                    className="form-control form-st-4 h-[38px] w-[250px] custom-select"

                                    onChange={handleSelectShopCategory}
                                >
                                    <option value="0" selected>
                                        ประเภทร้าน/สถานที่ทั้งหมด
                                    </option>
                                    {
                                        shopCategorylist.map((data, index) => (
                                            <option key={index} value={data.id}>{data.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className=" ">
                                <select
                                    className="form-control form-st-4 h-[38px] w-[250px] custom-select"
                                    onChange={handleSelectProvince}
                                >
                                    <option value="0" selected>
                                        จังหวัดทั้งหมด
                                    </option>
                                    {
                                        provincelist.map((data, index) => (
                                            <option key={index} value={data.id}>{data.name_th}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>


                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหาชื่อร้านค้า/สถานที่"
                                onKeyUp={handleSearch}
                            />
                        </div>
                        <Link
                            to="/manage/shop/0/information"
                            className="btn px-4 h-[38px] text-white  bg-[#31A7DE]"

                        >
                            เพิ่มร้านค้า/สถานที่
                        </Link>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={shoplist}
                        disableColumnMenu
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: pageSize,
                                },
                            },
                        }}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        pageSizeOptions={[5, 20, 30]}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                backgroundColor: '#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px'
                            }
                        }}
                    />
                </div>
            </div>


        </>
    );
}


const columns = [
    {
        field: 'shopCode',
        headerName: 'รหัสร้านค้า/สถานที่',
        headerClassName: 'bg-[#F5F5F7]',
        width: 150,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => (
            <a href={`/manage/shop/${row.id}/information`} target="_blank" className="text-[#31A7DE]">{row.shopCode}</a>
        ),
    },
    {
        field: 'shopName',
        headerName: 'ชื่อร้านค้า/สถานที่และสถานที่ท่องเที่ยว',
        headerClassName: 'bg-[#F5F5F7]',
        flex: 1,
        width: 150,
        editable: false,
        renderCell: ({ row }) => (
            <div className="flex items-center">
                <img src={row.shopImage} className="w-10 h-10 rounded-full" />
                <div className="ml-3">{row.shopName}</div>
            </div>
        ),
    },
    {
        field: 'shopAddress',
        headerName: 'ที่ตั้ง',
        headerClassName: 'bg-[#F5F5F7]',
        width: 110,
        flex: 1,
        editable: false,
    },
    {
        field: 'shopType',
        headerName: 'ประเภทร้านค้า/สถานที่',
        headerClassName: 'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
    },
    // {
    //     field: 'shopOpen',
    //     headerName: 'เวลาเปิด/ปิด',
    //     headerClassName:'bg-[#F5F5F7]',
    //     sortable: false,
    //     width: 160,
    //     center:false,
    //     headerAlign: 'center',
    //     align: 'center'
    // },
    {
        field: 'shopTelephoneNumber',
        headerName: 'เบอร์โทรศัพท์',
        headerClassName: 'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'shopStatus',
        headerName: 'สถานะ',
        headerClassName: 'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => (
            <ShopStatus status={row.shopStatus} is_status={row.is_status} />
        ),
    },
    {
        field: 'shopID',
        headerName: 'จัดการ',
        headerClassName: 'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => (
            <>
                <IconButton href={`/manage/shop/${row.id}/contact`} className="w-[30px] mr-3 h-[30px] p-1" style={{
                    background: '#E3E8F0',
                }} >
                    <img src={ic_edit} alt="" />
                </IconButton>
                <ShopButtonStatus status={!row.is_status ? 3 : row.shopStatus} href={`/manage/shop/${row.id}/information`} />
            </>

        ),
    },
];

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        ร้านค้า/สถานที่
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
        รายชื่อร้านค้า/สถานที่
    </Typography>
];

