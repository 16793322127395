import Modal from '@mui/material/Modal';
import React, { useState, useEffect,useRef } from "react";
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
export const ModalDeleteSuccess = ({open,setOpen}) => {

    const handleClose = () => setOpen(false);
    

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal} className="py-[50px]">
                <div className='text-center'>
                    <ErrorIcon className='text-[64px] text-[#F24236]' />
                </div>
                <div className='text-center text-2xl mt-7 font-semibold'>ลบข้อมูลสำเร็จ</div>
            </Box>
        </Modal>
    )
}

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 438,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 0,
    borderRadius:2,
    overflow:'hidden'
};