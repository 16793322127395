import DownloadExcel from "components/utils/DownloadExcel";
import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import formatDatetime from "utils/formatDatetime";
import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getUserTravellerCouponUsedPagination, downloadExcelUserTravellerCouponUsed } from "../../../redux/handlers/master/userTravellerCouponUsed";

export function CouponHistory() {
    let { id } = useParams();
    const dispatch = useAppDispatch();
    const userTravellerCouponUsedReducer = useAppSelector((state) => state.userTravellerCouponUsedReducer);
    const [userTravellerCouponUsedList, setUserTravellerCouponUsedList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    }
    const setUserTravellerCouponUsedData = (data) => {
        if (data && data.length > 0) {
            const userTravellerCouponUsedData = data.map((element) => ({
                id: element.id,
                date: formatDatetime(element?.created_at),
                shop: element?.shop?.name,
                couponName: element?.userTravellerCoupon.coupon?.coupon_name,
                discount: `${element?.userTravellerCoupon.coupon?.discount_value} ${element?.userTravellerCoupon.coupon?.discount_type == 'percent' ? '%' : 'บาท'}`,
                refCode: element?.userTravellerCoupon.shopCouponDistribution[0]?.code,
            }));

            setUserTravellerCouponUsedList(userTravellerCouponUsedData);
        } else {
            setUserTravellerCouponUsedList([]);
        }
    };
    const download = () => {
        dispatch(downloadExcelUserTravellerCouponUsed(id, filterText))
    }
    const columns = [
        {
            field: 'date',
            headerName: 'วันที่ใช้',
            headerClassName: 'bg-[#F5F5F7]',
            width: 250,
            editable: false,
        },
        {
            field: 'shop',
            headerName: 'ร้านค้า/สถานที่ที่ใช้',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 250,
            editable: false,
        },
        {
            field: 'couponName',
            headerName: 'ชื่อคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            width: 220,
            flex: 1,
            editable: false,
        },
        {
            field: 'discount',
            headerName: 'ส่วนลดที่ได้รับ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 220,
            renderCell: ({ row }) => (
                <>{row.discount}</>
            ),
        },
        {
            field: 'refCode',
            headerName: 'อ้างอิง',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 220,
        },
    ];

    useEffect(() => {
        dispatch(getUserTravellerCouponUsedPagination(id, page, pageSize, filterText));
    }, [id, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (userTravellerCouponUsedReducer.data != null && userTravellerCouponUsedReducer.data.data) {
            const data = userTravellerCouponUsedReducer.data.data.data;
            setUserTravellerCouponUsedData(data);
            setTotalRows(userTravellerCouponUsedReducer?.data?.data?.meta?.total);
        }
    }, [userTravellerCouponUsedReducer]);

    return (
        <>
            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px]">
                <div className="text-[#4C4E64]/[0.87] font-bold text-xl mb-5 mt-2">ประวัติการใช้งานคูปอง</div>
                <div className="d-flex align-items-center  mb-5 flex-wrap">
                    <div className="mr-auto" >
                        <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                    </div>
                    <div className=" ">
                        <input
                            type="text"
                            className="form-control form-st-4 h-[40px] w-[250px]"
                            placeholder="ค้นหา"
                            onKeyUp={handleSearch}
                        />
                    </div>
                </div>
                <div className="mb-5">
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={userTravellerCouponUsedList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                backgroundColor: '#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-footerContainer': {
                                marginRight: '20px'
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}
