
import DownloadExcel from "components/utils/DownloadExcel";
import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Chip from '@mui/material/Chip';
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";
import { Password } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';

import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { ModalConfirm } from "components/layouts/ModalConfirm";
import { ModalSuccess } from "components/layouts/ModalSuccess";
import { ModalDeleteSuccess } from "components/layouts/ModalDeleteSuccess";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { createUserShopEmployee, deleteUserShopEmployee, downloadExcelUserShopEmployee, getPaginationUserShopEmployee, getUserShopEmployeeById, updateUserShopEmployee } from "../../../../redux/handlers/shop/userShopEmployee";
import { getPrefix } from "../../../../redux/handlers/master/prefix";
import { getUserShopPosition } from "../../../../redux/handlers/master/userShopPosition";
import { getUserShopRole } from "../../../../redux/handlers/master/userShopRole";

export function EmpShop() {
    let { shopID } = useParams();
    const dispatch = useAppDispatch();

    const userShopReducer = useAppSelector((state) => state.userShopReducer);
    const [emp, setEmp] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);

    useEffect(() => {
        dispatch(getPaginationUserShopEmployee(page, pageSize, shopID, ""));
    }, [shopID, page, pageSize, dispatch]);

    useEffect(() => {
        if (userShopReducer.data && userShopReducer.data.data) {
            setEmp(userShopReducer.data.data.map((item) => {
                return {
                    id: item.id,
                    prefix: item?.prefixName?.name,
                    name: item.first_name,
                    surname: item.last_name,
                    image: item.image_path ?? '/images/profile-circle.svg',
                    phoneNumber: item.phone_number,
                    position: item.userShopPosition,
                    permissions: item.userShopRole,
                    password: item.code,
                    is_verify_phone: item.is_verify_phone,
                    status: item.is_status
                }
            }))
            setTotalRows(userShopReducer?.data?.meta?.total);

        }


    }, [userShopReducer?.data?.data]);

    useEffect(() => {
        if (userShopReducer.data && userShopReducer.data.id != null) {
            setEmpEdit({
                "id": userShopReducer.data.id,
                "image": userShopReducer.data.image_path,
                "prefix_name_id": userShopReducer.data.prefix_name_id,
                "name": userShopReducer.data.first_name,
                "surname": userShopReducer.data.last_name,
                "phone_number": userShopReducer.data.phone_number,
                "user_shop_position_id": userShopReducer.data.user_shop_position_id,
                "user_shop_role_id": userShopReducer.data.user_shop_role_id,
                "is_verify_phone": userShopReducer.data.is_verify_phone,
                "status": userShopReducer.data.is_status ? 1 : 0
            })
            setModalOpen(true)
        }


    }, [userShopReducer?.data]);


    const [searchTerm, setSearchTerm] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const handleKeyUp = (e) => {
        const value = e.target.value;

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(() => {
            dispatch(getPaginationUserShopEmployee(page, pageSize, shopID, value));
            setSearchTerm(value);
        }, 500);

        setDebounceTimeout(newTimeout);
    };

    const download = () => {
        dispatch(downloadExcelUserShopEmployee(page, pageSize, shopID, searchTerm))
    }

    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };

    const columns = [
        {
            field: 'password',
            headerName: 'รหัสเข้าใช้งาน',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            editable: false,

            renderCell: ({ row }) => (
                <div>{row.password}</div>
            ),
        },
        {
            field: 'name',
            headerName: 'ชื่อ - นามสกุล',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            editable: false,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <img src={row.image} className="w-10 h-10 rounded-full" />
                    <div className="ml-3">{row.prefix} {row.name} {row.surname}</div>
                </div>
            ),
        },
        {
            field: 'position',
            headerName: 'ตำแหน่ง',
            headerClassName: 'bg-[#F5F5F7]',
            width: 110,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <div className="">{row?.position?.name}</div>
                </div>
            ),
        },

        {
            field: 'permissions',
            headerName: 'สิทธิ์การใช้งาน',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <div className="">{row?.permissions?.name}</div>
                </div>
            ),
        },
        {
            field: 'id2',
            headerName: 'สถานะ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    {
                        row.status == 1 ? (
                            row.is_verify_phone ? (
                                <Chip label={"เปิดใช้งาน"} className="w-[88px]" style={{
                                    color: 'rgba(114, 225, 40, 0.87)',
                                    background: '#EEFBE5',
                                }} />
                            ) : (
                                <Chip label={"รอเปิดการใช้งาน"} className="w-[120px]" style={{
                                    color: 'rgba(255, 255, 255, 0.87)',
                                    background: '#75788D',
                                }} />
                            )
                        ) : (
                            <Chip label={"ปิดใช้งาน"} className="w-[88px]" style={{
                                color: '#FF0000',
                                background: '#ff00001f',
                            }} />
                        )
                    }

                </>

            ),
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <IconButton className="w-[30px] mr-3 h-[30px] p-1" style={{
                        background: '#E3E8F0',
                    }}
                        onClick={() => {
                            setDeleteId(row.id)
                            dispatch(getUserShopEmployeeById(row.id))
                        }}

                    >
                        <img src={ic_edit} alt="" />
                    </IconButton>
                </>

            ),
        },
    ];

    const [modalOpen, setModalOpen] = useState(false)
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false)
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const deleteData = () => {
        dispatch(deleteUserShopEmployee(deleteId)).then(() => {
            dispatch(getPaginationUserShopEmployee(page, pageSize, shopID, searchTerm))
            setDeleteId(null)
            setOpenConfirm(false)
            setModalDeleteOpen(true)
        })
    }

    const [empEdit, setEmpEdit] = useState({});

    const initialemp = {
        "image": "",
        "prefix_name_id": "",
        "name": "",
        "surname": "",
        "phone_number": "",
        "user_shop_position_id": "",
        "user_shop_role_id": "",
        "status": ""
    }
    return (
        <>

            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[10px] py-[10px]">
                    <div className="text-[#4C4E64]/[0.87] text-xl  px-4 font-semibold mb-5">ข้อมูลพนักงาน</div>
                    <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
                        <div className="mr-auto" >
                            {/* <DownloadExcel data={emp} filename="ผู้ใช้งานระบบ" /> */}
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>ผู้ใช้งานระบบ</button>
                        </div>


                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหา"
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <Button

                            className="btn px-4 h-[38px] text-white  bg-[#31A7DE]"
                            onClick={() => {
                                setEmpEdit(initialemp)

                                setModalOpen(true)

                            }
                            }
                        >
                            เพิ่มพนักงาน
                        </Button>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={emp}
                        columns={columns}
                        disableColumnMenu
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: pageSize,
                                },
                            },
                        }}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        pageSizeOptions={[5, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowCount={totalRows}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                backgroundColor: '#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px'
                            }
                        }}
                    />
                </div>
                <ModalCreate shopID={shopID} setOpen={setModalOpen} open={modalOpen} empEdit={empEdit} setSuccessOpen={() => {
                    setModalSuccessOpen(true);
                    dispatch(getPaginationUserShopEmployee(page, pageSize, shopID, searchTerm))
                }} setOpenConfirm={setOpenConfirm} setDeleteId={setDeleteId} />
                <ModalSuccess setOpen={setModalSuccessOpen} open={modalSuccessOpen} />
                <ModalDeleteSuccess setOpen={setModalDeleteOpen} open={modalDeleteOpen} />
                <ModalConfirm
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    message={"คุณต้องการลบข้อมูลนี้ใช่หรือไม่"}
                    confirm={() => deleteData()}
                />
            </div>


        </>
    );
}





const ModalCreate = ({ shopID, setOpen, open, empEdit, setSuccessOpen, setDeleteOpen, setOpenConfirm, setDeleteId }) => {

    const dispatch = useAppDispatch();
    const handleClose = () => setOpen(false);
    const [messageError, setMessageError] = useState("")


    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);

    const schema = yup.object().shape({
        prefix_name_id: yup.string().required(),
        name: yup.string().required(),
        surname: yup.string().required(),
        phone_number: yup.string().required(),
        user_shop_position_id: yup.string().required(),
        user_shop_role_id: yup.string().required(),
        status: yup.number().required(),

    });

    const initialemp = {
        "image": "",
        "prefix_name_id": "",
        "name": "",
        "surname": "",
        "phone_number": "",
        "user_shop_position_id": "",
        "user_shop_role_id": "",
        "status": ""
    }

    const [empForm, setEmpForm] = useState({});


    useEffect(() => {
        if (empEdit && empEdit.id) {
            setEmpForm({
                "id": empEdit.id,
                "previewImage": empEdit.image,
                "prefix_name_id": empEdit.prefix_name_id,
                "name": empEdit.name,
                "surname": empEdit.surname,
                "phone_number": empEdit.phone_number,
                "user_shop_position_id": empEdit.user_shop_position_id,
                "user_shop_role_id": empEdit.user_shop_role_id,
                "status": empEdit.status
            })
        } else {
            setEmpForm(initialemp)
        }


    }, [empEdit]);


    const [prefixList, setPrefixList] = useState([])
    useEffect(() => {
        dispatch(getPrefix());
    }, []);

    const prefixReducer = useAppSelector(
        (state) => state.prefixReducer
    );

    useEffect(() => {
        if (prefixReducer.data) {
            setPrefixList(prefixReducer.data.data);
        }
    }, [prefixReducer]);

    const prefix = [
    ];

    if (prefixList && prefixList.length > 0) {
        for (const element of prefixList) {
            prefix.push({
                label: element.name,
                value: element.id
            })
        }
    }

    const [positionList, setPositionList] = useState([])
    useEffect(() => {
        dispatch(getUserShopPosition());
    }, []);

    const userShopPositionReducer = useAppSelector(
        (state) => state.userShopPositionReducer
    );

    useEffect(() => {
        if (userShopPositionReducer.data) {
            setPositionList(userShopPositionReducer.data.data);
        }
    }, [userShopPositionReducer]);

    const position = [
    ];

    if (positionList && positionList.length > 0) {
        for (const element of positionList) {
            position.push({
                label: element.name,
                value: element.id
            })
        }
    }

    const [permissionsList, setpermissionsList] = useState([])
    useEffect(() => {
        dispatch(getUserShopRole());
    }, []);

    const userShopRoleReducer = useAppSelector(
        (state) => state.userShopRoleReducer
    );

    useEffect(() => {
        if (userShopRoleReducer.data) {
            setpermissionsList(userShopRoleReducer.data.data);
        }
    }, [userShopRoleReducer]);

    const permissions = [
    ];

    if (permissionsList && permissionsList.length > 0) {
        for (const element of permissionsList) {
            permissions.push({
                label: element.name,
                value: element.id
            })
        }
    }


    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal} className="p-6 ">
                    <div className="flex mb-3">
                        <div className="mr-auto text-xl text-[#4C4E64]/[0.87]">เพิ่มพนักงาน</div>
                        <IconButton onClick={() => {
                            setOpen(false)
                        }}>
                            <CloseSharpIcon className="text-[#4C4E64]/[0.54]" />
                        </IconButton>
                    </div>
                    <div>
                        <Formik
                            initialValues={empForm}
                            validationSchema={schema}
                            enableReinitialize
                            onSubmit={async (values) => {
                                if (empEdit && empEdit.id) {
                                    dispatch(updateUserShopEmployee(empEdit.id, {
                                        user_shop_type_id: 2,
                                        image_path: values.image ? values.image : null,
                                        shop_id: shopID,
                                        nationality_id: null,
                                        prefix_name_id: values.prefix_name_id,
                                        user_shop_position_id: values.user_shop_position_id,
                                        user_shop_role_id: values.user_shop_role_id,
                                        gender_id: null,
                                        first_name: values.name,
                                        last_name: values.surname,
                                        line_user_id: null,
                                        phone_number: values.phone_number,
                                        is_verify_phone: false,
                                        verify_phone_code: null,
                                        birth_date: null,
                                        is_upload_image: null,
                                        is_status: !!Number(values.status),
                                    })).then((result) => {
                                        if (result?.data?.message == 'USER_HAS_ALREADY_PHONE_NUMBER') {
                                            setMessageError("มีเบอร์โทรในระบบแล้ว");
                                            return;
                                        }
                                        setOpen(false)
                                        setSuccessOpen(true)
                                    });
                                } else {
                                    dispatch(createUserShopEmployee({
                                        user_shop_type_id: 2,
                                        image_path: values.image ? values.image : null,
                                        shop_id: shopID,
                                        nationality_id: null,
                                        prefix_name_id: values.prefix_name_id,
                                        user_shop_position_id: values.user_shop_position_id,
                                        user_shop_role_id: values.user_shop_role_id,
                                        gender_id: null,
                                        first_name: values.name,
                                        last_name: values.surname,
                                        line_user_id: null,
                                        phone_number: values.phone_number,
                                        is_verify_phone: false,
                                        verify_phone_code: null,
                                        birth_date: null,
                                        is_upload_image: !!(values.image != null),
                                        is_status: !!Number(values.status),
                                    })).then((result) => {
                                        if (result?.data?.message == 'USER_HAS_ALREADY_PHONE_NUMBER') {
                                            setMessageError("มีเบอร์โทรในระบบแล้ว");
                                            return;
                                        }
                                        setOpen(false)
                                        setSuccessOpen(true)
                                    });
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                submitForm
                            }) => (
                                <form onSubmit={handleSubmit} className="px-2">
                                    <div className="text-[#4C4E64]/[0.64] mb-2">ภาพโพรไฟล์ผู้ติดต่อ</div>
                                    <div className="flex items-center mb-9">
                                        <Avatar
                                            alt=""
                                            src={values.previewImage}
                                            sx={{ width: 101, height: 101 }}
                                        />
                                        <div className="ml-6">
                                            <Button
                                                variant="contained"
                                                className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                                onClick={() => inputFileRef.current.click()}
                                            >
                                                อัปโหลดรูปภาพ
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                                onClick={() => {
                                                    inputFileRef.current.value = ""
                                                    setImage(null)
                                                }}
                                            >
                                                ลบ
                                            </Button>

                                            <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 5MB</div>
                                            <input
                                                ref={inputFileRef}
                                                accept="image/*"
                                                hidden
                                                id="avatar-image-upload"
                                                type="file"
                                                onChange={(event) => {
                                                    const newImage = event.target?.files?.[0];
                                                    if (newImage) {
                                                        const maxSizeInMB = 5;
                                                        if (newImage.size > maxSizeInMB * 1024 * 1024) {
                                                            alert(`แสดงว่าขนาดไฟล์ของคุณมีขนาดเกินกำหนด`);
                                                            return; // Exit the function to prevent further processing
                                                        }
                                                        setFieldValue('image', newImage)
                                                        setFieldValue('previewImage', URL.createObjectURL(newImage))
                                                    }

                                                }}
                                            />
                                        </div>

                                    </div>

                                    <div className="mb-6">
                                        <TextField
                                            select
                                            label="คำนำหน้า *"
                                            name='prefix_name_id'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.prefix_name_id}

                                            error={!!errors.prefix_name_id}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        >
                                            {prefix.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="mb-6">
                                        <TextField
                                            label="ชื่อ *"
                                            name='name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            error={!!errors.name}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <TextField
                                            label="นามสกุล *"
                                            name='surname'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.surname}
                                            error={!!errors.surname}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <TextField
                                            label="เบอร์โทร *"
                                            name='phone_number'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone_number}
                                            error={!!errors.phone_number}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <TextField
                                            select
                                            label="ตำแหน่ง *"
                                            name='user_shop_position_id'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.user_shop_position_id}
                                            error={!!errors.user_shop_position_id}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        >
                                            {position.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="mb-6">
                                        <TextField

                                            select
                                            label="สิทธิ์การใช้งาน *"
                                            name='user_shop_role_id'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.user_shop_role_id}

                                            error={!!errors.user_shop_role_id}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        >
                                            {permissions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="mb-6">
                                        <FormControl>
                                            <div>สถานะ</div>
                                            <RadioGroup
                                                row
                                                aria-labelledby=""
                                                name="status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.status}

                                            >
                                                <FormControlLabel value="1" control={<Radio />} label="เปิดใช้งาน" />
                                                <FormControlLabel value="0" control={<Radio />} label="ปิดใช้งาน" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="text-center text-[#F24236] font-semibold py-[10px]">
                                        <div>{messageError}</div>
                                    </div>
                                    <div className="flex  ">
                                        {
                                            values.id ? (
                                                <Button
                                                    variant="outlined"
                                                    className="w-[111px] border-[#FF4D49] text-[#FF4D49] rounded-[8px] text-sm mr-auto  h-[44px]"
                                                    onClick={() => {
                                                        setOpen(false)
                                                        setDeleteId(empEdit.id)
                                                        setOpenConfirm(true)
                                                    }}
                                                >
                                                    ลบ
                                                </Button>
                                            ) : (
                                                <div className="mr-auto"></div>
                                            )
                                        }


                                        <Button
                                            variant="outlined"
                                            className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                            onClick={() => {
                                                setDeleteId(null)
                                                setOpen(false)
                                            }}
                                        >
                                            ยกเลิก
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] "
                                            type="submit"
                                        >
                                            บันทึก
                                        </Button>

                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>

                </Box>
            </Modal >
        </>
    )
}

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 538,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: '15px',
    borderRadius: 2,
    overflow: 'hidden',
    outline: 'none'
};