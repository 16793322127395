

import Clock from "../../assets/icon/Clock.svg";

import Card from "../../assets/icon/card2.png";
import Discount from "../../assets/icon/discount.svg";
import HomeActive from "../../assets/icon/HomeActive.svg";

import Tag from "../../assets/icon/Tag.svg";
import Ticket from "../../assets/icon/Ticket.svg";
import UserCircle from "../../assets/icon/UserCircle.svg";

import TagActive from "../../assets/icon/TagActive.svg";
import TicketActive from "../../assets/icon/TicketActive.svg";
import UserCircleActive from "../../assets/icon/UserCircleActive.svg";

import Home2 from "../../assets/icon/Home2.svg";
import MapPoint from "../../assets/icon/MapPoint.svg";
import MapPointW from "../../assets/icon/MapPointW.svg";
import Map from "../../assets/icon/Map.svg";
import filter from "../../assets/icon/filter.svg";

import MapPointB from "../../assets/icon/MapPointB.svg";
import phone from "../../assets/icon/phone.svg";
import Clock2 from "../../assets/icon/Clock2.svg";
import scan from "../../assets/icon/scan.svg";

import MapPointWave from "../../assets/icon/MapPointWave.svg";

import Camera from "../../assets/icon/Camera.svg";
import Gallery from "../../assets/icon/Gallery.svg";

import Bell from "../../assets/icon/Bell.svg";


import History from "../../assets/icon/History.svg";
import HandStars from "../../assets/icon/HandStars.svg";
import ClipboardAdd from "../../assets/icon/ClipboardAdd.svg";
import UserCircleW from "../../assets/icon/UserCircleW.svg";
import PhoneRounded from "../../assets/icon/PhoneRounded.svg";
import ic1 from "../../assets/icon/ic1.svg";
import ic2 from "../../assets/icon/ic2.svg";
import ic3 from "../../assets/icon/ic3.svg";

import DocumentText from "../../assets/icon/DocumentText.svg";

import c1 from "../../assets/icon/c1.png";
import c2 from "../../assets/icon/c2.png";
import c3 from "../../assets/icon/c3.png";

import TickerStarW from "../../assets/icon/TickerStarW.svg";
import TicketW from "../../assets/icon/TicketW.svg";
import BillCrossW from "../../assets/icon/BillCrossW.svg";


import BuildingsW from "../../assets/icon/BuildingsW.svg";
import ChefHatW from "../../assets/icon/ChefHatW.svg";
import giftboxW from "../../assets/icon/giftboxW.svg";
import UsersGroupW from "../../assets/icon/UsersGroupW.svg";
import mage_maleW from "../../assets/icon/mage_maleW.svg";
import mage_femaleW from "../../assets/icon/mage_femaleW.svg";

import excel2 from "../../assets/icon/excel2.svg";
import pdf from "../../assets/icon/pdf.svg";

export function Excel2Icon() {

    const style = {
        backgroundImage: `url(${excel2}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '27px'
    }

    return (
        <span className="pl-[38px] h-[28px]" style={style}></span>
    )
}

export function PdfIcon() {

    const style = {
        backgroundImage: `url(${pdf}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '27px'
    }

    return (
        <span className="pl-[38px] h-[28px]" style={style}></span>
    )
}


export function FemaleWIcon() {

    const style = {
        backgroundImage: `url(${mage_femaleW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function MaleWIcon() {

    const style = {
        backgroundImage: `url(${mage_maleW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function UsersGroupWIcon() {

    const style = {
        backgroundImage: `url(${UsersGroupW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function GiftboxWIcon() {

    const style = {
        backgroundImage: `url(${giftboxW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function ChefHatWIcon() {

    const style = {
        backgroundImage: `url(${ChefHatW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function BuildingsWIcon() {

    const style = {
        backgroundImage: `url(${BuildingsW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}



export function ClockIcon() {

    const style = {
        backgroundImage: `url(${Clock}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[34px] h-[24px]" style={style}></span>
    )
}

export function CardIcon() {

    const style = {
        backgroundImage: `url(${Card}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[34px] h-[24px]" style={style}></span>
    )
}

export function DiscountIcon() {

    const style = {
        backgroundImage: `url(${Discount}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[34px] h-[24px]" style={style}></span>
    )
}
export function Home2Icon() {

    const style = {
        backgroundImage: `url(${Home2}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function HomeActiveIcon() {

    const style = {
        backgroundImage: `url(${HomeActive}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function TagIcon() {

    const style = {
        backgroundImage: `url(${Tag}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function TagActiveIcon() {

    const style = {
        backgroundImage: `url(${TagActive}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function TicketIcon() {

    const style = {
        backgroundImage: `url(${Ticket}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function TicketActiveIcon() {

    const style = {
        backgroundImage: `url(${TicketActive}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function UserCircleIcon() {

    const style = {
        backgroundImage: `url(${UserCircle}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function UserCircleActiveIcon() {

    const style = {
        backgroundImage: `url(${UserCircleActive}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function MapPointIcon() {

    const style = {
        backgroundImage: `url(${MapPoint}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function MapIcon() {

    const style = {
        backgroundImage: `url(${Map}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '16px'
    }

    return (
        <span className="pl-[20px] h-[16px]" style={style}></span>
    )
}



export function MapPointWIcon() {

    const style = {
        backgroundImage: `url(${MapPointW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '12px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}
export function FilterIcon() {

    const style = {
        backgroundImage: `url(${filter}) `,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[34px] h-[34px]" style={style}></span>
    )
}

export function MapPointBIcon() {

    const style = {
        backgroundImage: `url(${MapPointB}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '18px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function PhoneIcon() {

    const style = {
        backgroundImage: `url(${phone}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '18px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}
export function Clock2Icon() {

    const style = {
        backgroundImage: `url(${Clock2}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '18px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}
export function ScanIcon() {

    const style = {
        backgroundImage: `url(${scan}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function MapPointWaveIcon() {

    const style = {
        backgroundImage: `url(${MapPointWave}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function CameraIcon() {

    const style = {
        backgroundImage: `url(${Camera}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function GalleryIcon() {

    const style = {
        backgroundImage: `url(${Gallery}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function BellIcon() {

    const style = {
        backgroundImage: `url(${Bell}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}




export function HistoryIcon() {

    const style = {
        backgroundImage: `url(${History}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '42px'
    }

    return (
        <div className="pl-[42px] h-[42px]" style={style}></div>
    )
}


export function HandStarsIcon() {

    const style = {
        backgroundImage: `url(${HandStars}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '42px'
    }

    return (
        <div className="pl-[42px] h-[42px]" style={style}></div>
    )
}

export function ClipboardAddIcon() {

    const style = {
        backgroundImage: `url(${ClipboardAdd}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '42px'
    }

    return (
        <div className="pl-[42px] h-[42px]" style={style}></div>
    )
}

export function UserCircleWIcon() {

    const style = {
        backgroundImage: `url(${UserCircleW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '42px'
    }

    return (
        <div className="pl-[42px] h-[42px]" style={style}></div>
    )
}


export function PhoneRoundedIcon() {

    const style = {
        backgroundImage: `url(${PhoneRounded}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function Ic1Icon() {

    const style = {
        backgroundImage: `url(${ic1}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '18px'
    }

    return (
        <span className="pl-[18px] h-[18px]" style={style}></span>
    )
}

export function Ic2Icon() {

    const style = {
        backgroundImage: `url(${ic2}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '18px'
    }

    return (
        <span className="pl-[18px] h-[18px]" style={style}></span>
    )
}
export function Ic3Icon() {

    const style = {
        backgroundImage: `url(${ic3}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '18px'
    }

    return (
        <span className="pl-[18px] h-[18px]" style={style}></span>
    )
}

export function DocumentTextIcon() {

    const style = {
        backgroundImage: `url(${DocumentText}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function C1Icon() {

    const style = {
        backgroundImage: `url(${c1}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function C2Icon() {

    const style = {
        backgroundImage: `url(${c2}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function C3Icon() {

    const style = {
        backgroundImage: `url(${c3}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}


export function TickerStarWIcon() {

    const style = {
        backgroundImage: `url(${TickerStarW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function TicketWIcon() {

    const style = {
        backgroundImage: `url(${TicketW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}

export function BillCrossWIcon() {

    const style = {
        backgroundImage: `url(${BillCrossW}) `,
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px'
    }

    return (
        <span className="pl-[24px] h-[24px]" style={style}></span>
    )
}
