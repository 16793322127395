import * as userTravellerCouponAction from "../../slices/master/userTravellerCoupon.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";
import { endPoint } from "../../../constants/server/api";

export const getUserTravellerCouponPagination = (user_traveller_id = "", is_unused = true, page = 1, perPage = 12, s = "") => {
  return async (dispatch) => {
    dispatch(userTravellerCouponAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.USER_TRAVELLER_COUPON_URL}/pagination`,
        params: {
          user_traveller_id,
          is_unused,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      result.data.is_unused = is_unused
      dispatch(userTravellerCouponAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(userTravellerCouponAction.fetchDataFailure());
    }
  };
};