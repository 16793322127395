import * as bankAction from "../../slices/master/bank.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getBankList = () => {
  return async (dispatch) => {
    dispatch(bankAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.BANK_URL}/list`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(bankAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(bankAction.fetchDataFailure());
    }
  };
};

export const getBank = (id) => {
  return async (dispatch) => {
    dispatch(bankAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.BANK_URL}/${id}`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(bankAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(bankAction.fetchDataFailure());
    }
  };
};