
import { NavLink } from "react-router-dom";

import { Icon } from '@iconify/react';
import iuserid from "assets/svg/UserId.svg";
import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
export function Header({ selectbranchID }) {


    const handleEnterHoverSidebar = () => {
        if (document.body.classList.contains('sidenav-toggled')) {
            document.body.classList.add("sidenav-toggled-open")
        }
    }

    const handleLeaveHoverSidebar = () => {
        if (document.body.classList.contains('sidenav-toggled')) {
            document.body.classList.remove("sidenav-toggled-open")
        }
    }

    function handleSlide(index) {
        var slide = document.querySelectorAll('.slide');
        for (let i = 0; i < slide.length; i++) {
            slide[i].classList.remove('is-expanded')
        }
        slide[index].classList.add('is-expanded')
    }

    const [expanded, setExpanded] = useState();

    return (
        <div>
            <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
            <aside className="app-sidebar sidebar-scroll"
                onMouseEnter={() => handleEnterHoverSidebar()}
                onMouseLeave={() => handleLeaveHoverSidebar()}
            >
                <div className="main-sidebar-header px-0  active">
                    <a className="desktop-logo active   text-center text-[12px] " href="">
                        <div className="flex items-center mt-3  pl-4">
                            <img src="/images/logo.png" className="main-logo w-[40px] mt-0" alt="logo" />
                            <div className="ml-3">การท่องเที่ยวแห่งประเทศไทย</div>
                        </div>
                    </a>
                    <a className="logo-icon mobile-logo py-3 px-0 icon-light active" href="">
                        <img src="/images/logo.png" className="logo-icon mx-auto" alt="logo" />
                    </a>
                </div>

                <div className="main-sidemenu px-2 pt-0">
                    <ul className="side-menu">
                        <li className="slide"  >
                            <NavLink
                                to={`/dashboard`}
                                className="side-menu__item py-1 "
                            >
                                <div className="side-menu_icon">
                                    <div className="side-menu__icon ">
                                        <Icon icon="fluent:home-32-regular" />
                                    </div>
                                </div>
                                <span className="side-menu__label"> หน้าหลัก</span>
                            </NavLink>
                        </li>

                        <div className="text-sm text-[#4C4E6461] py-3 mb-[10px]">--เมนู</div>
                        {/* <li className="slide"  >
                            <NavLink
                                to={`/insurance/premium`}
                                className="side-menu__item py-1 "
                            >
                                <div className="side-menu_icon">
                                    <div className="side-menu__icon ">
                                        <Icon icon="ant-design:pie-chart-outlined" />
                                    </div>
                                </div>
                                <span className="side-menu__label"> รานงาน</span>
                            </NavLink>
                        </li> */}

                        <li
                            className={`slide ${expanded == 3 ? 'is-expanded' : ''} `}
                            onClick={(e) => {
                                expanded != 3 ? setExpanded(3) : setExpanded('')
                            }}
                        >
                            <a className="side-menu__item py-2" data-toggle="slide" href="#">
                                <div className="side-menu_icon">
                                    <div className="side-menu__icon ">
                                        <Icon icon="ant-design:pie-chart-outlined" />
                                    </div>
                                </div>
                                <span className="side-menu__label">รานงาน</span>
                            </a>
                            <ul className="slide-menu">
                                <li>
                                    <NavLink
                                        to={`/report/campaign`}
                                        className="slide-item"
                                    >
                                        ใบแจ้งหนี้
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/report/survey`}
                                        className="slide-item"
                                    >
                                        รายงานแบบสำรวจ
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/report/campaigndetail`}
                                        className="slide-item"
                                    >
                                        รายละเอียดแคมเปญ
                                    </NavLink>
                                </li>
                            </ul>
                        </li>


                        <li
                            className={`slide ${expanded == 4 ? 'is-expanded' : ''} `}
                            onClick={(e) => {
                                expanded != 4 ? setExpanded(4) : setExpanded('')
                            }}
                        >
                            <a className="side-menu__item py-2" data-toggle="slide" href="#">
                                <div className="side-menu_icon">
                                    <div className="side-menu__icon ">
                                        {/* <Icon icon="ant-design:shop-outlined" /> */}
                                        <img src={iuserid} alt="" />
                                    </div>
                                </div>
                                <span className="side-menu__label">นักท่องเที่ยว</span>
                            </a>
                            <ul className="slide-menu">
                                <li>
                                    <NavLink
                                        to={`/tourist`}
                                        className="slide-item"
                                    >
                                        รายชื่อนักท่องเที่ยว
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li
                            className={`slide ${expanded == 5 ? 'is-expanded' : ''} `}
                            onClick={(e) => {
                                expanded != 5 ? setExpanded(5) : setExpanded('')
                            }}
                        >
                            <a className="side-menu__item py-2" data-toggle="slide" href="#">
                                <div className="side-menu_icon">
                                    <div className="side-menu__icon ">
                                        <Icon icon="ant-design:shop-outlined" />
                                    </div>
                                </div>
                                <span className="side-menu__label">ร้านค้า/สถานที่</span>
                            </a>
                            <ul className="slide-menu">
                                <li>
                                    <NavLink
                                        to={`/manage/shop/list`}
                                        className="slide-item"
                                    >
                                        จัดการร้านค้า/สถานที่
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li
                            className={`slide ${expanded == 6 ? 'is-expanded' : ''} `}
                            onClick={(e) => {
                                expanded != 6 ? setExpanded(6) : setExpanded('')
                            }}
                        >
                            <a className="side-menu__item py-2" data-toggle="slide" href="#">
                                <div className="side-menu_icon">
                                    <div className="side-menu__icon ">
                                        <Icon icon="majesticons:presentation-line" />
                                    </div>
                                </div>
                                <span className="side-menu__label">แคมเปญ</span>
                            </a>
                            <ul className="slide-menu">
                                <li>
                                    <NavLink
                                        to={`campaign`}
                                        className="slide-item"
                                    >
                                        จัดการแคมเปญ
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`coupon`}
                                        className="slide-item"
                                    >
                                        จัดการคูปอง
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <div className="text-sm text-[#4C4E6461] py-3 mb-[10px]">--ตั้งค่า</div>


                        <li
                            className={`slide ${expanded == 7 ? 'is-expanded' : ''} `}
                            onClick={(e) => {
                                expanded != 7 ? setExpanded(7) : setExpanded('')
                            }}
                        >
                            <a className="side-menu__item py-2" data-toggle="slide" href="#">
                                <div className="side-menu_icon">
                                    <div className="side-menu__icon ">
                                        <Icon icon="majesticons:settings-cog-line" />
                                    </div>
                                </div>
                                <span className="side-menu__label">ตั้งค่า</span>
                            </a>
                            <ul className="slide-menu">
                                <li>
                                    <NavLink
                                        to={`/setting/banner`}
                                        className="slide-item"
                                    >
                                        ตั้งค่าแบนเนอร์
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/setting/survey`}
                                        className="slide-item"
                                    >
                                        ตั้งค่าแบบสำรวจ
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/setting/users`}
                                        className="slide-item"
                                    >
                                        ตั้งค่าผู้ใช้งาน
                                    </NavLink>
                                </li>
                            </ul>
                        </li>




                    </ul>
                </div>
            </aside>
        </div>
    );
}