import * as nationalityAction from "../../slices/master/nationality.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";

import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";

export const getNationality = () => {
    return async (dispatch) => {
      dispatch(nationalityAction.fetchData());
  
      try {
        const config = {
          method: "get",
          url: `${endPoint.NATIONALITY_URL}/list`,
          headers: setHeaders(),
        };
  
        const result = await http(config);
        dispatch(nationalityAction.fetchDataSuccess(result.data));
        
      } catch (error) {
        if(error.response.statusText == "Unauthorized"){
          handleUnauthorized()
        }
        dispatch(nationalityAction.fetchDataFailure());
      }
    };
  };