import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'tailwind.css';
import { useDispatch } from 'react-redux';


import {login} from "../../redux/handlers/master/auth";

export function Login() {

  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const [inputtype, setInputtype] = useState("password");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    remember: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleLogin = (values, { setSubmitting, setErrors }) => {
    dispatch(login(values.username, values.password))
      .then((result) => {
        if (result?.data.message == 'success') {
         
          let token = result?.data.data.access_token?result?.data.data.access_token:''
          localStorage.setItem('token', token);
          navigate('/'); 
        } else {
          alert('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
        }
        setSubmitting(false);
      })
      .catch(() => {
        alert('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
        setSubmitting(false);
      });
  };


  return (
    <div className="loginpage p-0 bg-white"
    >
      <Row className="g-0 md:w-full" >
        <Col lg={7} xl={8} 
        className="d-none d-lg-flex justify-content-center flex-column align-items-center  h-100vh"
        >
          <Image
            src="/images/cover_login2.jpg"
            className="w-full"
            style={{ height: "100vh", objectFit: "cover" }}
          />
        </Col>
        <Col lg={5} xl={4} className="d-flex justify-content-center align-items-center h-100vh">
          <Card className="box-login border-0 ">
            <Card.Body className="">
              <div className="text-center">
                <Image src="/images/logo.png" width={150} className="mb-7 mx-auto" />
              </div>
              <div className="text-center text-[18px]  mb-7 font-semibold mb-3 tc-1 text-[#061126]">เข้าสู่ระบบ</div>

              <Formik
                initialValues={formData}
                validationSchema={schema}
                onSubmit={handleLogin}
              >
                {({
                  handleSubmit,
                  handleChange,
                  touched,
                  isValid,
                  errors,
                  values,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="อีเมลหรือเบอร์โทรศัพท์"
                      onChange={handleChange}
                      isValid={touched.username && !errors.username}
                      isInvalid={!!errors.username}
                      className="form-st-1 mb-3"
                    />
                    <div className="position-relative">
                      <Form.Control
                        type={inputtype}
                        name="password"
                        placeholder="รหัสผ่าน"
                        onChange={handleChange}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                        className="form-st-1 mb-3"
                      />
                      <Button
                        type="button"
                        className="btn btn-change-type-password"
                        onClick={() =>
                          inputtype == "password"
                            ? setInputtype("text")
                            : setInputtype("password")
                        }
                      ></Button>
                    </div>
                    <div className="d-flex justify-content-between my-4">
                      <Form.Check 
                        type="checkbox"
                        id="remember"
                        label="จำรหัสผ่าน"
                        defaultChecked={true}
                      />

                      <Link
                        to="/reset/password"
                        variant="link"
                        className="btn  underline text-[#2F4858]"
                      >
                        ลืมรหัสผ่าน ?
                      </Link>
                    </div>

                    <Button
                      type="submit"
                      className="btn-login text-lg font-normal w-100 mb-4 bg-[#31A7DE] border-0"
                    >
                      เข้าสู่ระบบ
                    </Button>
                      
                     
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
