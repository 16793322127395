import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import React, { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { campaign } from "mocks/shop";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DocumentTextIcon, C1Icon, C2Icon, C3Icon } from "components/fragments/Icon";
import dayjs from 'dayjs';

import { Link, useParams, NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getCampaignByShopId, getCampaignEntierOverview, getCampaignDueOverview } from "../../../../redux/handlers/master/campaign";

export function DashboardShop() {
    let { shopID } = useParams();
    const dispatch = useAppDispatch();
    const [date, setDate] = useState([dayjs(new Date()), dayjs(new Date())]);
    const campaignReducer = useAppSelector((state) => state.campaignReducer);
    const [campaignShopList, setCampaignShopList] = useState([])
    const [campaignEntierOverview, setCampaignEntierOverview] = useState({})
    const [campaignDueOverview, setCampaignDueOverview] = useState({})
    const [filterCampaignId, setFilterCampaignId] = useState('all')
    const filterEntireOverview = (campaignId) => {
        setFilterCampaignId(campaignId)
        dispatch(getCampaignEntierOverview(shopID, campaignId));
    }
    useEffect(() => {
        console.log(dayjs(date[0]).format('YYYY-MM-DD'), dayjs(date[1]).format('YYYY-MM-DD'))

        dispatch(getCampaignDueOverview(shopID, dayjs(date[0]).format('YYYY-MM-DD'), dayjs(date[1]).format('YYYY-MM-DD')));
    }, [date])

    useEffect(() => {
        dispatch(getCampaignByShopId(shopID));
        dispatch(getCampaignEntierOverview(shopID));
        dispatch(getCampaignDueOverview(shopID, dayjs(date[0]).format('YYYY-MM-DD'), dayjs(date[1]).format('YYYY-MM-DD')));
    }, [shopID])

    useEffect(() => {
        if (campaignReducer.campaignShopData) {
            const data = campaignReducer.campaignShopData.data;
            setCampaignShopList(data);
        }
    }, [campaignReducer.campaignShopData]);

    useEffect(() => {
        if (campaignReducer.campaignEntierOverviewData) {
            const data = campaignReducer.campaignEntierOverviewData.data;
            setCampaignEntierOverview(data);
        }
    }, [campaignReducer.campaignEntierOverviewData]);

    useEffect(() => {
        if (campaignReducer.campaignDueOverviewData) {
            const data = campaignReducer.campaignDueOverviewData.data;
            setCampaignDueOverview(data);
        }
    }, [campaignReducer.campaignDueOverviewData]);

    return (
        <>

            <div className="bg-white  rounded-[10px] drop-shadow py-7 mb-4 px-[25px]">
                <div className="flex items-start mb-7">
                    <div className="text-[#4C4E64]/[0.87]  text-xl mr-auto">ภาพรวมทั้งหมด</div>
                    <div className="w-[200px]">
                        <TextField
                            label="เลือกแคมเปญ"
                            select
                            name='campaign_id'
                            placeholder=""
                            onChange={(e) => filterEntireOverview(e.target.value)}
                            value={filterCampaignId}
                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                            sx={{
                                '& .MuiSelect-select span::before': {
                                    content: "''",
                                    color: '#b3b3b3'
                                },
                            }}
                        >
                            <MenuItem key={'all'} value={'all'}>
                                ทั้งหมด
                            </MenuItem>
                            {campaignShopList.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
                <div className="flex justify-center mb-10">
                    <div className="grid grid-cols-6 gap-3  mb-3 max-w-[700px]">
                        <div className="col-span-2">
                            <div className="rounded-lg px-[15px] py-[25px] bg-[#265797] text-white text-right text-base">
                                <div className="mb-4">จำนวนคูปองที่แจกไปทั้งหมด</div>
                                <div><span className="text-2xl mr-2">{campaignEntierOverview?.totalCouponsDistributed}</span> สิทธิ์</div>
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="rounded-lg px-[15px] py-[25px] bg-[#31A7DE] text-white text-right text-base">
                                <div className="mb-4">จำนวนผู้ใช้คูปองทั้งหมด</div>
                                <div><span className="text-2xl mr-2">{campaignEntierOverview?.totalCouponUsers}</span> คน</div>
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="rounded-lg px-[15px] py-[25px] bg-[#EF881C] text-white text-right text-base">
                                <div className="mb-4">จำนวนคูปองทั้งหมดที่ใช้</div>
                                <div><span className="text-2xl mr-2">{campaignEntierOverview?.totalCouponsUsed}</span> สิทธิ์</div>
                            </div>
                        </div>
                        <div className="col-span-6">
                            <div className="rounded-lg px-[15px] py-[25px] bg-[#DC148E] text-white text-center text-base">
                                <div className="mb-4">มูลค่าคูปองทั้งหมด</div>
                                <div><span className="text-2xl mr-2">{campaignEntierOverview?.totalCouponValue}</span> บาท</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-start mb-4">
                    <div className="text-[#4C4E64]/[0.87]  text-xl mr-auto">ภาพรวมตามวันที่</div>
                    <div className="w-[300px]">
                        <div className="formdaterange">
                            <label className="mb-1">เลือกช่วงวันที่</label>
                            <DateRangePicker
                                onChange={(value) => {
                                    setDate(value)
                                }}
                                value={date}
                                calendarIcon={<CalendarMonthIcon className="text-[#31A7DE]" />}
                                className={`daterang-lg w-full  `}
                                name="date"
                                clearIcon={null} // This will remove the clear icon
                            />
                        </div>
                    </div>
                </div>
                <div className="max-w-[500px] mx-auto">
                    <div className="mt-4 mb-2">
                        <div className="text-left">
                            {dayjs(date[0]).format('DD/MM/YYYY') + ' - ' + dayjs(date[1]).format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div>
                        <div className="rounded-lg px-3 py-3 border-[1px] border-[#F5F5F5]  ">
                            <div className="grid grid-cols-12 gap-3 mb-1 ">
                                <div className="col-span-7 text-left">จำนวนผู้มาใช้คูปอง</div>
                                <div className="col-span-3 text-right">{campaignDueOverview?.couponUsersCount}</div>
                                <div className="col-span-2  text-right">คน</div>
                            </div>
                            <div className="grid grid-cols-12 gap-3 mb-1 ">
                                <div className="col-span-7 text-left">จำนวนคูปองที่ถูกใช้งาน</div>
                                <div className="col-span-3 text-right">{campaignDueOverview?.couponsUsedCount}</div>
                                <div className="col-span-2  text-right">คูปอง</div>
                            </div>
                            <div className="grid grid-cols-12 gap-3  mb-2 ">
                                <div className="col-span-7 text-left">มูลค่าคูปอง</div>
                                <div className="col-span-3 text-right">{campaignDueOverview?.couponValue}</div>
                                <div className="col-span-2  text-right">บาท</div>
                            </div>
                            <div className="grid grid-cols-12 gap-3  ">
                                <div className="col-span-4 text-left text-[#31A7DE]"><C1Icon /><span className="ml-2">{campaignDueOverview?.couponUsersCount} คน</span></div>
                                <div className="col-span-4 text-left text-[#EF881C]"><C2Icon /><span className="ml-2">{campaignDueOverview?.couponsUsedCount} คูปอง</span></div>
                                <div className="col-span-4 text-right text-[#265797]"><C3Icon /><span className="ml-2">{campaignDueOverview?.couponValue}  บาท</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}