import * as authAction from '../../slices/master/auth.slice';
import { http } from '../../../utils/http';
import { endPoint } from '../../../constants/server/api';

export const login = (username, password) => {
  return async (dispatch) => {
    dispatch(authAction.fetchData());
    try {
      const config = {
        method: 'post',
        url: `${endPoint.AUTH_URL}/login`,
        data: {
          username: username,
          password: password,
        },
      };

      const result = await http(config);
      if (result) {
        dispatch(authAction.fetchDataSuccess(result));
        return result;
      } else {
        dispatch(authAction.fetchDataFailure());
        return null;
      }
    } catch (error) {
      dispatch(authAction.fetchDataFailure());
      return null;
    }
  };
};
