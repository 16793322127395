import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Chip from '@mui/material/Chip';
import ic_edit from "assets/svg/ic_edit.svg";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import Switch from '@mui/material/Switch';
import { InvPrint } from "components/pages/report/invoice/printinvoice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as yup from "yup";
import Button from '@mui/material/Button';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useReactToPrint } from "react-to-print";

import DeleteIcon from '@mui/icons-material/Delete';
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";

import { ModalConfirm } from "components/layouts/ModalConfirm";
import { ModalDeleteSuccess } from 'components/layouts/ModalDeleteSuccess';
import { ModalSuccess } from "components/layouts/ModalSuccess";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store/configureStore";
import { updateInvoiceById, updateInvoicePayment, getInvoiceById, deleteInvoiceRecordById } from "../../../../../redux/handlers/master/campaignShopInvoice";
import { getPaymentMethodList } from "../../../../../redux/handlers/master/paymentMethod";

export const Document = ({ invoice }) => {
    const dispatch = useAppDispatch();
    const paymentMethodReducer = useAppSelector((state) => state.paymentMethodReducer);
    const [openSaveSuccess, setOpenSaveSuccess] = React.useState(false);
    const [openDelModal, setOpenDelModal] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const [checked, setChecked] = React.useState(true);
    const [formInvoice, setFormInvoice] = useState({
        issued_at: '',
        document_subject: '',
        remark: '',
    });
    const schemaInvoice = yup.object().shape({})
    const schema = yup.object().shape({
        payment_detail: yup.array()
            .of(
                yup.object().shape({
                    payment_channel: yup.number().required('Required'),
                    amount_paid: yup.number().required('Required'),
                    payment_date: yup.date().required('Required'),
                })
            )
            .required()
    })
    const [formdisabled, setFormdisabled] = useState(true);
    const [formEditIndex, setFormEditIndex] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [form, setForm] = useState(null);
    const paymentinitial = [
        {
            record_id: null,
            payment_time: 1,
            payment_detail: [{
                id: null,
                payment_channel: '',
                amount_paid: '',
                residual_value: '',
                payment_date: '',
                note: '',
            }],
            edit: true,
            log_text: `ชำระเงินครั้งที่ 1`
        },
    ]
    const handleSubmitFormInvoice = (values) => {
        let formData = {
            issued_at: new Date(values.issued_at),
            document_subject: values.document_subject,
            remark: values.remark,
        }

        dispatch(updateInvoiceById(formData, invoice.id)).then((res) => {
            dispatch(getInvoiceById(invoice.id))
            setFormdisabled(true)
            setOpenSaveSuccess(true)
        }).catch((error) => {
            console.error('Error updateInvoiceById', error);
        })
    }
    const handleSubmitFormPayment = (values) => {
        let formData = {
            campaign_shop_invoice_id: invoice.id,
            campaign_shop_invoice_payment_record_id: values.record_id,
            campaign_shop_invoice_payment_method: [],
            log_text: values.log_text
        }

        for (const item of values.payment_detail) {
            formData.campaign_shop_invoice_payment_method.push({
                payment_method_id: item.payment_channel,
                payment_total: item.amount_paid,
                payment_date: `${dayjs(item.payment_date).format('YYYY-MM-DD')}T00:00:00Z`,
                remark: item.note,
            })
        }

        dispatch(updateInvoicePayment(formData)).then((res) => {
            dispatch(getInvoiceById(invoice.id))
            setOpenSaveSuccess(true)
        }).catch((error) => {
            console.error('Error updateInvoicePayment', error);
        })
    }
    // const handleChangePaymentTotal = () => {
    //     console.log(form)
    // }
    const deleteInvoiceRecord = () => {
        dispatch(deleteInvoiceRecordById(deleteData.id, deleteData))
            .then((response) => {
                if (response.message === "success") {
                    dispatch(getInvoiceById(invoice.id));
                    setDeleteData(null)
                    setOpenConfirm(false)
                    setOpenDelModal(true)
                }
            })
            .catch((error) => {
                console.error('Error delete coupon', error);
            })
    }

    useEffect(() => {
        dispatch(getPaymentMethodList())
    }, []);

    useEffect(() => {
        if (paymentMethodReducer.data) {
            let data = []

            for (const item of paymentMethodReducer.data.data) {
                data.push({
                    value: item.id,
                    label: item.name,
                })
            }

            setPaymentMethod(data)
        }
    }, [paymentMethodReducer.data]);

    useEffect(() => {
        if (invoice) {
            setFormInvoice({
                issued_at: invoice.issued_at,
                document_subject: invoice.document_subject,
                remark: invoice.remark,
            })

            // set default payment form
            setForm({
                "payments": paymentinitial
            })
            setFormEditIndex(null)

            if (invoice.campaignShopInvoicePaymentRecord) {
                let remainTotal = Number(invoice.sub_total)
                let data = []
                let index = 1

                for (const campaignShopInvoicePaymentRecord of invoice.campaignShopInvoicePaymentRecord) {
                    let record = {
                        record_id: campaignShopInvoicePaymentRecord.id,
                        payment_time: index,
                        payment_detail: [],
                        edit: false,
                        log_text: `แก้ไขการชำระเงินครั้งที่ ${index}`
                    }

                    for (const campaignShopInvoicePaymentMethod of campaignShopInvoicePaymentRecord.campaignShopInvoicePaymentMethod) {
                        remainTotal = Number(remainTotal) - Number(campaignShopInvoicePaymentMethod.payment_total)
                        record.payment_detail.push({
                            id: campaignShopInvoicePaymentMethod.id,
                            payment_channel: campaignShopInvoicePaymentMethod.payment_method_id,
                            amount_paid: campaignShopInvoicePaymentMethod.payment_total,
                            residual_value: remainTotal,
                            payment_date: campaignShopInvoicePaymentMethod.payment_date,
                            note: campaignShopInvoicePaymentMethod.remark
                        })
                    }

                    data.push(record)
                    index++
                }

                // add next payment
                if (remainTotal > 0) {
                    data.push({
                        record_id: null,
                        payment_time: index,
                        payment_detail: [{
                            id: null,
                            payment_channel: '',
                            amount_paid: '',
                            residual_value: '',
                            payment_date: '',
                            note: '',
                        }],
                        edit: true,
                        log_text: `ชำระเงินครั้งที่ ${index}`
                    })
                    setFormEditIndex(data.length - 1)
                }

                setForm({
                    "payments": data
                })
            } else {
                setFormEditIndex(paymentinitial.length - 1)
            }
        }
    }, [invoice]);

    return (
        <>
            {/* Form invoice data */}
            <div className="px-2">
                <div className="bg-white rounded-[7px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[10px] mb-7">
                    <div className="px-[25px]">

                        <Formik
                            enableReinitialize
                            initialValues={formInvoice}
                            validationSchema={schemaInvoice}
                            onSubmit={handleSubmitFormInvoice}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                touched,
                                errors,
                                values,
                                setFieldValue,
                                setValues,
                                isInvalid,
                                isValid,
                                isSubmitting
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className=" flex items-center">
                                        <div className='mr-auto'>
                                            <fieldset disabled={formdisabled}>
                                                <div>
                                                    <div className="mb-3" >
                                                        <div className="mt-3">
                                                            <div className='py-2'>
                                                                <Stack direction="row" spacing={2} sx={{
                                                                    alignItems: "center",
                                                                }}>
                                                                    <div>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DatePicker
                                                                                label="วันที่ออกเอกสาร"
                                                                                className='w-[200px]'
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        InputProps: {
                                                                                            sx: { borderRadius: '10px', },
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                format="DD/MM/YYYY"
                                                                                onChange={(newValue) => {
                                                                                    setFieldValue('issued_at', newValue)
                                                                                }}
                                                                                value={dayjs(values.issued_at) ?? ''}
                                                                            />
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                    <div>
                                                                        <TextField
                                                                            id=""
                                                                            label="รายการ"
                                                                            className='w-[400px] disabled:!bg-[#000]'
                                                                            InputProps={{ sx: { borderRadius: '10px' } }}
                                                                            onChange={(event, value) => {
                                                                                setFieldValue('document_subject', event.target.value)
                                                                            }}
                                                                            value={values.document_subject ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <TextField
                                                                            id=""
                                                                            label="หมายเหตุ"
                                                                            className='w-[300px]'
                                                                            InputProps={{ sx: { borderRadius: '10px' } }}
                                                                            onChange={(event, value) => {
                                                                                setFieldValue('remark', event.target.value)
                                                                            }}
                                                                            value={values.remark ?? ''}
                                                                        />
                                                                    </div>
                                                                </Stack>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        {
                                            formdisabled ? (
                                                <Button
                                                    variant="outlined"
                                                    className="w-[111px] disabled:bg-[#999] disabled:border-[#999] border-[#31A7DE] bg-[#31A7DE] text-[#FFF] rounded-[8px] text-sm mr-3  h-[44px]"
                                                    disabled={!invoice?.is_status}
                                                    onClick={() => {
                                                        setFormdisabled(false)
                                                    }}
                                                >
                                                    แก้ไข
                                                </Button>
                                            ) : (
                                                <>
                                                    <div className='text-end '>
                                                        <Button
                                                            variant="outlined"
                                                            className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                                            onClick={() => {
                                                                setFormdisabled(true)
                                                            }}
                                                        >
                                                            ยกเลิก
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                                            type="submit"

                                                        >
                                                            บันทึก
                                                        </Button>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className="mb-4" style={{ zoom: '140%' }}>
                <InvPrint invoice={invoice} />
            </div>
            {/* Form payment data */}
            <div className="bg-white rounded-[7px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[10px] mb-7">
                <div className="px-[15px] py-[10px] ">
                    <div className="d-flex align-items-center  mb-4 flex-wrap">
                        <div className="text-[#31A7DE] font-bold ml-2" >
                            ตัวเลือกชำระเงิน
                            <Switch
                                checked={checked}
                                onChange={(event) => {
                                    setChecked(event.target.checked);
                                }}
                            />
                        </div>
                    </div>
                    <div className="px-2">
                        {
                            checked ? (
                                <>
                                    {form && form.payments && form.payments.map((item, index) => (
                                        <Formik
                                            enableReinitialize
                                            initialValues={form.payments[index]}
                                            validationSchema={schema}
                                            onSubmit={handleSubmitFormPayment}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                touched,
                                                errors,
                                                values,
                                                setFieldValue,
                                                setValues,
                                                isInvalid,
                                                isValid,
                                                isSubmitting
                                            }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <div className="mb-7">
                                                        <div>
                                                            <div className="mb-7" key={index}>
                                                                <div className="text-[#242F48] font-bold mb-2">ชำระเงินครั้งที่ {index + 1}</div>
                                                                <hr />
                                                                <div className="flex items-center mt-5 mb-2">
                                                                    <CheckCircleIcon className="text-[#31A7DE]" />
                                                                    <span className="text-[#242F48] font-medium ml-2 mr-4">ช่องทางชำระเงิน</span>
                                                                    {
                                                                        formEditIndex != index ? (
                                                                            <IconButton className="w-[30px] mr-3 h-[30px] p-1" style={{
                                                                                background: '#E3E8F0',
                                                                            }}
                                                                                disabled={!invoice?.is_status}
                                                                                onClick={() => {
                                                                                    setFormEditIndex(index)
                                                                                }}
                                                                            >
                                                                                <img src={ic_edit} alt="" />
                                                                            </IconButton>
                                                                        ) : ''
                                                                    }
                                                                    {item.record_id &&
                                                                        <IconButton onClick={() => {
                                                                            let data = {
                                                                                id: item.record_id,
                                                                                campaign_shop_invoice_id: invoice.id,
                                                                                log_text: `ลบการชำระเงินครั้งที่ ${index + 1}`
                                                                            } 

                                                                            setDeleteData(data)
                                                                            setOpenConfirm(true)
                                                                        }} className="w-[30px] mr-3 h-[30px] p-1" style={{
                                                                            background: '#F95057',
                                                                        }} >
                                                                            <img src={ic_trash} alt="" />
                                                                        </IconButton>
                                                                    }
                                                                </div>
                                                                <fieldset disabled={formEditIndex != index}>
                                                                    <FieldArray
                                                                        name={`payment_detail`}
                                                                        render={arrayHelpers => (
                                                                            <>
                                                                                {values.payment_detail && values.payment_detail.map((item2, index2) => (
                                                                                    <div className="mt-3">
                                                                                        <div className='py-2'>
                                                                                            <Stack direction="row" spacing={2} sx={{
                                                                                                alignItems: "center",
                                                                                            }}>
                                                                                                <div>{index2 + 1}</div>
                                                                                                <div>
                                                                                                    <TextField
                                                                                                        id=""
                                                                                                        name="payment_channel"
                                                                                                        select
                                                                                                        label="จ่ายเงินโดย"
                                                                                                        className='w-[300px]'
                                                                                                        InputProps={{ sx: { borderRadius: '10px' } }}
                                                                                                        value={values.payment_detail[index2].payment_channel ?? ''}
                                                                                                        onChange={(e) => {
                                                                                                            setFieldValue(`payment_detail[${index2}].payment_channel`, e.target.value)

                                                                                                        }}
                                                                                                        error={errors.payment_detail && errors.payment_detail[index2] && !!errors.payment_detail[index2].payment_channel}
                                                                                                        disabled={formEditIndex != index || !invoice?.is_status}
                                                                                                    >
                                                                                                        {paymentMethod.map((option) => (
                                                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                                                {option.label}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </TextField>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <CurrencyTextField
                                                                                                        label="จำนวนเงินที่ชำระ"
                                                                                                        name="amount_paid"
                                                                                                        variant="outlined"
                                                                                                        value={values.payment_detail[index2].amount_paid ?? ''}
                                                                                                        outputFormat="string"
                                                                                                        currencySymbol=""
                                                                                                        decimalCharacter="."
                                                                                                        digitGroupSeparator=","
                                                                                                        InputProps={{ sx: { borderRadius: '10px' } }}
                                                                                                        className={`border-[#000] w-[200px] !rounded-[10px]  `}
                                                                                                        onChange={(event, value) => {
                                                                                                            setFieldValue(`payment_detail[${index2}].amount_paid`, value)
                                                                                                        }}
                                                                                                        // onBlur={() => { handleChangePaymentTotal() }}
                                                                                                        error={errors.payment_detail && errors.payment_detail[index2] && !!errors.payment_detail[index2].amount_paid}
                                                                                                        disabled={!invoice?.is_status}
                                                                                                    />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <CurrencyTextField
                                                                                                        label="มูลค่าคงเหลือ"
                                                                                                        variant="outlined"
                                                                                                        value={values.payment_detail[index2].residual_value ?? ''}
                                                                                                        outputFormat="string"
                                                                                                        currencySymbol=""
                                                                                                        decimalCharacter="."
                                                                                                        digitGroupSeparator=","
                                                                                                        InputProps={{ sx: { borderRadius: '10px' } }}
                                                                                                        className={`border-[#000] w-[200px] !rounded-[10px]  `}
                                                                                                        onChange={(event, value) => {
                                                                                                            setFieldValue(`payment_detail[${index2}].residual_value`, value)
                                                                                                        }}
                                                                                                        disabled={formEditIndex == index || !invoice?.is_status}
                                                                                                    />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                                        <DatePicker
                                                                                                            label="วันที่ชำระเงิน"
                                                                                                            name="payment_date"
                                                                                                            className='w-[200px]'
                                                                                                            slotProps={{
                                                                                                                textField: {
                                                                                                                    InputProps: {
                                                                                                                        sx: { borderRadius: '10px', },
                                                                                                                    },
                                                                                                                },
                                                                                                            }}
                                                                                                            format="DD/MM/YYYY"
                                                                                                            onChange={(newValue) => {
                                                                                                                setFieldValue(`payment_detail[${index2}].payment_date`, newValue)
                                                                                                            }}
                                                                                                            value={dayjs(values.payment_detail[index2].payment_date) ?? ''}
                                                                                                            error={errors.payment_detail && errors.payment_detail[index2] && !!errors.payment_detail[index2].payment_date}
                                                                                                            disabled={!invoice?.is_status}
                                                                                                        />
                                                                                                    </LocalizationProvider>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <TextField
                                                                                                        id=""
                                                                                                        label="หมายเหตุ"
                                                                                                        className='w-[300px]'
                                                                                                        InputProps={{ sx: { borderRadius: '10px' } }}
                                                                                                        onChange={(event, value) => {
                                                                                                            setFieldValue(`payment_detail[${index2}].note`, event.target.value)
                                                                                                        }}
                                                                                                        value={values.payment_detail[index2].note ?? ''}
                                                                                                        disabled={!invoice?.is_status}
                                                                                                    />
                                                                                                </div>
                                                                                                {
                                                                                                    index2 > 0 ? (
                                                                                                        <IconButton
                                                                                                            className='!bg-[#F95057]'
                                                                                                            aria-label="delete"
                                                                                                            onClick={() => {
                                                                                                                arrayHelpers.remove(index2)
                                                                                                            }}
                                                                                                        >
                                                                                                            <DeleteIcon className='text-[#FFF]' />
                                                                                                        </IconButton>
                                                                                                    ) : ''
                                                                                                }
                                                                                            </Stack>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                                {
                                                                                    formEditIndex == index ? (
                                                                                        <div className='my-3'>
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                className="w-[180px] bg-[#FF9900] disabled:bg-[#999] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                                                                                disabled={!invoice?.is_status}
                                                                                                onClick={() => arrayHelpers.push({
                                                                                                    "id": "",
                                                                                                    "payment_channel": "",
                                                                                                    "amount_paid": "",
                                                                                                    "residual_value": "",
                                                                                                    "payment_date": '',
                                                                                                    "note": ''
                                                                                                })}
                                                                                            >
                                                                                                + {" "} {" "} เพิ่มช่องทางชำระเงิน
                                                                                            </Button>
                                                                                        </div>
                                                                                    ) : ''
                                                                                }
                                                                            </>
                                                                        )}
                                                                    />
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                        {
                                                            formEditIndex == index ? (
                                                                <div className='text-end mt-[40px]'>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className="w-[111px] disabled:bg-[#999] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                                                        onClick={() => {
                                                                            if (invoice?.total_remain > 0) {
                                                                                setFormEditIndex(form.payments.length - 1)
                                                                            } else {
                                                                                setFormEditIndex(null)
                                                                            }
                                                                        }}
                                                                        disabled={!invoice?.is_status}
                                                                    >
                                                                        ยกเลิก
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        className="w-[111px] disabled:bg-[#999] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                                                        type="submit"
                                                                        disabled={!invoice?.is_status}
                                                                    >
                                                                        บันทึก
                                                                    </Button>
                                                                </div>
                                                            ) : ''
                                                        }
                                                        <hr className='mb-4 mt-7' />
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    ))}
                                    <div className='flex justify-end mb-4'>
                                        <div className='mr-5 pt-2'>ชำระเงินรวม : {invoice?.total_paid} บาท</div>
                                        <div className='rounded-lg bg-[#31A7DE] text-[#FFF] flex items-center p-4'>
                                            <div className='mr-5'>มูลค่าสุทธิรวม</div>
                                            <div><span className='text-3xl font-bold'>{invoice?.sub_total}</span> บาท</div>
                                        </div>
                                    </div>
                                    <div className='text-right'>ต้องชำระเงินอีก {invoice?.total_remain} บาท</div>
                                </>
                            ) : ''
                        }
                    </div>
                </div>
            </div>
            {/* Modal alert */}
            <ModalSuccess
                open={openSaveSuccess}
                setOpen={setOpenSaveSuccess}
                message={"บันทึกข้อมูลสำเร็จ"}
            />
            <ModalDeleteSuccess open={openDelModal} setOpen={setOpenDelModal} />
            <ModalConfirm
                open={openConfirm}
                setOpen={setOpenConfirm}
                message={"คุณต้องการลบข้อมูลนี้ใช่หรือไม่"}
                confirm={() => deleteInvoiceRecord()}
            />
        </>
    )
}