import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import React, { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Icon } from '@iconify/react';

import ipiechart from "assets/svg/PieChart.svg";
import icard from "assets/svg/card.svg";
import ihistory from "assets/svg/History.svg";
import ishop2 from "assets/svg/Shop2.svg";
import itag from "assets/svg/tag.svg";
import iusercircle from "assets/svg/UserCircle.svg";
import iuserid from "assets/svg/UserId.svg";
import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { prefix, date, months, years, gender, nationality, contact, contactinitial } from "mocks/shop";
import { ModalSuccess } from "components/layouts/ModalSuccess";

import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { createShopBookBank, updateShopBookBank, getShopBookBankByShopId } from "../../../../redux/handlers/master/shopBookBank";
import { getAccountTypeList } from "../../../../redux/handlers/master/accountType";
import { getBankList } from "../../../../redux/handlers/master/bank";
import { getPaymentMethodList } from "../../../../redux/handlers/master/paymentMethod";
import { getTaxBranchList } from "../../../../redux/handlers/master/taxBranch";
import { getVatTypeList } from "../../../../redux/handlers/master/vatType";

export function BankAccount() {
    let { shopID } = useParams();
    const dispatch = useAppDispatch();
    const [openModalSuccess, setOpenModalSuccess] = useState(false)
    const shopBookBankReducer = useAppSelector((state) => state.shopBookBankReducer);

    const [accountTypeList, setAccountTypeList] = useState([])
    const [bankList, setBankList] = useState([])
    const [taxBranchList, setTaxBranchList] = useState([])
    const [vatTypeList, setVatTypeList] = useState([])

    const accountTypeReducer = useAppSelector((state) => state.accountTypeReducer);
    const bankReducer = useAppSelector((state) => state.bankReducer);
    const taxBranchReducer = useAppSelector((state) => state.taxBranchReducer);
    const vatTypeReducer = useAppSelector((state) => state.vatTypeReducer);


    const schema = yup.object().shape({
        account_name: yup.string().required(),
        account_number: yup.string().required(),
        bank_id: yup.string().required(),
        branch_name: yup.string().required(),
        account_type_id: yup.string().required(),
    });

    const [formdata, setFormdata] = useState(null)
    const initialFormValues = formdata || {
        id: null,
        shop_id: shopID,
        account_name: null,
        account_number: null,
        bank_id: null,
        branch_name: null,
        account_type_id: null,
        tax_number: null,
        vat_type_id: null,
        tax_branch_id: "สำนักงานใหญ่",
        branch_vat_code: null,
    };
    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = {
                shop_id: values.shop_id,
                account_name: values.account_name,
                account_number: values.account_number,
                bank_id: values.bank_id,
                branch_name: values.branch_name,
                account_type_id: values.account_type_id,
                tax_number: values.tax_number,
                vat_type_id: values.vat_type_id,
                tax_branch_id: values.tax_branch_id,
                branch_vat_code: values.branch_vat_code,
            };

            if (formdata && formdata.id) {
                dispatch(updateShopBookBank(formData, formdata.id))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenModalSuccess(true)
                        }
                    })
                    .catch((error) => {
                        console.error('Error update shop book bank', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                dispatch(createShopBookBank(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            // alert('save success')
                            setOpenModalSuccess(true)
                            dispatch(getShopBookBankByShopId(shopID));
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating shop book bank', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        } catch (error) {
            console.error('Error submitting the form', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        dispatch(getAccountTypeList());
        dispatch(getBankList());
        dispatch(getTaxBranchList());
        dispatch(getVatTypeList());
        dispatch(getShopBookBankByShopId(shopID));
    }, [shopID])

    useEffect(() => {
        if (accountTypeReducer.data) {
            const data = accountTypeReducer.data.data;
            setAccountTypeList(data)
        }
    }, [accountTypeReducer])

    useEffect(() => {
        if (bankReducer.data) {
            const data = bankReducer.data.data;
            setBankList(data)
        }
    }, [bankReducer])

    useEffect(() => {
        if (taxBranchReducer.data) {
            const data = taxBranchReducer.data.data;
            setTaxBranchList(data)
        }
    }, [taxBranchReducer])

    useEffect(() => {
        if (vatTypeReducer.data) {
            const data = vatTypeReducer.data.data;
            setVatTypeList(data)
        }
    }, [vatTypeReducer])

    useEffect(() => {
        if (shopBookBankReducer.data) {
            const data = shopBookBankReducer.data.data;
            setFormdata(data)
        }
    }, [shopBookBankReducer])

    return (
        <>
            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px]">
                <div className="text-[#31A7DE] font-bold text-lg mb-5">บัญชีธนาคาร</div>
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit} className="max-w-[800px]">
                            <div className="grid grid-cols-12 gap-7 mb-6 " >

                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        select
                                        label="ประเภทบัญชี"
                                        name='account_type_id'
                                        placeholder=""
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.account_type_id ?? ''}
                                        error={!!errors.account_type_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        sx={{
                                            '& .MuiSelect-select span::before': {
                                                content: "''",
                                                color: '#b3b3b3'
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {accountTypeList.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        select
                                        label="ธนาคาร"
                                        name='bank_id'
                                        placeholder=""
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bank_id ?? ''}
                                        error={!!errors.bank_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        sx={{
                                            '& .MuiSelect-select span::before': {
                                                content: "''",
                                                color: '#b3b3b3'
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {bankList.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="หมายเลขบัญชี"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        value={values.account_number ?? ''}
                                        name="account_number"
                                        onChange={handleChange}
                                        error={!!errors.account_number}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>


                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="สาขา"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        value={values.branch_name ?? ''}
                                        name="branch_name"
                                        onChange={handleChange}
                                        error={!!errors.branch_name}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>

                                <div className="lg:col-span-12 col-span-12">
                                    <TextField
                                        label="ชื่อบัญชี"
                                        variant="outlined"
                                        fullWidth
                                        placeholder=""
                                        value={values.account_name ?? ''}
                                        name="account_name"
                                        onChange={handleChange}
                                        error={!!errors.account_name}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>

                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="เลขที่ประจำตัวผู้เสียภาษี"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        value={values.tax_number ?? ''}
                                        name="tax_number"
                                        onChange={handleChange}
                                        error={!!errors.tax_number}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>

                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        select
                                        label="ประเภท VAT"
                                        name='vat_type_id'
                                        placeholder=""
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.vat_type_id ?? ''}
                                        error={!!errors.vat_type_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        sx={{
                                            '& .MuiSelect-select span::before': {
                                                content: "''",
                                                color: '#b3b3b3'
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                        {vatTypeList.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <div className="lg:col-span-6 col-span-12">
                                    <FormControl>
                                        <div>รูปแบบสาขา</div>
                                        <RadioGroup
                                            row
                                            aria-labelledby=""
                                            name="tax_branch_id"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.tax_branch_id ?? ''}

                                        >
                                            {taxBranchList.map((option) => (
                                                <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.name} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        label="เลขที่สาขา (VAT CODE)"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        value={(values.tax_branch_id == 1 ? '' : values.branch_vat_code) ?? ''}
                                        name="branch_vat_code"
                                        onChange={handleChange}
                                        error={!!errors.branch_vat_code}
                                        disabled={values.tax_branch_id == 1}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="flex justify-end mb-7">
                                <Button
                                    variant="outlined"
                                    className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                    href="/manage/shop/list"
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    variant="contained"
                                    className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>

            </div>
            <ModalSuccess setOpen={setOpenModalSuccess} open={openModalSuccess} />
        </>
    )
}