import * as surveyListTypeAction from "../../slices/master/surveyListType.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getSurveyListType = () => {
  return async (dispatch) => {
    dispatch(surveyListTypeAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.SURVEY_LIST_TYPE_URL}/list`,
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(surveyListTypeAction.fetchDataSuccess(result.data));

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(surveyListTypeAction.fetchDataFailure());
    }
  };
};