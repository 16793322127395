import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //campaign
  data: null,
  isFetching: false,
  isError: false,
  // campaignType
  campaignType: null,
  iscampaignTypeFetching: false,
  iscampaignTypeError: false,
  // shop campaign
  campaignShopData: null,
  isCampaignShopFetching: false,
  // entier overview
  campaignEntierOverviewData: null,
  isCampaignEntierOverviewDataFetching: false,
  // due overview
  campaignDueOverviewData: null,
  isCampaignDueOverviewDataFetching: false,
};
const campaignSlice = createSlice({
  name: 'campaign',
  initialState: initialState,
  reducers: {
     // campaign
    fetchData: (state) => {
      state.isFetching = true;
      state.data = null;
      state.isError = false;
    },
    fetchDataSuccess: (state, action) => {
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    },
    fetchDataFailure: (state) => {
      return { ...state, isFetching: false, isError: true };
    },
    // campaignType
    fetchDataCampaginType: (state) => {
      state.iscampaignTypeFetching = true;
      state.campaignType = null;
      state.iscampaignTypeError = false;
    },
    fetchDataCampaginTypeSuccess: (state, action) => {
      return {
        ...state,
        iscampaignTypeFetching: false,
        campaignType: action.payload,
      };
    },
    fetchDataCampaginTypeFailure: (state) => {
      return { ...state, iscampaignTypeFetching: false, iscampaignTypeError: true };
    },
    // campaignCount
    fetchDataCountCampaignProcess: (state) => {
      state.isFetching = true;
      state.count = null;
      state.isError = false;
    },
    fetchDataCountCampaignProcessSuccess: (state,action) => {
      return {
        ...state,
        isFetching: false,
        count: action.payload.data,
      };
    },
    fetchDataCountCampaignProcessFailure: (state) => {
      return { ...state, isFetching: false, isError: true };
    },
    // shop campaign
    fetchDataCampaignShop: (state) => {
      state.isCampaignShopFetching = true;
      state.campaignShopData = null;
    },
    fetchDataCampaignShopSuccess: (state,action) => {
      return {
        ...state,
        isCampaignShopFetching: false,
        campaignShopData: action.payload,
      };
    },
    // entier campaign
    fetchDataCampaignEntierOverview: (state) => {
      state.isCampaignEntierOverviewDataFetching = true;
      state.campaignEntierOverviewData = null;
    },
    fetchDataCampaignEntierOverviewSuccess: (state,action) => {
      return {
        ...state,
        isCampaignEntierOverviewDataFetching: false,
        campaignEntierOverviewData: action.payload,
      };
    },
    // due campaign
    fetchDataCampaignDueOverview: (state) => {
      state.isCampaignDueOverviewDataFetching = true;
      state.campaignDueOverviewData = null;
    },
    fetchDataCampaignDueOverviewSuccess: (state,action) => {
      return {
        ...state,
        isCampaignDueOverviewDataFetching: false,
        campaignDueOverviewData: action.payload,
      };
    },
    resetState: () => initialState,
  },
});

export const { 
  fetchData, 
  fetchDataSuccess, 
  fetchDataFailure, 
  resetState,
  fetchDataCampaginType,
  fetchDataCampaginTypeSuccess,
  fetchDataCampaginTypeFailure,
  fetchDataCountCampaignProcess, 
  fetchDataCountCampaignProcessSuccess,
  fetchDataCountCampaignProcessFailure,
  fetchDataCampaignShop,
  fetchDataCampaignShopSuccess,
  fetchDataCampaignEntierOverview,
  fetchDataCampaignEntierOverviewSuccess,
  fetchDataCampaignDueOverview,
  fetchDataCampaignDueOverviewSuccess
} =
  campaignSlice.actions;
export default campaignSlice.reducer;
