import * as bannerSingleAction from "../../slices/master/bannerSingle.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";
import { endPoint } from "../../../constants/server/api";

export const getActiveBannerSingle = () => {
  return async (dispatch) => {
    dispatch(bannerSingleAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.BANNER_SINGLE_URL}/active`
      };
      const result = await http(config);

      dispatch(bannerSingleAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(bannerSingleAction.fetchDataFailure());
    }
  };
};

export const createBannerSingle = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.BANNER_SINGLE_URL}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };
      const result = await http(config);

      return result.data;
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const updateBannerSingle = (data, id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.BANNER_SINGLE_URL}/${id}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const deleteBannerSingle = (id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "delete",
        url: `${endPoint.BANNER_SINGLE_URL}/${id}`,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};
