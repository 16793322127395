import * as userShopAction from "../../slices/master/userShop.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";


import { endPoint } from "../../../constants/server/api";

export const getPaginationUserShopEmployee = (page = 1, perPage = 12, shopId, s = "") => {
    return async (dispatch) => {
        dispatch(userShopAction.fetchData());
        try {
            const config = {
                method: "get",
                headers: setHeaders(),
                url: `${endPoint.USER_SHOP_EMPLOYEE_BO_URL}`,
                params: {
                    page,
                    perPage,
                    shop_id: shopId,
                    s
                }
            };

            const result = await http(config);
            dispatch(userShopAction.fetchDataSuccess(result.data.data));

        } catch (error) {
            if (error.response.statusText == "Unauthorized") {
                handleUnauthorized()
            }
            dispatch(userShopAction.fetchDataFailure());
        }
    };
};


export const downloadExcelUserShopEmployee = (page = 1, perPage = 12, shopId, s = "") => {
    return async (dispatch) => {
        try {
            const config = {
                method: "get",
                url: `${endPoint.USER_SHOP_EMPLOYEE_BO_URL}/download/excel`,
                params: {
                    page,
                    perPage,
                    shop_id: shopId,
                    s
                },
                responseType: 'blob', // Important to handle binary data
                headers: setHeaders()
            };

            const result = await http(config);

            // Create a Blob from the response
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;

            // Optionally, get the filename from the response headers
            const contentDisposition = result.headers['content-disposition'];
            let fileName = 'userShopEmployee.xlsx'; // Default file name

            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Clean up the link element
            link.parentNode.removeChild(link);
        } catch (error) {
            if (error.response.statusText === "Unauthorized") {
                handleUnauthorized()
            }
        }
    };
};

export const createUserShopEmployee = (data) => {
    return async (dispatch) => {
        try {
            const config = {
                method: "post",
                url: `${endPoint.USER_SHOP_EMPLOYEE_BO_URL}`,
                data: data,
                headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
            };

            const result = await http(config);

            return result.data;

        } catch (error) {
            if (error.response.statusText == "Unauthorized") {
                handleUnauthorized()
            }
        }
    };

};

export const updateUserShopEmployee = (id, data) => {
    return async (dispatch) => {
        try {
            const config = {
                method: "put",
                url: `${endPoint.USER_SHOP_EMPLOYEE_BO_URL}/${id}`,
                data: data,
                headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
            };

            const result = await http(config);

            return result.data; // Return the response data to use in form submission

        } catch (error) {
            if (error.response.statusText == "Unauthorized") {
                handleUnauthorized()
            }
            throw error; // Rethrow the error to handle it in form submission
        }
    };

};

export const deleteUserShopEmployee = (id) => {
    return async (dispatch) => {
        try {
            const config = {
                method: "DELETE",
                url: `${endPoint.USER_SHOP_EMPLOYEE_BO_URL}/${id}`,
                headers: setHeaders()
            };

            const result = await http(config);

            return result.data; // Return the response data to use in form submission

        } catch (error) {
            if (error.response.statusText == "Unauthorized") {
                handleUnauthorized()
            }
            throw error; // Rethrow the error to handle it in form submission
        }
    };
};


export const getUserShopEmployeeById = (id) => {
    return async (dispatch) => {
        dispatch(userShopAction.fetchData());
        try {
            const config = {
                method: "get",
                url: `${endPoint.USER_SHOP_EMPLOYEE_BO_URL}/${id}`,

                headers: setHeaders()
            };

            const result = await http(config);
            dispatch(userShopAction.fetchDataSuccess(result.data.data));

        } catch (error) {
            if (error.response.statusText == "Unauthorized") {
                handleUnauthorized()
            }
        }
    };

};