import * as subDistrictAction from "../../slices/master/subDistrict.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";



export const getSubDistrict = (id) => {
  return async (dispatch) => {
    dispatch(subDistrictAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.SUB_DISTRICT_URL}/list`,
        params: {district_id:id},
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(subDistrictAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      dispatch(subDistrictAction.fetchDataFailure());
    }
  };
};
