import * as userTravellerAction from "../../slices/master/userTraveller.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";
import { endPoint } from "../../../constants/server/api";

export const getUserTravellerPagination = (page = 1, perPage = 12, s = "", province_id = "") => {
  return async (dispatch) => {
    dispatch(userTravellerAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.USER_TRAVELLER_URL}`,
        params: {
          page,
          perPage,
          s,
          province_id
        }
      };
      const result = await http(config);

      dispatch(userTravellerAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(userTravellerAction.fetchDataFailure());
    }
  };
};

export const getUserTravellerById = (id) => {
  return async (dispatch) => {
    dispatch(userTravellerAction.fetchData());
    try {
      const config = {
        method: "get",
        url: `${endPoint.USER_TRAVELLER_URL}/${id}`,
        headers: setHeaders()
      };
      const result = await http(config);

      dispatch(userTravellerAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const createUserTraveller = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.USER_TRAVELLER_URL}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };
      const result = await http(config);

      return result.data;
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const updateUserTraveller = (data, id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.USER_TRAVELLER_URL}/${id}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const deleteUserTraveller = (id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "delete",
        url: `${endPoint.USER_TRAVELLER_URL}/${id}`,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const downloadExcelUserTraveller = (s = "", province_id = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.USER_TRAVELLER_URL}/download/excel`,
        params: {
          s,
          province_id
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'user-traveller.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};