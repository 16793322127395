import React, { useState, useEffect } from "react";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";

import thaiDate from "utils/thaiDate";
import { ModalConfirm } from "components/layouts/ModalConfirm";
import { ModalSuccess } from 'components/layouts/ModalSuccess';
import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getUserTravellerCouponPagination } from "../../../redux/handlers/master/userTravellerCoupon";
import { createUserTravellerCouponCut } from "../../../redux/handlers/master/userTravellerCouponCut";

export function Mycoupon() {
    let { id } = useParams();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} className="mx-6">
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label=""
            >
                <Tab label="คูปองของฉัน" className="text-lg" {...a11yProps(0)} />
                <Tab label="ใช้แล้ว/หมดอายุ" className="text-lg" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <CouponActive id={id} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CouponExpired id={id} />
            </TabPanel>
        </Box>
    )
}

const CouponActive = ({ id }) => {
    const dispatch = useAppDispatch();
    const userTravellerCouponReducer = useAppSelector((state) => state.userTravellerCouponReducer);
    const userTravellerCouponCutReducer = useAppSelector((state) => state.userTravellerCouponCutReducer);
    const [userTravellerCouponList, setUserTravellerCouponList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(9999);
    const [filterText, setFilterText] = useState("");
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [cutId, setCutId] = useState(null);
    const setUserTravellerCouponData = (data, isActive) => {
        if (isActive) {
            if (data && data.length > 0) {
                const userTravellerCouponData = data.map((element) => ({
                    ...element,
                    couponType: element?.coupon?.coupon_name,
                    couponDiscount: `${element?.coupon?.discount_value} ${element?.coupon?.discount_type == 'percent' ? '%' : 'บาท'}`,
                    couponImage: element?.coupon.image_path,
                    couponMinimum: Number(element?.coupon?.minimum_spending),
                    couponExpire: !element?.coupon?.is_not_set_date ? thaiDate(element?.coupon?.end_date) : thaiDate(element?.coupon?.campaign?.end_date),
                }));

                setUserTravellerCouponList(userTravellerCouponData);
            } else {
                setUserTravellerCouponList([]);
            }
        }
    };
    const cutCoupon = () => {
        dispatch(createUserTravellerCouponCut({ user_traveller_coupon_id: cutId })).then(() => {
            dispatch(getUserTravellerCouponPagination(id, true, page, pageSize, filterText));
            setCutId(null)
            setOpenConfirm(false)
            setOpenModalSuccess(true)
        })
    }

    useEffect(() => {
        dispatch(getUserTravellerCouponPagination(id, true, page, pageSize, filterText));
    }, [id, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (userTravellerCouponReducer.data != null && userTravellerCouponReducer.data.data) {
            const data = userTravellerCouponReducer.data.data.data;
            setUserTravellerCouponData(data, userTravellerCouponReducer.data.is_unused);
        }
    }, [userTravellerCouponReducer]);

    return (
        <>
            {userTravellerCouponList.map((item, index) =>
                <div className="w-[437px] h-[188px] drop-shadow-sm flex px-2 py-3  mb-3" key={index} style={{
                    backgroundImage: 'url(/images/coupon/Subtract.png)',
                    backgroundSize: '100% 100% '
                }}>
                    <img src={item.couponImage} className="w-[168px] h-[160px] rounded-lg object-cover" />
                    <div className="grow pl-7 py-0">
                        <div className="mb-1">{item.couponType}</div>
                        <div className="text-[#31A7DE] text-3xl font-bold mb-2">{item.couponDiscount}</div>
                        {!item?.coupon?.is_minimum_spending &&
                            <div className="text-[#131A22] text-sm mb-1">เงื่อนไขซื้อขั้นต่ำ  <span className="ml-5">฿ {item.couponMinimum} บาท</span></div>
                        }
                        <div className="text-[#131A22] text-sm font-bold text-lg  mb-3">หมดเขต {item.couponExpire}</div>
                        <div className="text-right pr-5">
                            <Button variant="contained"
                                className="bg-[#31A7DE] text-sm text-white ml-auto"
                                onClick={() => {
                                    setCutId(item.id)
                                    setOpenConfirm(true)
                                }}
                            >ตัดใช้คูปอง</Button>
                        </div>
                    </div>
                </div>
            )}
            <ModalSuccess setOpen={setOpenModalSuccess} open={openModalSuccess} />
            <ModalConfirm
                open={openConfirm}
                setOpen={setOpenConfirm}
                message={"คุณต้องการตัดคูปองนี้ใช่หรือไม่"}
                confirm={() => cutCoupon()}
            />
        </>
    )
}

const CouponExpired = ({ id }) => {
    const dispatch = useAppDispatch();
    const userTravellerCouponReducer = useAppSelector((state) => state.userTravellerCouponReducer);
    const [userTravellerCouponList, setUserTravellerCouponList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(9999);
    const [filterText, setFilterText] = useState("");
    const setUserTravellerCouponData = (data, isActive) => {
        if (!isActive) {
            if (data && data.length > 0) {
                const userTravellerCouponData = data.map((element) => {
                    let couponStatus

                    if (element?.userTravellerCouponCut.length) {
                        couponStatus = 1
                    } else if (element?.userTravellerCouponUsed.length) {
                        couponStatus = 2
                    } else {
                        couponStatus = 3
                    }

                    return {
                        ...element,
                        couponType: element?.coupon?.coupon_name,
                        couponDiscount: `${element?.coupon?.discount_value} ${element?.coupon?.discount_type == 'percent' ? '%' : 'บาท'}`,
                        couponImage: element?.coupon.image_path,
                        couponMinimum: Number(element?.coupon?.minimum_spending),
                        couponExpire: !element?.coupon?.is_not_set_date ? thaiDate(element?.coupon?.end_date) : thaiDate(element?.coupon?.campaign?.end_date),
                        couponStatus: couponStatus
                    }
                });

                setUserTravellerCouponList(userTravellerCouponData);
            } else {
                setUserTravellerCouponList([]);
            }
        }
    };

    useEffect(() => {
        dispatch(getUserTravellerCouponPagination(id, false, page, pageSize, filterText));
    }, [id, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (userTravellerCouponReducer.data != null && userTravellerCouponReducer.data.data) {
            const data = userTravellerCouponReducer.data.data.data;
            setUserTravellerCouponData(data, userTravellerCouponReducer.data.is_unused);
        }
    }, [userTravellerCouponReducer]);

    return (
        <>
            {userTravellerCouponList.map((item, index) =>
                <div className="w-[437px] h-[188px] drop-shadow-sm flex px-2 py-3 mb-3" key={index} style={{
                    backgroundImage: 'url(/images/coupon/Subtract.png)',
                    backgroundSize: '100% 100% '
                }}>
                    <img src={item.couponImage} className="w-[168px] h-[160px] rounded-lg object-cover" />
                    <div className="grow pl-7 py-0">
                        <div className="mb-1 line-clamp-1">{item.couponType}</div>
                        <div className="text-[#75788D] text-3xl font-bold mb-2">{item.couponDiscount}</div>
                        {!item?.coupon?.is_minimum_spending &&
                            <div className="text-[#131A22] text-sm mb-1">เงื่อนไขซื้อขั้นต่ำ  <span className="ml-5">฿ {item.couponMinimum} บาท</span></div>
                        }
                        <div className="text-[#131A22] text-sm font-bold text-lg  mb-4">หมดเขต {item.couponExpire}</div>
                        <div className="text-right pr-5">
                            {item?.couponStatus == 1 &&
                                <div className="flex justify-between">
                                    <div className="w-[130px] line-clamp-1 pt-1 text-md text-left font-bold text-[#F24236]">ตัดใช้โดย {item?.userTravellerCouponCut[0].userAdmin.first_name} {item?.userTravellerCouponCut[0].userAdmin.last_name}xxa</div>
                                    <div className="flex-1 text-xl  font-bold text-[#75788D]">ใช้เเล้ว</div>
                                </div>
                            }
                            {item?.couponStatus == 2 &&
                                <div className="text-xl font-bold text-[#75788D]">ใช้เเล้ว</div>
                            }
                            {item?.couponStatus == 3 &&
                                <div className="text-xl font-bold text-[#F24236]" >หมดอายุ</div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            className="mt-5 "
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};