import _ from 'lodash'

import { history } from "./history";

export function handleUnauthorized() {
        localStorage.removeItem("token")
        history.push('/login')
        history.go()
 }
export function setHeaders(headers = {}) {
   try {
      let token = localStorage.getItem('token');
      let defaultHeadres = {
         'Content-Type': 'application/json'
      };
      if (token) {
         defaultHeadres['Authorization'] = `Bearer ${token}`
      }

      if (typeof headers === "object") {
        defaultHeadres = _.extend(defaultHeadres, headers)
     }

      return defaultHeadres
   } catch (error) {
      // console.log(error)
      return {}
   }
}