import * as campaignCheckInAction from "../../slices/master/campaignCheckIn.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";



export const createCampaignCheckIn = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.CAMPAIGN_CHECK_IN_URL}`,
        data: data,
        headers: setHeaders(),
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};

export const deleteCampaignCheckIn = (id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "delete",
        url: `${endPoint.CAMPAIGN_CHECK_IN_URL}/${id}`,
        headers: setHeaders(),
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};


export const downloadExcelCampaignCheckIn = (campaign_id, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_CHECK_IN_URL}/download/excel`,
        params: {
          campaign_id,
          s 
        },
        responseType: 'blob', // Important to handle binary data,
        headers: setHeaders(),
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'campaignCheckIn.xlsx'; // Default file name
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);

    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }

    }
  };
};
export const updateCampaignCheckIn = (id,data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.CAMPAIGN_CHECK_IN_URL}/${id}`,
        data: data,
        headers: setHeaders(),
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};

export const getCampaignCheckInPagination = (campaign_id,page=1,perPage=12,s="") => {
  return async (dispatch) => {
    dispatch(campaignCheckInAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.CAMPAIGN_CHECK_IN_URL}`,
        headers: setHeaders(),
        params: {
          campaign_id,
          page,
          perPage,
          s
        }
      };

      const result = await http(config);
      dispatch(campaignCheckInAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      dispatch(campaignCheckInAction.fetchDataFailure());
    }
  };
};
export const getShopPagination = (campaign_id,page=1,perPage=12,s="") => {
  return async (dispatch) => {
    dispatch(campaignCheckInAction.fetchShopData());
    try {
      const config = {
        method: "get",
        url: `${endPoint.SHOP_URL}/campaign-check-in`,
        headers: setHeaders(),
        params: {
          campaign_id,
          page,
          perPage,
          s 
        }
      };

      const result = await http(config);
      
      dispatch(campaignCheckInAction.fetchShopDataSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      dispatch(campaignCheckInAction.fetchShopDataFailure());
    }
  };
};


