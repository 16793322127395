import * as campaignShopInvoiceAction from "../../slices/master/campaignShopInvoice.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const updateInvoiceById = (data, id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.INVOICE_URL}/${id}`,
        data: data,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const updateInvoicePayment = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.INVOICE_URL}/payment`,
        data: data,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const getInvoicePaginationCampaign = (page, perPage, s = '') => {
  return async (dispatch) => {
    dispatch(campaignShopInvoiceAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.INVOICE_URL}/pagination/campaign`,
        headers: setHeaders(),
        params: {
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(campaignShopInvoiceAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignShopInvoiceAction.fetchDataFailure());
    }
  };
};

export const getInvoicePaginationCampaignShop = (campaignId, page, perPage, s = '') => {
  return async (dispatch) => {
    dispatch(campaignShopInvoiceAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.INVOICE_URL}/pagination/campaign/${campaignId}/invoice`,
        headers: setHeaders(),
        params: {
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(campaignShopInvoiceAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignShopInvoiceAction.fetchDataFailure());
    }
  };
};

export const getInvoiceById = (id) => {
  return async (dispatch) => {
    dispatch(campaignShopInvoiceAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.INVOICE_URL}/${id}`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(campaignShopInvoiceAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignShopInvoiceAction.fetchDataFailure());
    }
  };
};

export const deleteInvoiceRecordById = (id, data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "delete",
        url: `${endPoint.INVOICE_URL}/payment/${id}`,
        headers: setHeaders(),
        data: data,
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const downloadExcelInvoiceCampaign = (s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.INVOICE_URL}/download/excel/campaign`,
        params: {
          s,
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'invoice-campaign.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const downloadExcelInvoiceCampaignShop = (campaign_id, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.INVOICE_URL}/download/excel/campaign/shop-invoice`,
        params: {
          campaign_id,
          s,
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'invoice-campaign-shop.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};


export const cancelCampaignShopInvoice = (id) => {
  return async (dispatch) => {
    dispatch(campaignShopInvoiceAction.fetchData());

    try {
      const config = {
        method: "put",
        url: `${endPoint.INVOICE_URL}/cancel/${id}`,
        headers: setHeaders(),
      };
      const result = await http(config);

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignShopInvoiceAction.fetchDataFailure());
    }
  };
};

export const recoverCampaignShopInvoice = (id) => {
  return async (dispatch) => {
    dispatch(campaignShopInvoiceAction.fetchData());

    try {
      const config = {
        method: "put",
        url: `${endPoint.INVOICE_URL}/recover/${id}`,
        headers: setHeaders(),
      };
      const result = await http(config);

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(campaignShopInvoiceAction.fetchDataFailure());
    }
  };
};