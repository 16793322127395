import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Chip from '@mui/material/Chip';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import ClockCircle from "assets/svg/ClockCircle.svg";
import CardStar from "assets/svg/cardstar.png";

import FormatDate from "components/utils/FormatDate";
import thaiDateRange from "utils/thaiDateRange";
import thaiDate from "utils/thaiDate";
import formatDatetime from "utils/formatDatetime";
import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getUserTravellerCampaignRedemptionPagination, getUserTravellerCampaignRedemptionById } from "../../../redux/handlers/master/userTravellerCampaignRedemption";

export function TouristCampaign() {
    let { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userTravellerCampaignRedemptionReducer = useAppSelector((state) => state.userTravellerCampaignRedemptionReducer);
    const [userTravellerCampaignRedemptionList, setUserTravellerCampaignRedemptionList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(9999);
    const [filterText, setFilterText] = useState("");
    const setUserTravellerCamapginRedemptionData = (data) => {
        if (data && data.length > 0) {

            const userTravellerCampaignRedemptionData = data.map((element) => {
                let dateUseCoupon = '';
                if (element?.campaign?.coupon[0] && element?.campaign?.coupon[0].userTravellerCoupon[0] && element?.campaign?.coupon[0].userTravellerCoupon[0].userTravellerCouponUsed[0]) {
                    dateUseCoupon = element?.campaign?.coupon[0].userTravellerCoupon[0].userTravellerCouponUsed[0].created_at
                }

                for (const coupon of element?.campaign?.coupon) {
                    for (const userTravellerCoupon of coupon.userTravellerCoupon) {
                        for (const userTravellerCouponUsed of userTravellerCoupon.userTravellerCouponUsed) {
                            if (dateUseCoupon != null && dateUseCoupon < userTravellerCouponUsed.created_at) {
                                dateUseCoupon = userTravellerCouponUsed.created_at
                            }
                        }
                        for (const userTravellerCouponCut of userTravellerCoupon.userTravellerCouponCut) {
                            if (dateUseCoupon != null && dateUseCoupon < userTravellerCouponCut.created_at) {
                                dateUseCoupon = userTravellerCouponCut.created_at
                            }
                        }
                    }
                }

                if (dateUseCoupon != null) {
                    dateUseCoupon = thaiDate(dateUseCoupon)
                }

                return {
                    id: element.id,
                    campaignName: element?.campaign?.name,
                    campaignImage: element?.campaign?.image_path,
                    campaignDate: thaiDateRange(element?.campaign?.start_date, element?.campaign?.end_date, null),
                    dateReceiveCoupon: thaiDate(element?.created_at),
                    dateUseCoupon: dateUseCoupon,
                    campaignStatus: element?.campaign?.checkInStatus
                }
            });

            setUserTravellerCampaignRedemptionList(userTravellerCampaignRedemptionData);
        } else {
            setUserTravellerCampaignRedemptionList([]);
        }
    };
    const handleClick = (id) => {
        navigate("detail/" + id);
    }

    useEffect(() => {
        dispatch(getUserTravellerCampaignRedemptionPagination(id, page, pageSize, filterText));
    }, [id, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (userTravellerCampaignRedemptionReducer.data != null && userTravellerCampaignRedemptionReducer.data.data) {
            const data = userTravellerCampaignRedemptionReducer.data.data.data;
            setUserTravellerCamapginRedemptionData(data);
        }
    }, [userTravellerCampaignRedemptionReducer]);

    return (
        <>
            <div className="  rounded-[10px] drop-shadow  mb-4 px-[25px]">
                <div className="text-[#4C4E64]/[0.87] font-semibold text-xl mb-5 mt-2">แคมเปญที่เข้าร่วม</div>
                <div>
                    {userTravellerCampaignRedemptionList.map((item, index) => (
                        <Card className='w-100 rounded-[8px] shadow-none mb-4' key={index} onClick={() => handleClick(item.id)}>
                            <CardActionArea className='p-[10px] flex' >
                                <div>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={item.campaignImage}
                                        alt=""
                                        width="140"
                                    />
                                </div>
                                <CardContent className="grow">
                                    <div className="flex">
                                        <div className="grow">
                                            <div className="text-[#131A22] text-xl mb-2">{item.campaignName}</div>
                                            <div className="text-[#31A7DE] mb-2">{item.campaignDate}</div>
                                            <div>
                                                <span className="text-[#75788D]">วันที่รับสิทธิ์</span>
                                                <span className="text-[#31A7DE] mx-3">{item.dateReceiveCoupon}</span>
                                                <span className="text-[#75788D]">วันที่ใช้สิทธิ์</span>
                                                <span className="text-[#31A7DE] mx-3">{item.dateUseCoupon}</span>
                                            </div>
                                        </div>
                                        <div className="self-center">
                                            {
                                                item.campaignStatus == 1 ? (
                                                    <Chip label="กำลังดำเนินการ"
                                                        className="bg-[#FF9900] text-white w-[156px]"
                                                    />
                                                ) : (
                                                    item.campaignStatus == 2 ? (
                                                        <Chip label="เสร็จสิ้น"
                                                            className="bg-[#2DC76D] text-white w-[156px]"
                                                        />
                                                    ) : ''
                                                )
                                            }
                                        </div>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </div>
            </div>
        </>
    )
}

export function TouristCampaignDetail() {
    let { id, campaignID } = useParams(); // campaignID is a user_traveller_campaign_redemption_id
    const dispatch = useAppDispatch();
    const userTravellerCampaignRedemptionReducer = useAppSelector((state) => state.userTravellerCampaignRedemptionReducer);
    const [campaignRedemption, setCampaignRedemption] = useState({});
    const [open, setOpen] = React.useState(false);
    const [checkinDetail, setCheckinDetail] = useState(null);
    const openCheckinDetail = (item) => {
        setCheckinDetail(item)
        setOpen(true)
    }
    const openGoogleMap = (item) => {
        window.open(`https://www.google.com/maps?q=${item.shop.lat},${item.shop.ln}`, '_blank');
    }

    useEffect(() => {
        if (campaignID) {
            dispatch(getUserTravellerCampaignRedemptionById(campaignID));
        }
    }, [campaignID, dispatch]);

    useEffect(() => {
        if (userTravellerCampaignRedemptionReducer.data && userTravellerCampaignRedemptionReducer.data.data && !userTravellerCampaignRedemptionReducer.data.data.data) {
            const responseData = { ...userTravellerCampaignRedemptionReducer.data.data }; // clone the object
            const campaignClone = { ...responseData.campaign }; // clone the campaign object

            campaignClone.campaignDate = thaiDateRange(responseData?.campaign?.start_date, responseData?.campaign?.end_date, null);
            campaignClone.remaining = responseData?.campaign?.remaining;
            campaignClone.total_complete = 0;
            console.log(responseData);

            let qtyCampaignCheckin = 0;
            if (responseData.campaign.is_limit_check_in) {
                qtyCampaignCheckin = responseData.campaign.qty_check_in
            } else {
                qtyCampaignCheckin = responseData.campaign.campaignCheckIn ? responseData.campaign.campaignCheckIn.length : 0
            }
            campaignClone.qtyCampaignCheckin = qtyCampaignCheckin;

            if (responseData?.campaign?.campaignCheckIn) {
                for (const campaignCheckIn of responseData.campaign.campaignCheckIn) {
                    if (campaignCheckIn.userTravellerCampaignCheckIn.length) {
                        campaignClone.total_complete += 1
                    }
                }
            }

            const data = {
                ...responseData,
                campaign: campaignClone, // assign the modified campaign object back
            };

            setCampaignRedemption(data);
        }
    }, [userTravellerCampaignRedemptionReducer.data]);

    return (
        <div className="px-5">
            <div className="  mb-4">
                <div className="text-[#4C4E64]/[0.87] font-semibold text-2xl mb-5 mt-2">รายละเอียดแคมเปญที่เข้าร่วม</div>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Link to={`/tourist/manage/${id}/campaign`} underline="hover" key="2" color="inherit" >
                        แคมเปญที่เข้าร่วม
                    </Link>,
                    <Typography key="3" sx={{ color: 'rgba(76, 78, 100, 0.87)' }}>
                        {campaignRedemption?.campaign?.name}
                    </Typography>
                </Breadcrumbs>
            </div>
            <div className=" bg-white rounded-[10px] drop-shadow   p-[25px] mb-7">
                <div className="flex mb-7">
                    <div className="w-[185px h-[185px] mr-5">
                        <img src="/images/campaign/c3.png" className="w-[185px h-[185px] object-cover rounded-lg" />
                    </div>
                    <div className="grow flex flex-col">
                        <div className="text-[#131A22] text-3xl font-semibold mb-2">{campaignRedemption?.campaign?.name}</div>
                        <div className="text-lg mb-auto">{campaignRedemption?.campaign?.detail_short}</div>
                        <div className="flex">
                            <div className="flex items-center mr-7">
                                <img className="mr-3" src={ClockCircle} alt="" />
                                {campaignRedemption?.campaign?.campaignDate}
                            </div>
                            <div className="flex items-center">
                                <img className="mr-3" src={CardStar} alt="" />
                                คงเหลือ: <span className="text-[#31A7DE] mx-4">{campaignRedemption?.campaign?.remaining}</span> สิทธิ์
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-[#131A22] text-2xl font-semibold mb-3">รางวัลกิจกรรม</div>
                {campaignRedemption?.campaign?.coupon?.map((item, index) =>
                    <div className="w-[293px] h-[123px] drop-shadow-sm flex px-2 py-2  mb-" key={index} style={{
                        backgroundImage: 'url(/images/coupon/Subtract.png)',
                        backgroundSize: '100% 100% '
                    }}>
                        <img src={item.image_path} className="w-[111px] h-[107px] rounded-lg object-cover" />
                        <div className="grow pl-4 py-0">
                            <div className="mb-1 text-sm line-clamp-1">{item.coupon_name}</div>
                            <div className="text-[#31A7DE] text-xl font-bold ml-0 mb-2">{item.discount_value} {(item.discount_type == 'percent' ? '%' : 'บาท')}</div>
                            {!item.is_minimum_spending &&
                                <div className="text-[#131A22] text-xs mb-1">เงื่อนไขซื้อขั้นต่ำ  <span className="ml-1">฿ {Number(item.minimum_spending)} บาท</span></div>
                            }
                            <div className="text-[#131A22] text-xs font-bold text-lg  mb-3">หมดเขต {!item.is_not_set_date ? FormatDate(item.end_date) : FormatDate(campaignRedemption?.campaign.end_date)}</div>
                        </div>
                    </div>
                )}
                <div className="text-[#131A22] text-2xl font-semibold mb-3 mt-4">รายละเอียด</div>
                <div className="text-lg">
                    <div dangerouslySetInnerHTML={{ __html: campaignRedemption?.campaign?.detail }} />
                </div>
                <div className="text-[#131A22] text-2xl font-semibold mb-3 mt-4">เงื่อนไขในการใช้สิทธิ</div>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: campaignRedemption?.campaign?.term_and_cond }} />
                </div>
            </div>
            {campaignRedemption?.campaign?.campaign_type_id == 3 &&
                <div className=" bg-white rounded-[10px] drop-shadow   p-[25px] mb-7">
                    <div className="flex items-center mb-3">
                        <div className="text-[#131A22] text-2xl font-semibold">ภารกิจทั้งหมด ({
                            campaignRedemption?.campaign?.total_complete}/{campaignRedemption?.campaign?.qtyCampaignCheckin})</div>
                        {
                            campaignRedemption?.campaign?.checkInStatus == 2 ? (
                                <Chip label="เสร็จสิ้น"
                                    className="bg-[#2DC76D] text-white w-[156px] ml-auto text-lg"
                                />
                            ) : (
                                <Chip label="กำลังดำเนินการ"
                                    className="bg-[#FF9900] text-white w-[156px] ml-auto text-lg"
                                />
                            )
                        }
                    </div>
                    <div className="text-lg mb-4">ททท. แจกส่วนลด เมื่อลูกค้าเช็คอิน ณ จุดลงทะเบียนเพื่อกระตุ้นยอดขายร้านค้า/สถานที่ที่ร่วมรายการ</div>
                    <div>
                        {campaignRedemption?.campaign?.campaignCheckIn?.map((item, index) =>
                            <Card className='w-100 rounded-[8px] shadow-none drop-shadow mb-4' key={index} >
                                <CardActionArea className='p-[10px] flex' >
                                    <div className="flex-[0_0_100px]">
                                        <CardMedia
                                            component="img"
                                            height="100"
                                            image={item?.shop?.logo_image}
                                            alt=""
                                            width="100"
                                        />
                                    </div>
                                    <CardContent className="grow py-0">
                                        <div className="flex">
                                            <div className="grow">
                                                <div className="text-[#131A22] text-xl mb-1">{item.shop?.name}</div>
                                                <div className="text-[#75788D] flex items-center mb-2">
                                                    <PlaceSharpIcon className="text-base mr-2" /> {item.shop?.subDistrict?.district.province.name_th}</div>
                                                <Button
                                                    variant="outlined"
                                                    className="text-[#31A7DE] text-xs border-[#31A7DE] rounded-full py-1 px-2"
                                                    onClick={() => openGoogleMap(item)}
                                                >กดดูพิกัดเช็คอิน</Button>
                                            </div>
                                            {item.userTravellerCampaignCheckIn.length ? (
                                                <div className="self-center">
                                                    <Button
                                                        variant="contained"
                                                        className="text-[#FFF]  bg-[#31A7DE]  rounded-lg text-base"
                                                        onClick={() => openCheckinDetail(item)}
                                                    >ดูรายการ</Button>
                                                </div>
                                            ) : ''
                                            }
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        )}
                    </div>
                    <ModalCheckin open={open} setOpen={setOpen} checkinDetail={checkinDetail} />
                </div>
            }
        </div>
    )
}

const ModalCheckin = ({ open, setOpen, checkinDetail }) => {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className=" flex items-center w-full">
                    <div className="text-lg font-bold text-white px-5 py-2 grow mr-atuo"></div>
                    <IconButton onClick={() => {
                        setOpen(false)
                    }}>
                        <CloseSharpIcon className="text-[#4C4E64]/[0.5]" />
                    </IconButton>
                </div>
                <div className="text-xl  p-5 text-[#000] w-[420px] mx-auto">
                    <img src={checkinDetail?.userTravellerCampaignCheckIn[0].image_path} className=" mb-3 rounded-lg w-full " />
                    <div className="mb-1">
                        <span>สถานที่เช็คอิน : </span>
                        <span> {checkinDetail?.shop?.name}</span>
                    </div>
                    <div className="mb-1">
                        <span>วันที่ทำรายการ : </span>
                        <span> {formatDatetime(checkinDetail?.userTravellerCampaignCheckIn[0].created_at)} น.</span>
                    </div>
                    <div>
                        <span>พิกัด: </span>
                        <span className="text-[#31A7DE]"> {checkinDetail?.userTravellerCampaignCheckIn[0].lat}, {checkinDetail?.userTravellerCampaignCheckIn[0].ln}</span>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 448,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 1,
    borderRadius: 2,
    overflow: 'hidden'
};