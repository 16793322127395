import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";
import React, { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { campaign } from "mocks/shop";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DocumentTextIcon, C1Icon, C2Icon, C3Icon } from "components/fragments/Icon";

import dayjs from 'dayjs';

import CardCampaign from "components/layouts/CardCampaign";
import { DataGrid } from "@mui/x-data-grid";
import DownloadExcel from "components/utils/DownloadExcel";

import FormatDate from "components/utils/FormatDate";
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import thaiDateRange from "utils/thaiDateRange";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getShopCampaignDistributionPagination, getShopCampaignDistributionUserPagination, downloadExcelShop } from "../../../../redux/handlers/master/shopCampaignDistribution";
import { getCampaignById } from "../../../../redux/handlers/master/campaign";

export function HistoryGiveCoupon() {
    let { shopID } = useParams();
    const dispatch = useAppDispatch();
    const shopCampaignDistributionReducer = useAppSelector((state) => state.shopCampaignDistributionReducer);
    const [shopCampaignList, setShopCampaignList] = useState([]);

    useEffect(() => {
        dispatch(getShopCampaignDistributionPagination(shopID, 1, 9999));
    }, [shopID, dispatch]);

    useEffect(() => {
        if (shopCampaignDistributionReducer.data != null && shopCampaignDistributionReducer.data.data) {
            const data = shopCampaignDistributionReducer.data.data.data;

            if (data && data.length > 0) {
                let campaignData = []

                for (const element of data) {
                    if (element.campaign != null) {
                        campaignData.push({
                            id: element?.campaign.id,
                            image: element?.campaign.image_path,
                            name: element?.campaign.name,
                            date: thaiDateRange(element?.campaign.start_date, element?.campaign.end_date, null),
                            coupon: null,
                            couponremain: null,
                        })
                    }
                }

                setShopCampaignList(campaignData);
            }
        }
    }, [shopCampaignDistributionReducer]);

    return (
        <>
            <div className="text-2xl mb-5 font-semibold text-[#4C4E64]/[0.87]">แคมเปญ</div>
            {shopCampaignList.map((item, index) => (
                <div className="mb-3" key={index}>
                    <CardCampaign item={item} />
                </div>
            ))}
        </>
    )
}

export function HistoryGiveCouponList() {
    let { campaignID, shopID } = useParams();
    const dispatch = useAppDispatch();
    const shopCampaignDistributionReducer = useAppSelector((state) => state.shopCampaignDistributionReducer);
    const campaignReducer = useAppSelector((state) => state.campaignReducer);
    const [shopCouponList, setShopCouponList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const setShopCouponData = (data) => {
        if (data && data.length > 0) {
            const couponData = data.map((element) => ({
                id: element.id,
                couponCode: element?.campaign?.coupon[0]?.code,
                couponName: element?.campaign?.coupon[0]?.coupon_name,
                campaignName: element?.campaign?.name,
                couponRecipient: `${element?.userTravellerCampaignRedemption?.userTraveller?.first_name} ${element?.userTravellerCampaignRedemption?.userTraveller?.last_name}`,
                date: FormatDate(element?.created_at),
                couponValue: `${element?.campaign?.coupon[0]?.discount_value} ${(element?.campaign?.coupon[0]?.discount_type == 'percent') ? '%' : 'บาท'}`,
                userTravellerId: (element?.userTravellerCampaignRedemption?.userTraveller?.is_active) ? element?.userTravellerCampaignRedemption?.userTraveller?.id : null
            }));

            setShopCouponList(couponData);
        } else {
            setShopCouponList([]);
        }
    };
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const download = () => {
        dispatch(downloadExcelShop(shopID, campaignID))
    }
    const columns = [
        {
            field: 'couponCode',
            headerName: 'รหัสคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            editable: false,
            renderCell: ({ row }) => (
                <div>{row.couponCode}</div>
            ),
        },
        {
            field: 'couponName',
            headerName: 'ชื่อคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            editable: false,
        },
        {
            field: 'couponRecipient',
            headerName: 'ผู้รับคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            width: 110,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => (
                <>
                    {row.userTravellerId &&
                        <a href={`/tourist/manage/${row.userTravellerId}/information`} target="_blank" className="text-[#31A7DE]">{row.couponRecipient}</a>
                    }
                    {!row.userTravellerId &&
                        <div>{row.couponRecipient}</div>
                    }
                </>
            ),
        },

        {
            field: 'date',
            headerName: 'วันที่แจกสิทธิ์',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
        },
        {
            field: 'couponValue',
            headerName: 'มูลค่าคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <div className="text-[#31A7DE]">{row.couponValue}</div>

                </>
            ),
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getCampaignById(campaignID))
    }, [campaignID, dispatch]);

    useEffect(() => {
        if (campaignReducer.data != null) {
            const data = campaignReducer?.data.data;

            if (data) {
                setCampaignName(data.name)
            }
        }
    }, [campaignReducer]);

    useEffect(() => {
        dispatch(getShopCampaignDistributionUserPagination(shopID, campaignID, page, pageSize, filterText));
    }, [shopID, campaignID, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (shopCampaignDistributionReducer.data != null && shopCampaignDistributionReducer.data.data) {
            const data = shopCampaignDistributionReducer.data.data.data;
            setShopCouponData(data);
            setTotalRows(shopCampaignDistributionReducer?.data?.data?.meta?.total);
        }
    }, [shopCampaignDistributionReducer]);

    return (
        <>
            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[10px] py-[10px]">
                    <div className="text-[#4C4E64]/[0.87] text-xl  px-4 font-semibold mb-5">{campaignName}</div>
                    <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหา"
                                onKeyUp={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={shopCouponList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                backgroundColor: '#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px'
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
}





