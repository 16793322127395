import * as userTravellerCouponCutAction from "../../slices/master/userTravellerCouponCut.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";
import { endPoint } from "../../../constants/server/api";

export const createUserTravellerCouponCut = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.USER_TRAVELLER_COUPON_CUT_URL}`,
        data: data,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data;
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};