
import DownloadExcel from "components/utils/DownloadExcel";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Chip from '@mui/material/Chip';
import ic_edit from "assets/svg/ic_edit.svg";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import { useReactToPrint } from "react-to-print";
import { InvPrint } from "components/pages/report/invoice/printinvoice";

import FormatDate from "components/utils/FormatDate";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getInvoicePaginationCampaignShop, downloadExcelInvoiceCampaignShop } from "../../../../redux/handlers/master/campaignShopInvoice";
import { getCampaignById } from "../../../../redux/handlers/master/campaign";

export function ReportInvoiceList() {
    let { campaignID } = useParams();
    const dispatch = useAppDispatch();
    const campaignShopInvoiceReducer = useAppSelector((state) => state.campaignShopInvoiceReducer);
    const campaignReducer = useAppSelector((state) => state.campaignReducer);
    const [invoiceList, setInvoiceList] = useState([]);
    const [invoice, setInvoice] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const setInvoiceData = (data) => {
        if (data && data.length > 0) {
            const invoiceData = data.map((element) => ({
                id: element.id,
                invoiceCode: element?.code,
                shopName: element?.shop?.name,
                address: element?.shop?.full_address,
                invoiceDate: FormatDate(element?.issued_at),
                netTotal: element?.total_remain,
                status: element?.invoice_status,
                is_status: element?.is_status
            }));

            setInvoiceList(invoiceData);
        } else {
            setInvoiceList([]);
        }
    };
    const download = () => {
        dispatch(downloadExcelInvoiceCampaignShop(campaignID, filterText))
    }
    const componentRef = useRef();
    const componentRefs = useRef([]);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (invoice) {
            handlePrint();
        }
    }, [invoice]);

    const columns = [
        {
            field: 'invoiceCode',
            headerName: 'เลขที่ใบแจ้งหนี้',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            minWidth: 200,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` text-[#3FA5FF]`}>{row.invoiceCode}</div>
                </div>
            ),

        },
        {
            field: 'shopName',
            headerName: 'ชื่อร้านค้า/สถานที่',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            minWidth: 250,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className={` `}>{row.shopName}</div>
                </div>
            ),
        },
        {
            field: 'address',
            headerName: 'ที่อยู่',
            headerClassName: 'bg-[#F5F5F7]',
            width: 300,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.address}</div>
                    <div className=""></div>
                </div>
            ),
        },
        {
            field: 'invoiceDate',
            headerName: 'วันที่ออกเอกสาร',
            headerClassName: 'bg-[#F5F5F7]',
            width: 200,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.invoiceDate}</div>
                    <div className=""></div>
                </div>
            ),
        },
        {
            field: 'netTotal',
            headerName: 'ยอดสุทธิ (รวมภาษี)',
            headerClassName: 'bg-[#F5F5F7]',
            width: 180,
            sortable: false,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <div className="text-[#242F48]">{row.netTotal}</div>
                    <div className=""></div>
                </div>
            ),
        },
        {
            field: 'status',
            headerName: 'สถานะ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    {
                        row && !row?.is_status ? (
                            <Chip label={"ยกเลิกเอกสาร"} className="w-[128px]" style={{
                                color: '#FFF',
                                background: 'red',
                            }} />
                        ) : (
                            row.status == 1 ? (
                                <Chip label={"ชำระบางส่วน"} className="w-[128px]" style={{
                                    color: '#FFAA00',
                                    background: '#FFF6F0',
                                }} />
                            ) : (

                                row.status == 2 ? (
                                    <Chip label={"ชำระเเล้ว"} className="w-[128px]" style={{
                                        color: '#72E128',
                                        background: '#EEFBE5',
                                    }} />
                                ) : (
                                    <Chip label={"ค้างชำระ"} className="w-[128px]" style={{
                                        color: '#F24236',
                                        background: '#FFECEC',
                                    }} />
                                )

                            )
                        )
                    }

                </>

            ),
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <IconButton href={`/report/campaign/${campaignID}/invoice/${row.id}`} className="w-[30px] mr-3 h-[30px] p-1" style={{
                        background: '#E3E8F0',
                    }} >
                        <img src={ic_edit} alt="" />
                    </IconButton>
                    <IconButton className="w-[32px]  h-[32px] p-1" style={{
                        background: '#E8F1FF',
                        color: '#31A7DE'
                    }}
                        onClick={() => {
                            const index = invoiceList.findIndex(data => data.id == row.id)
                            console.log(index);
                            let invoice = { ...campaignShopInvoiceReducer.data.data.data[index] };
                            invoice.date = new Date()
                            setInvoice(invoice)
                        }}
                    >
                        <PrintOutlinedIcon className="text-[20px]" />
                    </IconButton>

                </>

            ),
        },

    ];
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="#">
            รายงาน
        </Link>,
        <Link underline="hover" key="1" color="inherit" to="/report/campaign">
            ใบแจ้งหนี้
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            {campaign ? campaign.name : ''}
        </Typography>
    ];

    useEffect(() => {
        dispatch(getCampaignById(campaignID));
    }, [campaignID]);

    useEffect(() => {
        dispatch(getInvoicePaginationCampaignShop(campaignID, page, pageSize, filterText));
    }, [campaignID, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (campaignReducer.data != null) {
            const data = campaignReducer.data.data
            setCampaign(data)
        }
    }, [campaignReducer]);

    useEffect(() => {
        if (campaignShopInvoiceReducer.data != null && campaignShopInvoiceReducer.data.data) {
            const data = campaignShopInvoiceReducer.data.data.data;

            setInvoiceData(data);
            setTotalRows(campaignShopInvoiceReducer?.data?.data?.meta?.total);
        }
    }, [campaignShopInvoiceReducer]);

    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">{campaign ? campaign.name : ''}</div>
            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[10px]">
                <div className="px-[15px] pt-[15px]">
                    <div className="d-flex align-items-center px-4 mb-5 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหาชื่อร้านค้า/สถานที่"
                                onKeyUp={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={invoiceList}
                        disableColumnMenu
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px',

                            },
                            '.MuiDataGrid-columnHeader': {
                                backgroundColor: '#F5F5F7'
                            },
                            '& .MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px',
                            },
                        }}
                    />
                </div>
            </div>
            <div style={{ display: "none" }}>
                {
                    <InvPrint
                        innerRef={(el) => (componentRef.current = el)}
                        isSplit={true}
                        invoice={invoice}
                    />
                }

            </div >
        </>
    );
}