const thaiDateRange = (startDateStr, endDateStr,type) => {
    if (!startDateStr || !endDateStr) return '';

    // Parse the start and end dates
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Check for invalid dates
    if (isNaN(startDate) || isNaN(endDate)) return '';

    // Extract day, month, year
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    const startMonth = startDate.getMonth(); // months are 0-indexed (0-11)
    const endMonth = endDate.getMonth();

    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    // Convert to Thai year (Buddhist Era)
    const thaiStartYear = startYear + 543;
    const thaiEndYear = endYear + 543;

    // Map months to Thai abbreviations
    const thaiMonths = [
      'ม.ค.', // January
      'ก.พ.', // February
      'มี.ค.', // March
      'เม.ย.', // April
      'พ.ค.', // May
      'มิ.ย.', // June
      'ก.ค.', // July
      'ส.ค.', // August
      'ก.ย.', // September
      'ต.ค.', // October
      'พ.ย.', // November
      'ธ.ค.', // December
    ];

    const startMonthThai = thaiMonths[startMonth];
    const endMonthThai = thaiMonths[endMonth];

    let dateRangeString = 'วันที่ ';

    let textEnd = ' เท่านั้น'
    if (type  == 'mission') {
      textEnd=''
    }

    if (startYear === endYear) {
      if (startMonth === endMonth) {
        // Same month and year
        dateRangeString += `${startDay} - ${endDay} ${startMonthThai} ${thaiStartYear} ${textEnd}`;
      } else {
        // Same year, different months
        dateRangeString += `${startDay} ${startMonthThai} - ${endDay} ${endMonthThai} ${thaiStartYear} ${textEnd}`;
      }
    } else {
      // Different years
      dateRangeString += `${startDay} ${startMonthThai} ${thaiStartYear} - ${endDay} ${endMonthThai} ${thaiEndYear} ${textEnd}`;
    }

    return dateRangeString;
}

export default thaiDateRange;