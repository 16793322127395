import * as accountTypeAction from "../../slices/master/accountType.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";

export const getAccountTypeList = () => {
  return async (dispatch) => {
    dispatch(accountTypeAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.ACCOUNT_TYPE_URL}/list`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(accountTypeAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(accountTypeAction.fetchDataFailure());
    }
  };
};

export const getAccountType = (id) => {
  return async (dispatch) => {
    dispatch(accountTypeAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.ACCOUNT_TYPE_URL}/${id}`,
        headers: setHeaders(),
      };
      const result = await http(config);

      dispatch(accountTypeAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(accountTypeAction.fetchDataFailure());
    }
  };
};