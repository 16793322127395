import Modal from '@mui/material/Modal';
import React, { useState, useEffect,useRef } from "react";
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';

export const ModalSuccess = ({open,setOpen,message="บันทึกข้อมูลสำเร็จ"}) => {

    const handleClose = () => setOpen(false);
    

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal} className="py-[30px]">
                <div className='text-center'>
                    <CheckCircleIcon className='text-[64px] text-[#2DC76D]' />
                </div>
                <div className='text-center text-2xl my-7 font-semibold'>{message}</div>
                <Button className="btn px-7 text-base  h-[44px] text-white flex items-center rounded-[8px] mx-auto bg-[#31A7DE]"
                onClick={handleClose}
                >
                    ตกลง
                </Button>
            </Box>
        </Modal>
    )
}

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 438,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 0,
    borderRadius:2,
    overflow:'hidden',
    outline:'none'
};