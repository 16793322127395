


import { ShopStatus, ShopButtonStatus } from "components/fragments/ShopStatus";

import { ModalAllder } from "components/layouts/ModalAllder";


import React, { useState, useEffect } from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import ipiechart from "assets/svg/PieChart.svg";
import icard from "assets/svg/card.svg";
import ihistory from "assets/svg/History.svg";
import ishop2 from "assets/svg/Shop2.svg";
import itag from "assets/svg/tag.svg";
import iusercircle from "assets/svg/UserCircle.svg";
import iuserid from "assets/svg/UserId.svg";
import UserIdActive from "assets/svg/UserId.svg";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';
import IconButton from '@mui/material/IconButton';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

import {
    Link,
    useParams,
    NavLink,
    Outlet,
    useNavigate,
} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../redux/store/configureStore";
import { getCampaignById } from "../../redux/handlers/master/campaign";

export function ReportCampaignMenu() {
    let { campaignID } = useParams();
    const dispatch = useAppDispatch();
    const campaignReducer = useAppSelector((state) => state.campaignReducer);
    const [campaign, setCampaign] = useState();
    const [open, setOpen] = React.useState(false);
    const breadcrumbs = [
        <NavLink
            to="/report/campaigndetail"
            className={({ isActive, isPending }) =>
                isActive ? "text-[#31a7de]" : ""
            }
            key={1}
        >
            <div className=" flex items-center hover:text-[#31a7de]">
                รายงาน
            </div>
        </NavLink>,

        <Typography key="3" sx={{ color: 'text.primary' }} >
            {campaign ? campaign.name : ''}
        </Typography>
    ];

    useEffect(() => {
        dispatch(getCampaignById(campaignID));
    }, [campaignID]);

    useEffect(() => {
        if (campaignReducer.data && campaignReducer.data.data) {
            setCampaign(campaignReducer.data.data)
        }
    }, [campaignReducer.data]);

    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">{campaign ? campaign.name : ''}</div>
            <div className="flex">
                <div className=" w-[272px] flex-[0_0_272px] mr-4">
                    <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4">
                        <div className="px-[15px] mb-4">รายงานละเอียดแคมเปญ</div>
                        <ul className="text-[#75788D]">
                            <li className="mb-5">

                                {/* <NavLink
                                    to="ccc"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                    <img className="mr-3" src={ipiechart} alt="" /> 
                                    ภาพรวม
                                    </div>
                                </NavLink> */}
                            </li>
                            <li className="mb-5">
                                <NavLink
                                    to="detail"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={iuserid} alt="" />
                                        รายละเอียดแคมเปญ
                                    </div>
                                </NavLink>
                            </li>
                            <li className="mb-5">
                                <NavLink
                                    to="participants"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={iusercircle} alt="" />
                                        ผู้เข้าร่วมแคมเปญ
                                    </div>
                                </NavLink>
                            </li>
                            <li className="mb-5">
                                <NavLink
                                    to="users"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={ihistory} alt="" />
                                        ผู้ใช้สิทธิจากแคมเปญ
                                    </div>
                                </NavLink>
                            </li>
                            <li className="mb-5">
                                <NavLink
                                    to="shop/participants"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={ishop2} alt="" />

                                        ร้านค้า/สถานที่ที่เข้าร่วมแคมเปญ
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex-[0_0_calc(100%-288px)] w-[calc(100%-288px)]">
                    <Outlet />
                </div>
            </div>
            <div className="py-2"></div>
        </>
    )
}